import {
    userConstants
} from "../constants/user.constants";
import {
    userService
} from "../services/user.service";
import {
    alertActions
} from './index';
import {
    history
} from "../shared/history";


export const userActions = {
    login,
    logout,
    adminUserReg,
    updateUser,
    delete: _delete,
    SetEmail,
    accountRecovery,
    MandatoryPasswordReset
};


function accountRecovery(email) {
    return dispatch => {
        dispatch(request(email));
        userService.accountRecovery(email)
            .then(
                email => {

                    dispatch(success(email));
                    dispatch(alertActions.success(" A confirmation has been sent to your email!"));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                },
                error => {

                    if (error !== "") {
                        dispatch(failure(" This email is not associated with an account"));
                        dispatch(alertActions.error(" This email is not associated with an account "));

                    } else {
                        dispatch(failure(" This email is not associated with an account"));
                        dispatch(alertActions.error(" This email is not associated with an account "));
                    }
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {
            type: userConstants.SET_EMAIL_REQUEST
        }
    }

    function success(email) {
        return {
            type: userConstants.SET_EMAIL_SUCCESS,
            email
        }
    }

    function failure(error) {


        return {
            type: userConstants.SET_EMAIL_FAILURE,
            error
        }
    }
}


function SetEmail(user) {
    return dispatch => {
        dispatch(request(user));
        userService.SetEmail(user)
            .then(
                email => {
                    dispatch(success(email));
                    history.push('/home');
                    window.location.reload(true);

                },
                error => {

                    if (error !== "") {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error.message));

                    } else {
                        dispatch(failure(" This email is already associated with an account please try again"));
                        dispatch(alertActions.error(" This email is already associated with an account please try again"));
                    }
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {
            type: userConstants.SET_EMAIL_REQUEST
        }
    }

    function success(email) {
        return {
            type: userConstants.SET_EMAIL_SUCCESS,
            email
        }
    }

    function failure(error) {


        return {
            type: userConstants.SET_EMAIL_FAILURE,
            error
        }
    }
}


function login(user) {
    return dispatch => {
        dispatch(request(user));
        userService.login(user).then(
                user => {
                    dispatch(success(user));
                    let role = user.roles;
                    let mail = user.email;

                    if (!(mail === "")) {


                            if (role.length>0) {
                            
                                history.push('/home');
                                window.location.reload(true);
                                
                            }
                            else{
                                dispatch(alertActions.error(" Not authorized to access this resource, Contact Support"))
                                setTimeout(() => {
                                    dispatch(alertActions.clear())
                                }, 5000)
                            }

                    } else {
                        history.push('/EJohns/SetEmail');
                        window.location.reload(true);
                    }

                },
                error => {
                        if(error.Message){
                        dispatch(failure(error.Messsage));           
                        dispatch(alertActions.error(error.Message));
                    }else{
                         dispatch(failure("Connection Error.E-Johns Server may be temporarily down, Please try again later"));
                        dispatch(alertActions.error("Connection Error.E-Johns Server may be temporarily down, Please try again later"));
                        //return Promise.reject("Connection Error.E-Johns Server may be temporarily down, Please try again later");
                    }
  
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                })

              
    };

    function request(user) {
        return {
            type: userConstants.USER_LOGIN_REQUEST,
            user
        }
    }

    function success(user) {

        return {
            type: userConstants.USER_LOGIN_SUCCESS,
            user
        }
    }

    function failure(error) {


        return {
            type: userConstants.USER_LOGIN_FAILURE,
            error
        }
    }
}

function logout() {
    userService.logout();
    history.push('/EJohns');
    window.location.reload(true);
    return {
        type: userConstants.USER_LOGOUT
    };
}

function adminUserReg(user) {
    return dispatch => {
        dispatch(request(user));

        userService.adminUserRegistration(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success('Registration successful'));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request(user) {
        return {
            type: userConstants.USER_REGISTER_REQUEST,
            user
        }
    }

    function success(user) {
        return {
            type: userConstants.USER_REGISTER_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: userConstants.USER_REGISTER_FAILURE,
            error
        }
    }
}

function MandatoryPasswordReset(user) {

    return dispatch => {
        dispatch(request());

        userService.updateUserCredentialsMandatory(user)
            .then(
                users => {

                    dispatch(success(users));
                    dispatch(alertActions.success(" Successfully updated password, Please Login again"));

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                        dispatch(userActions.logout())
                    }, 5000);

                },

                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Connection Error. E-Johns Server may be temporarily down, Please try again later"));
                        dispatch(alertActions.error("Connection Error.E-Johns Server may be temporarily down, Please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );

    };

    function request() {
        return {
            type: userConstants.USER_MANDATORY_UPDATE_REQUEST
        }
    }

    function success(user) {
        return {
            type: userConstants.USER_MANDATORY_UPDATE_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: userConstants.USER_MANDATORY_UPDATE_FAILURE,
            error
        }
    }
}




function updateUser(user) {

    return dispatch => {
        dispatch(request());
        if (user.curPassword) {
            userService.updateUserCredentials(user)
                .then(
                    users => {

                        dispatch(success(users));
                        dispatch(alertActions.success(" Successfully updated pasword"));
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000);

                    },

                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Connection Error. E-Johns Server may be temporarily down, Please try again later"));
                            dispatch(alertActions.error("Connection Error.E-Johns Server may be temporarily down, Please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        } else {
            userService.updateUserDetails(user)
                .then(
                    users => {
                        dispatch(success(users));
                        dispatch(alertActions.success(" Successfully updated User Email"));
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000);
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error));
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000);
                    }
                )
        }
    };

    function request() {
        return {
            type: userConstants.USER_UPDATE_REQUEST
        }
    }

    function success(user) {
        return {
            type: userConstants.USER_UPDATE_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: userConstants.USER_UPDATE_FAILURE,
            error
        }
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
        setTimeout(() => {
            dispatch(alertActions.clear())
        }, 5000)
    };

    function request(id) {
        return {
            type: userConstants.USER_DELETE_REQUEST,
            id
        }
    }

    function success(id) {
        return {
            type: userConstants.USER_DELETE_SUCCESS,
            id
        }
    }

    function failure(id, error) {
        return {
            type: userConstants.USER_DELETE_FAILURE,
            id,
            error
        }
    }
}