
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";
import {Collapse} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";



function  AttendanceStats() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const dispatch = useDispatch();
    const message = useSelector((state) => state.alert);
    const register = useSelector((state) => state.teacherReducer.attendanceStats);
    const netError = useSelector((state) => state.teacherReducer.netError);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)

    React.useEffect(() => {

        document.getElementById("date").value = new Date().toDateInputValue();
        if(!register&& !netError){
            dispatch(teacherActions.getAttendanceStats({"user":user,
                "date":new Date()}
            ))
        }

        dispatch(pagesActions.updatepage(pagesConstants.ATTENDANCE_STATS));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])


    const columns = [

        {
            title: "CLASS",
            field: "clazz"
        },
        {
            title:"TOTAL MALE" ,
            field: "numberOfBoys"
            // hidden:true
        },
        {
            title: "TOTAL FEMALE",
            field: "numberOfGirls"
        },
    {
        title: "TOTAL STUDENTS",
            field: "totalStudents"
    },
    {
        title:"MALE PRESENT" ,
            field: "numberOfBoysPresent"
        // hidden:true
    },
    {
        title: "FEMALE PRESENT",
            field: "numberOfGirlsPresent"
    },
    {
        title:"TOTAL PRESENT" ,
            field: "totalStudentsPresent"
        // hidden:true
    },

    {
        title: "TOTAL UNMARKED",
            field: "unmarked"
    }

    ];



    function retrieveStats() {
        let date = ""
        if(document.getElementById("date").valueAsDate==null){
            date =  document.getElementById("date").valueAsDate = new Date();
        }else {
            date =  document.getElementById("date").value+"T20:37:06.073Z";
        }

        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getAttendanceStats({"user":user,
            "date":date}
        ))

    }


    function compileStats() {

        let date = ""
        if(document.getElementById("date").valueAsDate==null){
            date =  document.getElementById("date").valueAsDate = new Date();
        }else {
            date =  document.getElementById("date").value+"T20:37:06.073Z";
        }

        confirmAlert({
            title: 'Confirm submission',
            message: 'Existing stats, if any shall be overwritten, Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(alertActions.success("Working ,please wait"));
                        dispatch(teacherActions.compileAttendanceStats({"user":user,
                            "date":date}
                        ))

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });
    }

    function downloadStats() {
        let date = ""
        if(document.getElementById("date").valueAsDate==null){
            date =  document.getElementById("date").valueAsDate = new Date();
        }else {
            date =  document.getElementById("date").value+"T20:37:06.073Z";
        }

        confirmAlert({
            title: 'Confirm downlaod',
            message: 'Are you sure you want to download statistics for '+date,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(alertActions.success("Working ,please wait"));
                        dispatch(teacherActions.downloadRegisterStats({"user":user,
                            "date":date}
                        ))

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });
    }

    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className={"block-header "}>
                    <div className={"row clearfix  align-items-end  "}>

                        <div className={"col-6 text-bold  "}>
                            <strong><h2>Attendance Stats </h2></strong>
                        </div>


                    </div>
                </div>
                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }
                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Attendance Statistics </li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}

                <div className="row p-3">
                    <div className="container-fluid  border p-3 panel-bg">
                        <div className="row align-content-center" >
                                <div className="col col-lg-3 shadow border border-md mx-1 p-2 bbg-white" >
                                    <div className="card-body">

                                        <div className="row"><div className="col text-dark"><strong>RETRIEVE DATE</strong></div></div><br/>
                                        <input id="date" className={"form-control"} type="date"/> <br/>
                                        <button className={"btn btn-small btn-primary mr-2"} onClick={retrieveStats}>retrieve</button>
                                        <button className={"btn btn-small btn-primary mr-2"} onClick={compileStats}>compile stats</button>
                                        <button className={"btn btn-small btn-success m-2"} onClick={downloadStats}>downloadStats</button>
                                    </div>

                                </div>

                            <div className="col">
                                <div className="col-6 card gradient-card  m-1  py-3">
                                    {register?(<>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col p-0"><strong>{register.date.toUpperCase()+" STATISTICS"}</strong></div></div>
                                            <div className="row"><div className="col p-0"><small>Total Students:</small></div><div className="col p-0 text-right">{register.totalBoys+register.totalGirls}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Female Students: </small></div><div className="col p-0 text-right">{register.totalGirls}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Male Students:</small> </div><div className="col p-0 text-right">{register.totalBoys}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Female Students present: </small></div><div className="col p-0 text-right" >{register.girlsPresent}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Male Students present: </small></div><div className="col p-0 text-right" >{register.boysPresent}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Unmarked Students : </small></div><div className="col p-0 text-right" >{register.unmarked}</div></div>
                                            <div className="row"><div className="col p-0"><small>Total Students present: </small></div><div className="col p-0 text-right" >{register.girlsPresent+register.boysPresent}</div></div>

                                        </div>
                                    </>):""}


                                </div>


                                </div>
                            </div>
                        </div>


                    </div>


                <div className="row mt-2 p-3">
                    <div className="col border p-0">
                        {register&&register.classAttendanceDTOS? <MaterialTable
                            title= {"SCHOOL ATTENDANCE [ "+register.date.toUpperCase()+" ]"}
                            icons={tableIcons}

                            data={register.classAttendanceDTOS}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />:<MaterialTable
                            title= "No Mark Schedule "
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}
                        />
                        }
                    </div>



                </div>
                <Footer/>

            </div>



        </>);

}
export default AttendanceStats;
