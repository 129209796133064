export const popupConstants = {

    INFOPOPUP: 'INFO_POPUP',
    CLEARPOPUP: 'CLEAR_POPUP',
    SETTINGS_POPUP: 'SETTINGS_POPUP',
    UPLOAD_POPUP:'UPLOAD_POPUP',
    MARK_PROFILE_POPUP:'MARK_PROFILE_POPUP',
    REPORT_PROFILES_POPUP: 'REPORT_PROFILES_POPUP',
    PASSWORD_RESET_POPUP:'PASSWORD_RESET_POPUP'



};