
import "../css/dash.css";


import React, {forwardRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";




function  AttendanceRegister() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert);
  const register = useSelector((state) => state.teacherReducer.attendanceRegister);
  const netError = useSelector((state) => state.teacherReducer.netError);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)

    React.useEffect(() => {
        let date =  document.getElementById("date").value+"T20:37:06.073Z";
        if(!register&& !netError){
            dispatch(teacherActions.getAttendanceRegister({"user":user,
                "date":new Date()}
            ))
        }

        dispatch(pagesActions.updatepage(pagesConstants.ATTENDANCE_REGISTER));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])


    const columns = [


        {
            title: "ID",
            field: "studentID",
        },
        {
            title:"Name" ,
            field: "fullName",
           // hidden:true
        },


        {
            title: "Attendance",
            field: "attendance",
        },

        {
            title: "Actions",
            field: "actions",
        }

    ];
    function retrieveAttendance() {
        let date = ""
        if(document.getElementById("date").valueAsDate==null){
            date =  document.getElementById("date").valueAsDate = new Date();
        }else {
            date =  document.getElementById("date").value+"T20:37:06.073Z";
        }

        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getAttendanceRegister({"user":user,
                "date":date}
        ))

    }


    function markPresent(e) {
        let date = register.date

        let id = e.target.getAttribute("data-id");
          let payload =
            {
            "StudentID" :id,
            "FullName":"name",
            "status":false,
            "Attendance" :true,
            "date" :date
            }
          let postPayload ={"user":user,
              "date":date}

            dispatch(alertActions.success("Working ,please wait"));
            dispatch(teacherActions.MarkStudentRegister(payload,postPayload))


    }
    function markAbsent(e) {
        let date = register.date
        let id = e.target.getAttribute("data-id");
        let payload =         {
            "StudentID" :id,
            "FullName":"name",
            "status":false,
            "Attendance" :false,
            "date" :date
        }
        let postPayload ={"user":user,
            "date":date}
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.MarkStudentRegister(payload,postPayload))

    }
    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>
                      <div className={"row clearfix  align-items-end  "}>

                          <div className={"col-6 text-bold  "}>
                              <strong><h2>Attendance Register </h2></strong>
                          </div>


                      </div>
                  </div>
                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Attendance Register </li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}

                    <div className="row justify-content-between align-content-center">
                        <div className="row p-3">
                            <div className="col card m-2" >
                                <div className="card-body">

                                    <div className="row"><div className="col text-dark"><strong>DATE</strong></div></div><br/>
                                    <input id="date" className={"form-control"} type="date"/> <br/>
                                    <button className={"btn btn-small btn-primary"} onClick={retrieveAttendance}>retrieve</button>

                            </div>

                            </div>

                        </div>

                        <div className="col m-2">


                        </div>

                    </div>
                    <div className="row mt-2 p-3">
                        <div className="col border p-0">
                          {register&&register.attendance? <MaterialTable
                              title= {(register.clazz?register.clazz:"SCHOOL ")     +" ATTENDANCE [ "+register.date.split("T")[0]+" ]"}
                              icons={tableIcons}

                              data={register.attendance.map(att=>{
                                  return(
                                      {
                                          ...att,
                                          attendance:

                                              att.attendance!=null? att.attendance?<><div className="badge badge-success" >PRESENT</div></>:
                                                        <><div className="badge badge-danger" >ABSENT</div></>:"",


                                 status:att.status?(<div className="col"> <i className="fas fa-check-circle text-success fa-2x"/></div>):(<div className="col-6">
                                              <i className="fas fa-times-circle text-danger fa-2x"/></div>),

                                          actions:
                                          att.attendance!=null?
                                                  att.attendance?

                                                     <> <button className={"btn btn-small btn-warning"} data-id={att.studentID} onClick={markAbsent}> Mark Absent</button></>:
                                              <><button className={"btn btn-small btn-primary"} data-id={att.studentID} onClick={markPresent}> Mark Present</button> </>:

                                                  <><button className={"btn btn-small btn-primary m-1"} data-id={att.studentID} onClick={markPresent}> Mark Present</button>
                                  <button className={"btn btn-small btn-warning "} data-id={att.studentID} onClick={markAbsent}> Mark Absent</button></>,

                                      }

                                  )
                              })
                              }
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}

                          />:<MaterialTable
                              title= "No Mark Schedule "
                              icons={tableIcons}
                              data={[]}
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                          />
                          }
                      </div>



                  </div>
                      <Footer/>

                  </div>



            </>);

}

export default AttendanceRegister;
