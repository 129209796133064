export const teacherConstants = {

 CHANGE_SUBJECT_VIEW:'CHANGE_SUBJECT_VIEW',
 CHANGE_SSE_STATUS:'CHANGE_SSE_STATUS',
 CUT_SSE:'CUT_SSE',

 RESET_CLEARANCES :'RESET_CLEARANCES',

 DELETE_REQUEST: 'DELETE_REQUEST',
 DELETE_SUCCESS: 'DELETE_SUCCESS',
 DELETE_FAILURE: 'DELETE_FAILURE',

 STUDENT_STATEMENT_RETRIEVE_REQUEST: 'STUDENT_STATEMENT_RETRIEVE_REQUEST',
 STUDENT_STATEMENT_RETRIEVE_SUCCESS: 'STUDENT_STATEMENT_RETRIEVE_SUCCESS',
 STUDENT_STATEMENT_RETRIEVE_FAILURE: 'STUDENT_STATEMENT_RETRIEVE_FAILURE',


 ACCOUNTS_RETRIEVE_REQUEST: 'ACCOUNTS_RETRIEVE_REQUEST',
 ACCOUNTS_RETRIEVE_SUCCESS: 'ACCOUNTS_RETRIEVE_SUCCESS',
 ACCOUNTS_RETRIEVE_FAILURE: 'ACCOUNTS_RETRIEVE_FAILURE',

 PDF_RETRIEVE_REQUEST: 'PDF_RETRIEVE_REQUEST',
 PDF_RETRIEVE_SUCCESS: 'PDF_RETRIEVE_SUCCESS',
 PDF_RETRIEVE_FAILURE: 'PDF_RETRIEVE_FAILURE',

 TEACHERS_RETRIEVE_REQUEST: 'TEACHERS_RETRIEVE_REQUEST',
 TEACHERS_RETRIEVE_SUCCESS: 'TEACHERS_RETRIEVE_SUCCESS',
 TEACHERS_RETRIEVE_FAILURE: 'TEACHERS_RETRIEVE_FAILURE',

 TEACHER_RETRIEVE_REQUEST: 'TEACHER_RETRIEVE_REQUEST',
 TEACHER_RETRIEVE_SUCCESS: 'TEACHER_RETRIEVE_SUCCESS',
 TEACHER_RETRIEVE_FAILURE: 'TEACHER_RETRIEVE_FAILURE',

 ACCOUNT_STATEMENT_RETRIEVE_REQUEST: 'ACCOUNT_STATEMENT_RETRIEVE_REQUEST',
 ACCOUNT_STATEMENT_RETRIEVE_SUCCESS: 'ACCOUNT_STATEMENT_RETRIEVE_SUCCESS',
 ACCOUNT_STATEMENT_RETRIEVE_FAILURE: 'ACCOUNT_STATEMENT_RETRIEVE_FAILURE',

 ACCOUNT_STATEMENT_PROFILE_RETRIEVE_REQUEST: 'ACCOUNT_STATEMENT_PROFILE_RETRIEVE_REQUEST',
 ACCOUNT_STATEMENT_PROFILE_RETRIEVE_SUCCESS: 'ACCOUNT_STATEMENT_PROFILE_RETRIEVE_SUCCESS',
 ACCOUNT_STATEMENT_PROFILE_RETRIEVE_FAILURE: 'ACCOUNT_STATEMENT_PROFILE_RETRIEVE_FAILURE',

 SUBJECT_CLASS_ADD_REQUEST: 'SUBJECT_CLASS_ADD_REQUEST',
 SUBJECT_CLASS_ADD_SUCCESS: 'SUBJECT_CLASS_ADD_SUCCESS',
 SUBJECT_CLASS_ADD_FAILURE: 'SUBJECT_CLASS_ADD_FAILURE',

 SUBJECT_CLASS_DELETE_REQUEST: 'SUBJECT_CLASS_DELETE_REQUEST',
 SUBJECT_CLASS_DELETE_SUCCESS: 'SUBJECT_CLASS_DELETE_SUCCESS',
 SUBJECT_CLASS_DELETE_FAILURE: 'SUBJECT_CLASS_DELETE_FAILURE',

 SUBJECT_DELETE_REQUEST: 'SUBJECT_DELETE_REQUEST',
 SUBJECT_DELETE_SUCCESS: 'SUBJECT_DELETE_SUCCESS',
 SUBJECT_DELETE_FAILURE: 'SUBJECT_DELETE_FAILURE',

 TEACHER_VALIDATE_REQUEST: 'TEACHER_VALIDATE_REQUEST',
 TEACHER_VALIDATE_SUCCESS: 'TEACHER_VALIDATE_SUCCESS',
 TEACHER_VALIDATE_FAILURE: 'TEACHER_VALIDATE_FAILURE',

 STUDENT_RETRIEVE_REQUEST: 'STUDENT_RETRIEVE_REQUEST',
 STUDENT_RETRIEVE_SUCCESS: 'STUDENT_RETRIEVE_SUCCESS',
 STUDENT_RETRIEVE_FAILURE: 'STUDENT_RETRIEVE_FAILURE',

 STUDENT_UPLOAD_REQUEST: 'STUDENT_UPLOAD_REQUEST',
 STUDENT_UPLOAD_SUCCESS: 'STUDENT_UPLOAD_SUCCESS',
 STUDENT_UPLOAD_FAILURE: 'STUDENT_UPLOAD_FAILURE',

 FILE_UPLOAD_REQUEST: 'FILE_UPLOAD_REQUEST',
 FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
 FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',

 STUDENT_DELETE_REQUEST: 'STUDENT_DELETE_REQUEST',
 STUDENT_DELETE_SUCCESS: 'STUDENT_DELETE_SUCCESS',
 STUDENT_DELETE_FAILURE: 'STUDENT_DELETE_FAILURE',

 STUDENT_ACTIVITIES_UPLOAD_REQUEST: 'STUDENT_ACTIVITIES_UPLOAD_REQUEST',
 STUDENT_ACTIVITIES_UPLOAD_SUCCESS: 'STUDENT_ACTIVITIES_UPLOAD_SUCCESS',
 STUDENT_ACTIVITIES_UPLOAD_FAILURE: 'STUDENT_ACTIVITIES_UPLOAD_FAILURE',

 MINI_PROFILE_RETRIEVE_REQUEST: 'MINI_PROFILE_RETRIEVE_REQUEST',
 MINI_PROFILE_RETRIEVE_SUCCESS: 'MINI_PROFILE_RETRIEVE_SUCCESS',
 MINI_PROFILE_RETRIEVE_FAILURE: 'MINI_PROFILE_RETRIEVE_FAILURE',

 TEACHER_MINI_PROFILE_RETRIEVE_REQUEST: 'TEACHER_MINI_PROFILE_RETRIEVE_REQUEST',
 TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS: 'TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS',
 TEACHER_MINI_PROFILE_RETRIEVE_FAILURE: 'TEACHER_MINI_PROFILE_RETRIEVE_FAILURE',

 PROFILE_RETRIEVE_REQUEST: 'PROFILE_RETRIEVE_REQUEST',
 PROFILE_RETRIEVE_SUCCESS: 'PROFILE_RETRIEVE_SUCCESS',
 PROFILE_RETRIEVE_FAILURE: 'PROFILE_RETRIEVE_FAILURE',

 UNREGISTERED_STUDENT_RETRIEVE_REQUEST: 'UNREGISTERED_STUDENT_RETRIEVE_REQUEST',
 UNREGISTERED_STUDENT_RETRIEVE_SUCCESS: 'UNREGISTERED_STUDENT_RETRIEVE_SUCCESS',
 UNREGISTERED_STUDENT_RETRIEVE_FAILURE: 'UNREGISTERED_STUDENT_RETRIEVE_FAILURE',

 REGISTERED_STUDENT_RETRIEVE_REQUEST: 'REGISTERED_STUDENT_RETRIEVE_REQUEST',
 REGISTERED_STUDENT_RETRIEVE_SUCCESS: 'REGISTERED_STUDENT_RETRIEVE_SUCCESS',
 REGISTERED_STUDENT_RETRIEVE_FAILURE: 'REGISTERED_STUDENT_RETRIEVE_FAILURE',

 MARKS_RETRIEVE_REQUEST: 'MARKS_RETRIEVE_REQUEST',
 MARKS_RETRIEVE_SUCCESS: 'MARKS_RETRIEVE_SUCCESS',
 MARKS_RETRIEVE_FAILURE: 'MARKS_RETRIEVE_FAILURE',

 STUDENT_PROFILE_RETRIEVE_REQUEST: 'STUDENT_PROFILE_RETRIEVE_REQUEST',
 STUDENT_PROFILE_RETRIEVE_SUCCESS: 'STUDENT_PROFILE_RETRIEVE_SUCCESS',
 STUDENT_PROFILE_RETRIEVE_FAILURE: 'STUDENT_PROFILE_RETRIEVE_FAILURE',

 CALENDAR_RETRIEVE_REQUEST: 'CALENDAR_RETRIEVE_REQUEST',
 CALENDAR_RETRIEVE_SUCCESS: 'CALENDAR_RETRIEVE_SUCCESS',
 CALENDAR_RETRIEVE_FAILURE: 'CALENDAR_RETRIEVE_FAILURE',

 CALENDAR_UPDATE_REQUEST: 'CALENDAR_UPDATE_REQUEST',
 CALENDAR_UPDATE_SUCCESS: 'CALENDAR_UPDATE_SUCCESS',
 CALENDAR_UPDATE_FAILURE: 'CALENDAR_UPDATE_FAILURE',

 CALENDAR_SAVE_REQUEST: 'CALENDAR_SAVE_REQUEST',
 CALENDAR_SAVE_SUCCESS: 'CALENDAR_SAVE_SUCCESS',
 CALENDAR_SAVE_FAILURE: 'CALENDAR_SAVE_FAILURE',

 ASSESSMENT_MARKS_RETRIEVE_REQUEST: 'ASSESSMENT_MARKS_RETRIEVE_REQUEST',
 ASSESSMENT_MARKS_RETRIEVE_SUCCESS: 'ASSESSMENT_MARKS_RETRIEVE_SUCCESS',
 ASSESSMENT_MARKS_RETRIEVE_FAILURE: 'ASSESSMENT_MARKS_RETRIEVE_FAILURE',

 ASSESSMENT_MARKS_DELETE_REQUEST: 'ASSESSMENT_MARKS_DELETE_REQUEST',
 ASSESSMENT_MARKS_DELETE_SUCCESS: 'ASSESSMENT_MARKS_DELETE_SUCCESS',
 ASSESSMENT_MARKS_DELETE_FAILURE: 'ASSESSMENT_MARKS_DELETE_FAILURE',

 CALENDAR_DELETE_REQUEST: 'CALENDAR_DELETE_REQUEST',
 CALENDAR_DELETE_SUCCESS: 'CALENDAR_DELETE_SUCCESS',
 CALENDAR_DELETE_FAILURE: 'CALENDAR_DELETE_FAILURE',

 NOTIFICATIONS_RETRIEVE_REQUEST: 'NOTIFICATIONS_RETRIEVE_REQUEST',
 NOTIFICATIONS_RETRIEVE_SUCCESS: 'NOTIFICATIONS_RETRIEVE_SUCCESS',
 NOTIFICATIONS_RETRIEVE_FAILURE: 'NOTIFICATIONS_RETRIEVE_FAILURE',

 NOTIFICATIONS_UPDATE_REQUEST: 'NOTIFICATIONS_UPDATE_REQUEST',
 NOTIFICATIONS_UPDATE_SUCCESS: 'NOTIFICATIONS_UPDATE_SUCCESS',
 NOTIFICATIONS_UPDATE_FAILURE: 'NOTIFICATIONS_UPDATE_FAILURE',

 NOTIFICATIONS_SAVE_REQUEST: 'NOTIFICATIONS_SAVE_REQUEST',
 NOTIFICATIONS_SAVE_SUCCESS: 'NOTIFICATIONS_SAVE_SUCCESS',
 NOTIFICATIONS_SAVE_FAILURE: 'NOTIFICATIONS_SAVE_FAILURE',

 NOTIFICATIONS_DELETE_REQUEST: 'NOTIFICATIONS_DELETE_REQUEST',
 NOTIFICATIONS_DELETE_SUCCESS: 'NOTIFICATIONS_DELETE_SUCCESS',
 NOTIFICATIONS_DELETE_FAILURE: 'NOTIFICATIONS_DELETE_FAILURE',


 ASSESSMENT_MARKS_SUBMIT_REQUEST: 'ASSESSMENT_MARKS_SUBMIT_REQUEST',
 ASSESSMENT_MARKS_SUBMIT_SUCCESS: 'ASSESSMENT_MARKS_SUBMIT_SUCCESS',
 ASSESSMENT_MARKS_SUBMIT_FAILURE: 'ASSESSMENT_MARKS_SUBMIT_FAILURE',


 MARKS_SUBMIT_REQUEST: 'MARKS_SUBMIT_REQUEST',
 MARKS_SUBMIT_SUCCESS: 'MARKS_SUBMIT_SUCCESS',
 MARKS_SUBMIT_FAILURE: 'MARKS_SUBMIT_FAILURE',

 ALTER_STUDENTS:"ALTER_STUDENTS",

 OPTIONS_REQUEST: 'OPTIONS_REQUEST',
 OPTIONS_SUCCESS: 'OPTIONS_SUCCESS',
 OPTIONS_FAILURE: 'OPTIONS_FAILURE',

 STUDENT_ADD_REQUEST: 'STUDENT_ADD_REQUEST',
 STUDENT_ADD_SUCCESS: 'STUDENT_ADD_SUCCESS',
 STUDENT_ADD_FAILURE: 'STUDENT_ADD_FAILURE',

 TEACHER_ADD_REQUEST: 'TEACHER_ADD_REQUEST',
 TEACHER_ADD_SUCCESS: 'TEACHER_ADD_SUCCESS',
 TEACHER_ADD_FAILURE: 'TEACHER_ADD_FAILURE',

 TEACHER_EDIT_REQUEST: 'TEACHER_EDIT_REQUEST',
 TEACHER_EDIT_SUCCESS: 'TEACHER_EDIT_SUCCESS',
 TEACHER_EDIT_FAILURE: 'TEACHER_EDIT_FAILURE',

 TEACHER_REMOVE_REQUEST: 'TEACHER_REMOVE_REQUEST',
 TEACHER_REMOVE_SUCCESS: 'TEACHER_REMOVE_SUCCESS',
 TEACHER_REMOVE_FAILURE: 'TEACHER_REMOVE_FAILURE',



 STUDENT_ARREAR_ADD_REQUEST: 'STUDENT_ARREAR_ADD_REQUEST',
 STUDENT_ARREAR_ADD_SUCCESS: 'STUDENT_ARREAR_ADD_SUCCESS',
 STUDENT_ARREAR_ADD_FAILURE: 'STUDENT_ARREAR_ADD_FAILURE',

 DEPARTMENT_CLEAR_REQUEST: 'DEPARTMENT_CLEAR_REQUEST',
 DEPARTMENT_CLEAR_SUCCESS: 'DEPARTMENT_CLEAR_SUCCESS',
 DEPARTMENT_CLEAR_FAILURE: ' DEPARTMENT_CLEAR_FAILURE',

 REPORT_GEN_REQUEST: 'REPORT_GEN_REQUEST',
 REPORT_GEN_SUCCESS: 'REPORT_GEN_SUCCESS',
 REPORT_GEN_FAILURE: 'REPORT_GEN_FAILURE',

 STUDENT_ARREAR_CLEAR_REQUEST: 'STUDENT_ARREAR_CLEAR_REQUEST',
 STUDENT_ARREAR_CLEAR_SUCCESS: 'STUDENT_ARREAR_CLEAR_SUCCESS',
 STUDENT_ARREAR_CLEAR_FAILURE: 'STUDENT_ARREAR_CLEAR_FAILURE',

 CLUB_MARKS_RETRIEVE_REQUEST: 'CLUB_MARKS_RETRIEVE_REQUEST',
 CLUB_MARKS_RETRIEVE_SUCCESS: 'CLUB_MARKS_RETRIEVE_SUCCESS',
 CLUB_MARKS_RETRIEVE_FAILURE: 'CLUB_MARKS_RETRIEVE_FAILURE',

UPLOAD_RETRIEVE_REQUEST: 'UPLOAD_RETRIEVE_REQUEST',
UPLOAD_RETRIEVE_SUCCESS: 'UPLOAD_RETRIEVE_SUCCESS',
UPLOAD_RETRIEVE_FAILURE: 'UPLOAD_RETRIEVE_FAILURE',

 UPLOAD_SUBMIT_REQUEST: 'UPLOAD_SUBMIT_REQUEST',
 UPLOAD_SUBMIT_SUCCESS: 'UPLOAD_SUBMIT_SUCCESS',
 UPLOAD_SUBMIT_FAILURE: 'UPLOAD_SUBMIT_FAILURE',

 NEW_SUBJECT_EDIT_REQUEST: 'NEW_SUBJECT_EDIT_REQUEST',
 NEW_SUBJECT_EDIT_SUCCESS: 'NEW_SUBJECT_EDIT_SUCCESS',
 NEW_SUBJECT_EDIT_FAILURE: 'NEW_SUBJECT_EDIT_FAILURE',

 NEW_SUBJECT_SUBMIT_REQUEST: 'NEW_SUBJECT_SUBMIT_REQUEST',
 NEW_SUBJECT_SUBMIT_SUCCESS: 'NEW_SUBJECT_SUBMIT_SUCCESS',
 NEW_SUBJECT_SUBMIT_FAILURE: 'NEW_SUBJECT_SUBMIT_FAILURE',

 NEW_SUBJECT_REMOVE_REQUEST: 'NEW_SUBJECT_REMOVE_REQUEST',
 NEW_SUBJECT_REMOVE_SUCCESS: 'NEW_SUBJECT_REMOVE_SUCCESS',
 NEW_SUBJECT_REMOVE_FAILURE: 'NEW_SUBJECT_REMOVE_FAILURE',

 SUBJECT_CLASS_SUBMIT_REQUEST: 'SUBJECT_CLASS_SUBMIT_REQUEST',
 SUBJECT_CLASS_SUBMIT_SUCCESS: 'SUBJECT_CLASS_SUBMIT_SUCCESS',
 SUBJECT_CLASS_SUBMIT_FAILURE: 'SUBJECT_CLASS_SUBMIT_FAILURE',

 NEW_ASSMNT_SUBMIT_REQUEST: 'NEW_ASSMNT_SUBMIT_REQUEST',
 NEW_ASSMNT_SUBMIT_SUCCESS: 'NEW_ASSMNT_SUBMIT_SUCCESS',
 NEW_ASSMNT_SUBMIT_FAILURE: 'NEW_ASSMNT_SUBMIT_FAILURE',


 CLUB_MARKS_SUBMIT_REQUEST: 'CLUB_MARKS_SUBMIT_REQUEST',
 CLUB_MARKS_SUBMIT_SUCCESS: 'CLUB_MARKS_SUBMIT_SUCCESS',
 CLUB_MARKS_SUBMIT_FAILURE: 'CLUB_MARKS_SUBMIT_FAILURE',

 SPORT_MARKS_RETRIEVE_REQUEST: 'SPORT_MARKS_RETRIEVE_REQUEST',
 SPORT_MARKS_RETRIEVE_SUCCESS: 'SPORT_MARKS_RETRIEVE_SUCCESS',
 SPORT_MARKS_RETRIEVE_FAILURE: 'SPORT_MARKS_RETRIEVE_FAILURE',

 DEPARTMENT_RETRIEVE_REQUEST: 'DEPARTMENT_RETRIEVE_REQUEST',
 DEPARTMENT_RETRIEVE_SUCCESS: 'DEPARTMENT_RETRIEVE_SUCCESS',
 DEPARTMENT_RETRIEVE_FAILURE: 'DEPARTMENT_RETRIEVE_FAILURE',

 SPORT_MARKS_SUBMIT_REQUEST: 'SPORT_MARKS_SUBMIT_REQUEST',
 SPORT_MARKS_SUBMIT_SUCCESS: 'SPORT_MARKS_SUBMIT_SUCCESS',
 SPORT_MARKS_SUBMIT_FAILURE: 'SPORT_MARKS_SUBMIT_FAILURE',


 ARREARS_RETRIEVE_REQUEST: 'ARREARS_RETRIEVE_REQUEST',
 ARREARS_RETRIEVE_SUCCESS: 'ARREARS_RETRIEVE_SUCCESS',
 ARREARS_RETRIEVE_FAILURE: 'ARREARS_RETRIEVE_FAILURE',

 MARK_PROFILES_RETRIEVE_REQUEST: 'MARK_PROFILES_RETRIEVE_REQUEST',
 MARK_PROFILES_RETRIEVE_SUCCESS: 'MARK_PROFILES_RETRIEVE_SUCCESS',
 MARK_PROFILES_RETRIEVE_FAILURE: 'MARK_PROFILES_RETRIEVE_FAILURE',


 STUDENT_VALIDATE_REQUEST: 'STUDENT_VALIDATE_REQUEST',
 STUDENT_VALIDATE_SUCCESS: 'STUDENT_VALIDATE_SUCCESS',
 STUDENT_VALIDATE_FAILURE: 'STUDENT_VALIDATE_FAILURE',

 REGISTER_STUDENT_REQUEST: 'REGISTER_STUDENT_REQUEST',
 REGISTER_STUDENT_SUCCESS: 'REGISTER_STUDENT_SUCCESS',
 REGISTER_STUDENT_FAILURE: 'REGISTER_STUDENT_FAILURE',

 CONTACTS_RETRIEVE_REQUEST: 'CONTACTS_RETRIEVE_REQUEST',
 CONTACTS_RETRIEVE_SUCCESS: 'CONTACTS_RETRIEVE_SUCCESS',
 CONTACTS_RETRIEVE_FAILURE: 'CONTACTS_RETRIEVE_FAILURE',

 CONTACT_UPDATE_REQUEST: 'CONTACT_UPDATE_REQUEST',
 CONTACT_UPDATE_SUCCESS: 'CONTACT_UPDATE_SUCCESS',
 CONTACT_UPDATE_FAILURE: 'CONTACT_UPDATE_FAILURE',

 CONTACT_DELETE_REQUEST: 'CONTACT_DELETE_REQUEST',
 CONTACT_DELETE_SUCCESS: 'CONTACT_DELETE_SUCCESS',
 CONTACT_DELETE_FAILURE: 'CONTACT_DELETE_FAILURE',

 MEDICALS_RETRIEVE_REQUEST: 'MEDICALS_RETRIEVE_REQUEST',
 MEDICALS_RETRIEVE_SUCCESS: 'MEDICALS_RETRIEVE_SUCCESS',
 MEDICALS_RETRIEVE_FAILURE: 'MEDICALS_RETRIEVE_FAILURE',

 SINGLE_SUBJECT_INFORMATION_RETRIEVE_REQUEST: 'SINGLE_SUBJECT_INFORMATION_RETRIEVE_REQUEST',
 SINGLE_SUBJECT_INFORMATION_RETRIEVE_SUCCESS: 'SINGLE_SUBJECT_INFORMATION_RETRIEVE_SUCCESS',
 SINGLE_SUBJECT_INFORMATION_RETRIEVE_FAILURE: 'SINGLE_SUBJECT_INFORMATION_RETRIEVE_FAILURE',

 SUBJECT_INFORMATION_RETRIEVE_REQUEST: 'SUBJECT_INFORMATION_RETRIEVE_REQUEST',
 SUBJECT_INFORMATION_RETRIEVE_SUCCESS: 'SUBJECT_INFORMATION_RETRIEVE_SUCCESS',
 SUBJECT_INFORMATION_RETRIEVE_FAILURE: 'SUBJECT_INFORMATION_RETRIEVE_FAILURE',

 MEDICAL_RETRIEVE_REQUEST: 'MEDICAL_RETRIEVE_REQUEST',
 MEDICAL_RETRIEVE_SUCCESS: 'MEDICAL_RETRIEVE_SUCCESS',
 MEDICAL_RETRIEVE_FAILURE: 'MEDICAL_RETRIEVE_FAILURE',

 MEDICALS_UPDATE_REQUEST: 'MEDICALS_UPDATE_REQUEST',
 MEDICALS_UPDATE_SUCCESS: 'MEDICALS_UPDATE_SUCCESS',
 MEDICALS_UPDATE_FAILURE: 'MEDICALS_UPDATE_FAILURE',

 STUDENTS_COMMENTS_RETRIEVE_REQUEST: 'STUDENTS_COMMENTS_RETRIEVE_REQUEST',
 STUDENTS_COMMENTS_RETRIEVE_SUCCESS: 'STUDENTS_COMMENTS_RETRIEVE_SUCCESS',
 STUDENTS_COMMENTS_RETRIEVE_FAILURE: 'STUDENTS_COMMENTS_RETRIEVE_FAILURE',


 STUDENT_COMMENT_SUBMIT_REQUEST: 'STUDENT_COMMENT_SUBMIT_REQUEST',
 STUDENT_COMMENT_SUBMIT_SUCCESS: 'STUDENT_COMMENT_SUBMIT_SUCCESS',
 STUDENT_COMMENT_SUBMIT_FAILURE: 'STUDENT_COMMENT_SUBMIT_FAILURE',

 DEPARTMENT_SUBMISSION_RESET_REQUEST: 'DEPARTMENT_SUBMISSION_RESET_REQUEST',
 DEPARTMENT_SUBMISSION_RESET_SUCCESS: 'DEPARTMENT_SUBMISSION_RESET_SUCCESS',
 DEPARTMENT_SUBMISSION_RESET_FAILURE: 'DEPARTMENT_SUBMISSION_RESET_FAILURE',

 DEPARTMENT_RESET_REQUEST: 'DEPARTMENT_RESET_REQUEST',
 DEPARTMENT_RESET_SUCCESS: 'DEPARTMENT_RESET_SUCCESS',
 DEPARTMENT_RESET_FAILURE: 'DEPARTMENT_RESET_FAILURE',

 SUBJECT_RESET_REQUEST: 'SUBJECT_RESET_REQUEST',
 SUBJECT_RESET_SUCCESS: 'SUBJECT_RESET_SUCCESS',
 SUBJECT_RESET_FAILURE: 'SUBJECT_RESET_FAILURE',

 CLASS_RESET_REQUEST: 'CLASS_RESET_REQUEST',
 CLASS_RESET_SUCCESS: 'CLASS_RESET_SUCCESS',
 CLASS_RESET_FAILURE: 'CLASS_RESET_FAILURE',

 STUDENT_ID_RESET_REQUEST:'STUDENT_ID_RESET_REQUEST',

 TEACHER_ID_RESET_REQUEST:'TEACHER_ID_RESET_REQUEST',

 STUDENT_REGISTRATION_EDIT_REQUEST:" STUDENT_REGISTRATION_EDIT_REQUEST",
 STUDENT_REGISTRATION_EDIT_SUCCESS:" STUDENT_REGISTRATION_EDIT_SUCCESS",
 STUDENT_REGISTRATION_EDIT_FAILURE:" STUDENT_REGISTRATION_EDIT_FAILURE",


 ALL_ARREARS_RETRIEVE_REQUEST: 'ALL_ARREARS_RETRIEVE_REQUEST',
 ALL_ARREARS_RETRIEVE_SUCCESS: 'ALL_ARREARS_RETRIEVE_SUCCESS',
 ALL_ARREARS_RETRIEVE_FAILURE: 'ALL_ARREARS_RETRIEVE_FAILURE',

 REPORT_RETRIEVE_REQUEST:" REPORT_RETRIEVE_REQUEST",
 REPORT_RETRIEVE_SUCCESS:" REPORT_RETRIEVE_SUCCESS",
 REPORT_RETRIEVE_FAILURE:" REPORT_RETRIEVE_FAILURE",

 REPORT_STATISTICS_REQUEST:" REPORT_STATISTICS_REQUEST",
 REPORT_STATISTICS_SUCCESS:" REPORT_STATISTICS_SUCCESS",
 REPORT_STATISTICS_FAILURE:" REPORT_STATISTICS_FAILURE",


 FILE_DELETE_SUCCESS:"FILE_DELETE_SUCCESS",
 FILE_DELETE_FAILURE:"FILE_DELETE_FAILURE",
 FILE_DELETE_REQUEST:"FILE_DELETE_REQUEST",

 ALTER_TEACHER_SUBJECTS:'ALTER_TEACHER_SUBJECTS',
 RESET_NET_ERROR:'RESET_NET_ERROR',
RESET_MARKS:'RESET_MARKS',

 DEPARTMENT_DELETE_REQUEST: 'DEPARTMENT_DELETE_REQUEST',
 DEPARTMENT_DELETE_SUCCESS: 'DEPARTMENT_DELETE_SUCCESS',
 DEPARTMENT_DELETE_FAILURE: 'DEPARTMENT_DELETE_FAILURE',

 DEPARTMENT_EDIT_REQUEST: 'DEPARTMENT_EDIT_REQUEST',
 DEPARTMENT_EDIT_SUCCESS: 'DEPARTMENT_EDIT_SUCCESS',
 DEPARTMENT_EDIT_FAILURE: 'DEPARTMENT_EDIT_FAILURE',

 DEPARTMENT_SAVE_REQUEST: 'DEPARTMENT_SAVE_REQUEST',
 DEPARTMENT_SAVE_SUCCESS: 'DEPARTMENT_SAVE_SUCCESS',
 DEPARTMENT_SAVE_FAILURE: 'DEPARTMENT_SAVE_FAILURE',

 DEPARTMENT_REQUEST_REQUEST: 'DEPARTMENT_REQUEST_REQUEST',
 DEPARTMENT_REQUEST_SUCCESS: 'DEPARTMENT_REQUEST_SUCCESS',
 DEPARTMENT_REQUEST_FAILURE: 'DEPARTMENT_REQUEST_FAILURE',

 CLUB_DELETE_REQUEST: 'CLUB_DELETE_REQUEST',
 CLUB_DELETE_SUCCESS: 'CLUB_DELETE_SUCCESS',
 CLUB_DELETE_FAILURE: 'CLUB_DELETE_FAILURE',

 CLUB_EDIT_REQUEST: 'CLUB_EDIT_REQUEST',
 CLUB_EDIT_SUCCESS: 'CLUB_EDIT_SUCCESS',
 CLUB_EDIT_FAILURE: 'CLUB_EDIT_FAILURE',

 CLUB_SAVE_REQUEST: 'CLUB_SAVE_REQUEST',
 CLUB_SAVE_SUCCESS: 'CLUB_SAVE_SUCCESS',
 CLUB_SAVE_FAILURE: 'CLUB_SAVE_FAILURE',

 CLUB_REQUEST_REQUEST: 'CLUB_REQUEST_REQUEST',
 CLUB_REQUEST_SUCCESS: 'CLUB_REQUEST_SUCCESS',
 CLUB_REQUEST_FAILURE: 'CLUB_REQUEST_FAILURE',

 SPORT_DELETE_REQUEST: 'SPORT_DELETE_REQUEST',
 SPORT_DELETE_SUCCESS: 'SPORT_DELETE_SUCCESS',
 SPORT_DELETE_FAILURE: 'SPORT_DELETE_FAILURE',

 SPORT_EDIT_REQUEST: 'SPORT_EDIT_REQUEST',
 SPORT_EDIT_SUCCESS: 'SPORT_EDIT_SUCCESS',
 SPORT_EDIT_FAILURE: 'SPORT_EDIT_FAILURE',

 SPORT_SAVE_REQUEST: 'SPORT_SAVE_REQUEST',
 SPORT_SAVE_SUCCESS: 'SPORT_SAVE_SUCCESS',
 SPORT_SAVE_FAILURE: 'SPORT_SAVE_FAILURE',

 SPORT_REQUEST_REQUEST: 'SPORT_REQUEST_REQUEST',
 SPORT_REQUEST_SUCCESS: 'SPORT_REQUEST_SUCCESS',
 SPORT_REQUEST_FAILURE: 'SPORT_REQUEST_FAILURE',

 STUDENT_ACCOUNT_SUBMIT_REQUEST: 'STUDENT_ACCOUNT_SUBMIT_REQUEST',
 STUDENT_ACCOUNT_SUBMIT_SUCCESS: 'STUDENT_ACCOUNT_SUBMIT_SUCCESS',
 STUDENT_ACCOUNT_SUBMIT_FAILURE: 'STUDENT_ACCOUNT_SUBMIT_FAILURE',

 ATTENDANCE_REGISTER_RETRIEVE_REQUEST:'ATTENDANCE_REGISTER_RETRIEVE_REQUEST',
  ATTENDANCE_REGISTER_RETRIEVE_SUCCESS:'ATTENDANCE_REGISTER_RETRIEVE_SUCCESS',
 ATTENDANCE_REGISTER_RETRIEVE_FAILURE:'ATTENDANCE_REGISTER_RETRIEVE_FAILURE',

 REPORT_RESET_REQUEST:"REPORT_RESET_REQUEST",

 MARK_REGISTER_REQUEST: "MARK_REGISTER_REQUEST",
 MARK_REGISTER_FAILURE: "MARK_REGISTER_FAILURE",
 MARK_REGISTER_SUCCESS: "MARK_REGISTER_SUCCESS",

 ATTENDANCE_STATS_RETRIEVE_REQUEST:'ATTENDANCE_STATS_RETRIEVE_REQUEST',
 ATTENDANCE_STATS_RETRIEVE_SUCCESS:'ATTENDANCE_STATS_RETRIEVE_SUCCESS',
 ATTENDANCE_STATS_RETRIEVE_FAILURE:'ATTENDANCE_STATS_RETRIEVE_FAILURE',



};