export const userConstants = {
    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    USER_MANDATORY_UPDATE_REQUEST: 'USER_MANDATORY_UPDATE_REQUEST',
    USER_MANDATORY_UPDATE_SUCCESS: 'USER_MANDATORY_UPDATE_SUCCESS',
    USER_MANDATORY_UPDATE_FAILURE: 'USER_MANDATORY_UPDATE_FAILURE',

    USER_FETCH_REQUEST: 'USER_FETCH_REQUEST',
    USER_FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
    USER_FETCH_FAILURE: 'USER_FETCH_FAILURE',

    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    SET_EMAIL_REQUEST: 'SET_EMAIL_REQUEST',
    SET_EMAIL_SUCCESS: 'SET_EMAIL_SUCCESS',
    SET_EMAIL_FAILURE: 'SET_EMAIL_FAILURE',

    USER_LOGOUT: 'USER_LOGOUT',

    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',

    // BASIC_USER: "BASIC_USER",
    BASIC_USER: "ROLE_USER",
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",


   STUDENT: "ROLE_STUDENT",
   TEACHER:"ROLE_TEACHER",
    HOD:"ROLE_HOD",
    HEADMASTER: "ROLE_HEAD",
    ADMIN:"ROLE_ADMIN",
    ACCOUNTS:"ROLE_ACCOUNTS",

    SPORTTEACHER:"ROLE_SPORTTEACHER",
    CLASSTEACHER:"ROLE_CLASSTEACHER",
    CLUBTEACHER:"ROLE_CLUBTEACHER"
};