import React from "react";
import TICHome from "../Pages/TICHome";
import ManageStudents from "../Pages/ManageStudents";
import AddStudent from "../Pages/AddStudent";
import { Switch, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import RegisterStudents from "../Pages/RegisterStudents";
import StudentArrears from "../Pages/StudentArrears";
import Sports from "../Pages/Sports";
import Clubs from "../Pages/Clubs";
import Departments from "../Pages/Departments";
import StudentContacts from "../Pages/StudentContacts";
import StudentMedicals from "../Pages/StudentMedicals";
import StudentBehaviorAndAttendance from "../Pages/StudentBehaviorAndAttendance";
import EditStudent from "../Pages/EditStudent";
import SettingsPopup from "./SettingsPopup";
import {useDispatch, useSelector} from "react-redux";
import {popupActions} from "../actions/popup.actions";
import CalendarEvents from "../Pages/CalendarEvents";
import ManageCalendar from "../Pages/ManageCalendar";
import ManageNotifications from "../Pages/ManageNotifications";
import Profile from "../Pages/Profile";
import StudentResults from "../Pages/StudentResults";
import ClassStudents from "../Pages/ClassStudents";
import PortalUsers from "../Pages/PortalUsers";
import AddPortalUser from "../Pages/AddPortalUser";
import ManageTeachers from "../Pages/ManageTeachers";
import AddTeacher from "../Pages/AddTeacher";
import EditTeacher from "../Pages/EditTeacher";
import MarkingSchema from "../Pages/MarkingSchema";
import Registration from "../Pages/Registration";
import ReportsCenter from "../Pages/ReportsCenter";
import ELearning from "../Pages/ELearning";
import AssessmentMarks from "../Pages/AssessmentMarks";
import AssessmentResults from "../Pages/AssessmentResults";
import ManageSubjects from "../Pages/ManageSubjects";
import EditSubject from "../Pages/EditSubject";
import ManageDepartments from "../Pages/ManageDepartments";
import ManageSports from "../Pages/ManageSports";
import ManageClubs from "../Pages/ManageClubs";
import StudentReports from "../Pages/StudentReports";
import StudentResultsViewer from "../Pages/StudentResultsViewer";
import StudentAccounts from "../Pages/StudentAccounts";
import StudentsMonthlyStatements from "../Pages/StudentsMonthlyStatements";
import StudentStatements from "../Pages/StudentStatements";
import StudentStatementsViewer from "../Pages/StudentStatementsViewer";
import StudentArrearsSB from "../Pages/StudentArrearsSB";
import PasswordResetPopup from "./PasswordResetPopup";
import AttendanceRegister from "../Pages/AttendanceRegister";
import AttendanceStats from "../Pages/AttendanceStats";


Date.prototype.toDateInputValue=(function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes()- this.getTimezoneOffset());
  return local.toJSON().slice(0,10);
})


function Main() {
    const settingsPopup = useSelector(
        (state) => state.popupReducer.settingsPopup
    );
  const passwordReset = useSelector(
      (state) => state.authenticationReducer.user.reset
  );
    const dispatch = useDispatch();


  return (
    <React.Fragment>


        <SettingsPopup
            show={settingsPopup}
            onHide={() =>  { document.getElementsByClassName("modal-backdrop")[0].hidden = true;
                            dispatch(popupActions.clear()
                            )}}
        />

      {/*<PasswordResetPopup*/}
      {/*    show={passwordReset}*/}
      {/*    onHide={() =>  { document.getElementsByClassName("modal-backdrop")[0].hidden = true;*/}
      {/*      dispatch(popupActions.clear()*/}
      {/*      )}}*/}
      {/*/>*/}
    <Navbar />   {/*top title bar */}
    <Sidebar />   {/*   left side bar */}

       {/* main interchangeable window below */}

        <Switch>
          <Route exact path="/home/Marks" component={TICHome} />
          <Route exact path="/home/ClassStudents" component={ClassStudents} />
          <Route exact path="/home/AttendanceStats" component={AttendanceStats} />

          <Route exact path="/home/StudentResults/:ID/:TimeOC" component={StudentResults} />
          <Route exact path="/home/StudentResults/" component={StudentResults} />
          <Route exact path="/home/StudentResults/:ID" component={StudentResults} />

            <Route exact path="/home/StudentStatements/:ID" component={StudentStatements} />

          <Route exact path="/home/StudentResultsViewer/" component={StudentResultsViewer}/><Route exact path="/home/StudentBursary" component={StudentStatementsViewer}/><Route  exact path="/home/AttendanceRegister" component={AttendanceRegister} />
          <Route  exact path="/home" component={Profile} />
          <Route  exact path="/home/ManageStudents" component={ManageStudents} />
          <Route  exact path="/home/StudentsMonthlyStatements" component={StudentsMonthlyStatements} />
          <Route  exact path="/home/StudentAccounts" component={StudentAccounts} />
          <Route  exact path="/home/addStudent" component={AddStudent} />
          <Route  exact path="/home/addTeacher" component={AddTeacher} />
          <Route  exact path="/home/editTeacher/:id" component={EditTeacher} />
          <Route  exact path="/home/SportMarks" component={Sports} />
          <Route  exact path="/home/ClubMarks"  component={Clubs} />
          <Route  exact path="/home/Departments"  component={Departments} />
          <Route  exact path="/home/Contacts"  component={StudentContacts} />
          <Route  exact path="/home/Medicals"  component={StudentMedicals} />
          <Route  exact path="/home/StudentBehaviorAndAttendance" component={StudentBehaviorAndAttendance} />
          <Route  exact path="/home/StudentArrears" component={StudentArrears} />
          <Route  exact path="/home/StudentArrearsSB" component={StudentArrearsSB} />
          <Route  exact path="/home/registerStudents" component={RegisterStudents} />
          <Route  exact path="/home/EditStudent/:ID" component={EditStudent} />
          <Route  exact path="/home/Calendar" component={CalendarEvents} />
          <Route  exact path="/home/ManageCalendar" component={ManageCalendar} />
          <Route  exact path="/home/ManageNotifications" component={ManageNotifications} />
          <Route  exact path="/home/admin/PortalUsers" component={PortalUsers} />
          <Route  exact path="/home/admin/AddUser" component={AddPortalUser} />
          <Route  exact path="/home/ManageTeachers" component={ManageTeachers} />
          <Route  exact path="/home/MarkingScheme" component={MarkingSchema} />
          <Route  exact path="/home/ManageSubjects" component={ManageSubjects} />
          <Route  exact path="/home/Registration" component={Registration} />
          <Route  exact path="/home/ELearning/:subject" component={ELearning} />
          <Route  exact path="/home/AssessmentMarks/:id" component={AssessmentMarks} />
          <Route  exact path="/home/reportsCenter-re-99-33-98-mcx0OFk" component={ReportsCenter}/>
          <Route  exact path="/home/AssessmentResults/:subject" component={AssessmentResults}/>
          <Route  exact path="/home/EditSubject/:ID" component={EditSubject}/>
          <Route  exact path="/home/ManageDepartments" component={ManageDepartments}/>
          <Route  exact path="/home/ManageSports" component={ManageSports}/>
          <Route  exact path="/home/ManageClubs" component={ManageClubs}/>
          <Route  exact path="/home/StudentReports" component={StudentReports}/>




      </Switch>
    </React.Fragment>
  );
}
export default Main;
