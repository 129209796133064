export const apiConstants = {

     //TEST SERVER
     //API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005'


     //LIVE SERVER
     API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'https://ejohnsonline.com/CompuLearn'


}