
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Switch from "react-switch";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete"; // Import css
import "@webscopeio/react-textarea-autocomplete/style.css";

function  TICHome() {


    const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

    const Loading = ({ data }) => <div>Loading</div>;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

  const dispatch = useDispatch();

  const tic = useSelector((state) => state.teacherReducer.TIC);
  const marksSubmit = useSelector((state) => state.teacherReducer.submittedMarks);
  //const loading = useSelector((state) => state.teacherReducer.submittingMarks);
  const user = useSelector((state)=>state.authenticationReducer.user.userId)
  const message = useSelector((state) => state.alert);
  const marks = useSelector((state) => state.teacherReducer.Marks);
  const netError = useSelector((state) => state.teacherReducer.netError);

  React.useEffect(() => {
          if(!tic&&!netError){
            dispatch(teacherActions.getTeacher(user))


            if (marksSubmit){
                 SubmitClass()

              }else{

            }



          }
          dispatch(pagesActions.updatepage(pagesConstants.HOME));
      return(()=>{
          dispatch(teacherActions.resetNetError())
      })
  },[])

  const [currentSubject,setCurrentSubject] = useState({
    subjectKey:"",
    classKey:"",
    classes:[],
      csv:""
  });

    const columns = [

        {
            title:"Name" ,
            field: "Name",
            editable:"never",
            width:2
           // hidden:true
        },
        {
            title: "Position",
            field:"classPosition" ,
            editable:"never",
            width:2
        },

        {
            title:"Score" ,
            field:"score" ,
            type:"numeric",
            width:2
        }
        // },{
        //     title: "H/Work&Del",
        //     field: "hwDel",
        //     type:"numeric"
        // },

        ,
        {
            title: "Comment",
            field:"comment",
            //lookup: marks&&marks.comments?marks.comments.map(comment=>comment.comment):{"NO AVAILABLE COMMENTS":"NO AVAILABLE COMMENTS"}
        }
        ,
        {
            title: "Marked?",
            field: "status",
            editable:"never",
            width:5

        },
        {
            title: "Actions",
            field: "actions",
            editable:"never",
            width:5

        }

    ];

    String.prototype.capitalizeWords = function() {
        try{
            return this.split(" ").map(function(ele){ return ele[0].toUpperCase() + ele.slice(1).toLowerCase();}).join(" ");
        }catch (e){
            return "";
        }

    };
    function handleSubject (e){

      const {  value } = e.target
      let subcls =   tic.subjects.filter(h=>h.subjectKey===value)[0].classes;

      setCurrentSubject((currentSubject) => ({...currentSubject, subjectKey:value,classes:subcls,classKey:""}));
      dispatch(teacherActions.resetMarks())
    }
    function handleClass (e){
      const { value } = e.target
      setCurrentSubject((currentSubject) => ({...currentSubject, classKey:value}))
        dispatch(teacherActions.resetMarks())


    }
    function handleCsv (e){
        e.preventDefault()

        setCurrentSubject((currentSubject) => ({...currentSubject, csv:e.target.files[0]}))
        SubmitCsv(e.target.files[0])
    }


    function SubmitClass() {
       if(currentSubject.subjectKey&&currentSubject.classKey){
         dispatch(teacherActions.getSubjectMarks(currentSubject.subjectKey,currentSubject.classKey))
       }else{
        dispatch(alertActions.error("Please Select Both Subject and Class"));
        setTimeout(() => {
          dispatch(alertActions.clear())
      }, 5000)
       }
    }
    function DownloadTemplate() {
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        if(currentSubject.subjectKey&&currentSubject.classKey){
                            dispatch(teacherActions.downloadMarkTemplate(currentSubject.subjectKey,currentSubject.classKey))
                        }else{
                            dispatch(alertActions.error("Please Select Both Subject and Class"));
                            setTimeout(() => {
                                dispatch(alertActions.clear())
                            }, 5000)
                        }

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });


    }

    function submitMark(e) {
        let mark = JSON.parse(e.target.getAttribute("data-mark"));
        let  score = document.getElementById(mark.id+'SCORE').value
        let  comment = document.getElementById(mark.id+'COMM').value
        let newMark ={...mark,score,comment,clazz:currentSubject.classKey,subjectKey:currentSubject.subjectKey}

        if(currentSubject.subjectKey&&currentSubject.classKey){
                if((score>0&&score<101)&&comment!==""){
                    dispatch(alertActions.success("Working ,please wait"));
                    dispatch(teacherActions.submitMark(newMark,currentSubject.subjectKey,currentSubject.classKey))

                }else{
                    dispatch(alertActions.error("Score should be within 0 and hundred, Comment should not be empty"));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                }
        }else{
            dispatch(alertActions.error("No Subject selected"));
            setTimeout(() => {
                dispatch(alertActions.clear())
            }, 5000)
        }

    }

    function SubmitCsv(csv) {
        if(currentSubject.subjectKey&&currentSubject.classKey){
                if (csv&&currentSubject.subjectKey&&currentSubject.classKey){
                    let fileData = new FormData();
                    fileData.append('file',csv);
                    dispatch(alertActions.success("Working,Please wait"));
                    dispatch(teacherActions.submitMarks(fileData,currentSubject.subjectKey,currentSubject.classKey))
                }else{
                    dispatch(alertActions.error("Error uploading file ,please try again"));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
        }else{
            dispatch(alertActions.error("Please Select Both Subject and Class"));
            setTimeout(() => {
                dispatch(alertActions.clear())
            }, 5000)
        }
    }




    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>

                      <div className={"row clearfix  align-items-end  "}>

                          <div className={"col-6 text-bold  "}>
                              <strong><h2>Subject Marks</h2></strong>
                          </div>


                      </div>
                  </div>
                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Subject Marks</li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}

                  <div className={" card shadow"}>
                      <div className={" card-header d-flex bg-light  "}>
                          <div className="row">
                                {tic? tic.subjects.map( ({subjectKey, subjectName, classes})=>{
                                    return(
                                        <>
                                            <div className="col m-0 p-0">
                                                <input  type="radio" id ={subjectKey} name="subject" value={subjectKey} onChange={handleSubject}/>
                                                {(currentSubject&&currentSubject.subjectKey===subjectKey)?(
                                                    <label className="labelPicker active m-0 " htmlFor={subjectKey}>{subjectName}</label>):(
                                                    <label className="labelPicker m-0 " htmlFor={subjectKey}>{subjectName}</label>)
                                                }
                                            </div>
                                        </>
                                    )
                                }):""}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row bg-white m-0 p-0 "> { currentSubject.classes?currentSubject.classes.map( sub=>{
                                          return(
                                              <>

                                                  <div className="col ">
                                                      <input type="radio" id ={sub} name="class" value={sub} onClick={handleClass}/>
                                                      {(currentSubject&&currentSubject.classKey===sub)?(
                                                          <label className="labelPicker active"  htmlFor={sub}>{sub}</label>):(
                                                          <label className="labelPicker" htmlFor={sub}>{sub}</label>)
                                                      }

                                                  </div>

                                              </>
                                          )
                                      })
                                      : ""}
                                </div>
                            </div>
                            <div className="card-footer bg-dark m-0">


                                <input type="file"

                                       className="custom-file-input "
                                       id="file"
                                       name="file"
                                       onChange={handleCsv}
                                       hidden/>



                                <div className={"btn btn-dark border "} onClick={SubmitClass}> Retrieve Marks </div>
                                <div className={"btn btn-dark border  ml-1"} onClick={DownloadTemplate}> Download Template </div>
                                <label className={"btn btn-dark border m-1"} htmlFor="file">Submit Bulk Marks</label>



                            </div>
                  </div>



                    { message ? (
                        <div className={message.type + " my-1"}>
                            {" "}
                            <div className="popup"> {message.message}</div>
                        </div>) : ("")
                    }

                    <div className="row mt-2 p-3">
                        <div className="col border p-0">
                          {marks&&marks.marks? <MaterialTable
                              title= {marks.SubjectName+" "+marks.classCode}
                              icons={tableIcons}
                              data={marks.marks.map(mark=>{

                                  return({...mark,

                                      score:<>
                                          <input type="number" name={mark.id+"SCORE"} id={mark.id+"SCORE"}    defaultValue={mark.score}/>
                                      </>,
                                      comment:

                                    (<>
                                              <input list={mark.id+"COMMS"}  name={mark.id+"COMM"} className="col mb-2" id={mark.id+"COMM"}  defaultValue={mark.comment}/>

                                                 <datalist id={mark.id+"COMMS"}>
                                                  <option value="">--COMMENTS--</option>
                                                  {marks.comments.map(comment=> {

                                                      return( mark.Sex=="F"?(
                                                          <option value={comment.Fcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())} selected={mark.comment===comment.Fcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())}>{
                                                              comment.Fcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())
                                                          }</option>):(
                                                          <option value={comment.Mcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())} selected={mark.comment===comment.Mcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())}>{
                                                              comment.Mcomment.replace("<student_Name>",mark.Name.split(" ")[1].capitalizeWords())
                                                          }</option>))}
                                                  )
                                                  })}</datalist>

                                        {/*<ReactTextareaAutocomplete*/}
                                        {/*    className="my-textarea"*/}
                                        {/*    loadingComponent={Loading}*/}
                                        {/*    style={{*/}
                                        {/*        fontSize: "15px",*/}
                                        {/*        lineHeight: "20px",*/}
                                        {/*        padding: 5*/}
                                        {/*    }}*/}
                                        {/*    containerStyle={{*/}
                                        {/*        marginTop: 20,*/}
                                        {/*        margin: "20px auto"*/}
                                        {/*    }}*/}
                                        {/*    minChar={0}*/}
                                        {/*    trigger={{*/}
                                        {/*    " ": {*/}


                                        {/*            dataProvider: token => {*/}

                                        {/*                return [...marks.comments.map(comment=> {*/}

                                        {/*                    return (mark.Sex == "F" ? ({*/}

                                        {/*                            name: "",*/}
                                        {/*                            char: comment.Fcomment.replace("<student_Name>", mark.Name.split(" ")[1].capitalizeWords())*/}
                                        {/*                        }) : (*/}
                                        {/*                            {*/}
                                        {/*                                name: "",*/}
                                        {/*                                char: comment.Mcomment.replace("<student_Name>", mark.Name.split(" ")[1].capitalizeWords())*/}
                                        {/*                            }*/}
                                        {/*                        )*/}
                                        {/*                    )*/}
                                        {/*                })]*/}
                                        {/*            },*/}
                                        {/*            component: Item,*/}
                                        {/*            output: (item, trigger) => item.char*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*/>*/}

                                        </>),

                                      status:mark.status?(<div className="col"> <i className="fas fa-check-circle text-success fa-2x"/></div>):(<div className="col-6">
                                          <i className="fas fa-times-circle text-danger fa-2x"/></div>),
                                      actions:!mark.status?<><button className={"btn btn-small btn-primary"} data-mark={JSON.stringify(mark)} onClick={submitMark}>submit</button></>:
                                          <><button className={"btn btn-small btn-warning"} data-mark={JSON.stringify(mark)} onClick={submitMark}>alter</button></>
                              })} )}

                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                              editable={{


                                  // onRowUpdate: (newData, oldData) =>
                                  //
                                  //     new Promise((resolve, reject) => {
                                  //         setTimeout(() => {
                                  //             console.log(newData)
                                  //             if((newData.score<1||newData.score>100)) {
                                  //                 dispatch(alertActions.error("Score should be between 1 and 100"))
                                  //                 setTimeout(() => {
                                  //                     dispatch(alertActions.clear())
                                  //                 }, 5000)
                                  //             }
                                              // }else if(newData.hwDel<1||newData.hwDel>5){
                                              //     dispatch(alertActions.error("Diligence and Homework should be between 1 and 5"))
                                              //     setTimeout(()=>{dispatch(alertActions.clear())},5000)
                                              //
                                              // }else if(newData.behAtti<0||newData.behAtti>5){
                                              //     dispatch(alertActions.error("Behaviour and attitude should be between 1 and 5"))
                                              //     setTimeout(()=>{dispatch(alertActions.clear())},5000)
                                              //
                                              // }else if( newData.behAtti===undefined||newData.hwDel===undefined||newData.score===undefined||
                                              //           newData.behAtti===null||newData.hwDel===null||newData.score===null||
                                              //           isNaN(newData.behAtti)||isNaN(newData.hwDel)||isNaN(newData.score)){
                                              //
                                              //     dispatch(alertActions.error("One or more empty entries detected please try again"))
                                              //     setTimeout(()=>{dispatch(alertActions.clear())},5000)}
                                      //         else{
                                      //             if(newData.status==="not marked"){
                                      //                 newData.status = false;
                                      //             }else{
                                      //                 newData.status = true;
                                      //             }
                                      //             submitMark(newData)
                                      //
                                      //         }
                                      //         resolve();
                                      //     }, 1000)
                                      //
                                      //
                                      // })
                                   }}
                          />:<MaterialTable
                              title= "SELECT A SUBJECT AND A CLASS"
                              icons={tableIcons}
                              data={[]}
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                          />
                          }
                      </div>



                  </div>
                      <Footer/>

                  </div>



            </>);

}

export default TICHome;
