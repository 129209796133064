import{popupConstants} from "../constants/popup.constants";

export const popupActions = {
    userinfo,
    clear,
    settings,
    upload,
    newAsssmnt,
    newReportProfiles
};

function newAsssmnt() {
    return { type:popupConstants.MARK_PROFILE_POPUP };
}
function newReportProfiles(){
    return{type:popupConstants.REPORT_PROFILES_POPUP};
}
function userinfo(info) {
    return { type:popupConstants.INFOPOPUP, info };
}
function settings(info) {
    return { type:popupConstants.SETTINGS_POPUP, info };
}
function passwordRest(info) {
    return { type:popupConstants.PASSWORD_RESET_POPUP, info };
}
function clear() {
    return { type: popupConstants.CLEARPOPUP };
}
function upload() {
    return { type:popupConstants.UPLOAD_POPUP };
}
