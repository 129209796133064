import {
    apiConstants
} from "../constants/api.constants";
import { store } from '../shared/store';
import {
    authHeader
} from "../shared/auth-header";
import {history} from "../shared/history";
import {userConstants} from "../constants/user.constants";
import {teacherActions} from "../actions/teacher.actions";

export const teacherService = {
    deleteSport,
    editSport,
    createSport,
    getSportInformation,
    deleteClub,
    editClub,
    createClub,
    getClubInformation,
    deleteDepartment,
    editDepartment,
    createDepartment,
    getDepartmentInformation,
    AddClassSubject,
    RemoveClassSubject,
    RemoveSubject,
    SubmitNewSubject,
    editNewSubject,
    getSingleSubjectInformation,
    getSubjectInformation,
    getMarkProfilesForStudent,
    SubmitNewAssessment,
    getUpload,
    getMarkProfiles,
    getUploadsForStudent,
    getUploads,
    DeleteUpload,
    getAssessmentMarks,
    DeleteAssessmentMarks,
    toggleReports,
    testProgress,
    newReportsProfile,
    genReports,
    validateId,
    validateTeacherId,
    getTeacher,
    getSubjectMarks,
    getClubMarks,
    getSportMarks,
    getStudentAccounts,
    submitMarks,
    submitUpload,
    uploadStudents,
    uploadStudentsShift,
    uploadStudentActivities,
    submitAssessmentMark,
    submitMark,
    submitSportMark,
    submitClubMark,
    getOptions,
    getClassOptions,
    getStudentArrears,
    getClassArrears,
    saveStudent,
    saveTeacher,
    editTeacher,
    updateStudent,
    getTeachers,
    getClassStudents,
    getUnregisteredStudents,
    getClassRegistration,
    getClassRegistrationbyTic,
    getRegisteredStudents,
    RegisterStudents,
    CreateArrear,
    ClearStudent,
    getDepartmentStats,
    clearDepartment,
    resetDepartmentSubmission,
    resetDepartment,
    resetSubject,
    resetSubjectClass,
    getStudentContacts,
    CreateContact,
    ClearContact,
    getStudentMedicals,
    CreateMedicalCondition,
    getStudentComments,
    submitClassTeacherComments,
    getCalendar,
    RemoveCalendarEvent,
    UpdateCalendar,
    getNotifications,
    UpdateNotifications,
    RemoveNotification,
    getProfile,
    getMiniProfile,
    getTeacherMiniProfile,
    retrievePdfResults,
    downloadClearances,
    getECTemplate,
    getAttendanceRegister,
    getStudentTemplate,
    getPDFClassLists,
    getPDFCalendar,
    removeStudentSubject,
    removeStudentSport,
    removeStudentClub,
    AddSubject,
    AddSport,
    AddClub,
    downloadMarkTemplate,
    getReportStatistics,
    getStudentResults,
    getStudentStatements,
    getStudentProfile,
    getMarkProfilesForStudentTwo,
    getStudentShiftTemplate,
    RemoveStudent,
    RemoveTeacher,
    uploadStudentsAccounts,
    getStudentAccountsTemplate,
    submitStudentAccount,
    MarkStudentRegister,
    uploadMonthlyStatements,
    getAccountStatementsProfiles,
    deleteAccountStatements,
    retrievePdfStatements,
    getBroadsheet,
    getSubjectArrears,
    uploadStudentsArrears,
    getStudents,
    searchStudents,
    AlterStudentArrear,
    getAttendanceStats,
    compileAttendanceStats,
    downloadRegisterStats
}

function testProgress() {

    const sse = new EventSource(`${apiConstants.API_BASE_URL}/api/process`,{ withCredentials: false });
    function getRealtimeData(data) {
        console.log(data)
        store.dispatch(teacherActions.SSE(JSON.parse(data)))

    }
    sse.onmessage = e => getRealtimeData(e.data);
    sse.onerror = (e) => {
        // error log here
        console.log(e)
        sse.close();
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "text/event-stream",
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/process`, requestOptions)
        .then(handleProgressResponse)
        .then(options => {
            return options;
        });
}

function AddSport(studentId,sport) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentSport/${studentId}/${sport}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getAssessmentMarks(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/RetrieveAssessmentMarks`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function DeleteAssessmentMarks(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteMarkProfile/${id}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function RemoveClassSubject(payload) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader()
                },
                body: JSON.stringify(payload)

            };

            return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeClassSubject`, requestOptions)
                .then(handleResponse)
                .then(options => {
                    return options;
                });
        }

function getDepartmentInformation() {
    const requestOptions = {
        method: 'GET',
        headers: {

            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getDepartmentInformation`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function createDepartment(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/createDepartment`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function editDepartment(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/editDepartment`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function deleteDepartment(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteDepartment`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getSportInformation() {
    const requestOptions = {
        method: 'GET',
        headers: {

            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getSportInformation`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function createSport(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/createSport`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function editSport(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/editSport`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function deleteSport(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteSport`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClubInformation() {
    const requestOptions = {
        method: 'GET',
        headers: {

            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getClubInformation`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function createClub(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/createClub`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function editClub(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/editClub`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function deleteClub(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteClub`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function AddClassSubject(payload) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                ...authHeader()
            },
            body: JSON.stringify(payload)

        };

        return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addClassSubject`, requestOptions)
            .then(handleResponse)
            .then(options => {
                return options;
            });
    }

function RemoveSubject(subjectID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteSubject/${subjectID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function DeleteUpload(fileID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteUpload/${fileID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getStudentProfile(ID){
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/bio/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentStatements(ID,TIME) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getStudentStatements/${ID}/${TIME}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getBroadsheet(TIME) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getBroadsheet/${TIME}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentResults(ID,TIME) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/StudentResults/${ID}/${TIME}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getAccountStatementsProfiles() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getAccountStatementsProfiles`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getReportStatistics() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/ReportStatistics`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function AddClub(studentId,club) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentClub/${studentId}/${club}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function AddSubject(studentId,subject) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentSubject/${studentId}/${subject}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function removeStudentSport(studentId,sport) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentSport/${studentId}/${sport}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function RemoveTeacher(teacherId) {

    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/ete/del/${teacherId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });

}

function RemoveStudent(studentId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ete/del/${studentId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function deleteAccountStatements(Id) {

    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/deleteAccountStatements/${Id}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });

}

function removeStudentClub(studentId,club) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentClub/${studentId}/${club}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function removeStudentSubject(studentId,subject) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentSubject/${studentId}/${subject}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}



function getPDFCalendar(getPDFCalendar) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },


    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Calendar`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getPDFClassLists(list) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(list)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/ClassLists`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getStudentShiftTemplate() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/getStudentShiftTemplate`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getStudentTemplate() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/downloadStudentTemplate`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentAccountsTemplate() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/downloadStudentAccountsTemplate`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getECTemplate() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/downloadStudentECTemplate`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function downloadRegisterStats(user) {

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(user)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/downloadRegisterStats`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function downloadClearances() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/StudentPDFClearances`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getTeacherMiniProfile(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/MiniProfile/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getMiniProfile(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/MiniProfile/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function retrievePdfStatements(ID,TIME) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/retrievePdfStatements/${ID}/${TIME}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function retrievePdfResults(ID,TIME) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/StudentPDFResults/${ID}/${TIME}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getProfile() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Profile`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function RemoveNotification(notificationId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/DeleteNotification/${notificationId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function UpdateNotifications(notificationbody) {
    const requestOptions = {
        method: 'POST',
        headers: {

            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(notificationbody)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SaveNotification`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getAttendanceStats(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {

            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/getAttendanceStats`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function compileAttendanceStats(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {

            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/compileAttendanceStats`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getAttendanceRegister(payload) {
    const requestOptions = {
        method: 'POST',
        headers: {

            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/getAttendanceRegister`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}




function getMarkProfiles(classKey,subjectKey) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/RetrieveMarkProfiles/${subjectKey}/${classKey}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getMarkProfilesForStudentTwo(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/RetrieveMarkProfilesForStudent/${id}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getMarkProfilesForStudent(subjectKey,classKey) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/RetrieveMarkProfiles/${subjectKey}/${classKey}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getSingleSubjectInformation(subject) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getSingleSubjectInformation/${subject}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getSubjectInformation() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getSubjectInformation`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getUploadsForStudent(classKey,subjectKey) {
        const requestOptions = {
            method: 'GET',
            headers: {
                ...authHeader()
            },

        };

        return fetch(`${apiConstants.API_BASE_URL}/api/Student/getUploads/${classKey}/${subjectKey}`, requestOptions)
            .then(handleResponse)
            .then(options => {
                return options;
            });
    }
function getUploads(classKey,subjectKey) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/getUploads/${classKey}/${subjectKey}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getNotifications() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/notifications`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function UpdateCalendar(eventbody) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(eventbody)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SaveCalender`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function RemoveCalendarEvent(eventId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/DeleteCalender/${eventId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getCalendar() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/calender`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function CreateMedicalCondition(condition) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(condition)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/MedicalsSave`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function CreateContact(contact) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(contact)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ContactSave`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function ClearContact(contact) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ContactsClear/${contact}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}



function getStudentMedicals() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Medicals`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentContacts() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Contacts`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function resetSubject(subId) {

    const sse = new EventSource(`${apiConstants.API_BASE_URL}/api/Teacher/resetSubject/${subId}`,{ withCredentials: false });
    function getRealtimeData(data) {
        console.log(data)
        store.dispatch(teacherActions.SSE(JSON.parse(data)))

    }
    sse.onmessage = e => getRealtimeData(e.data);
    sse.onerror = (e) => {
        // error log here
        console.log(e)
        sse.close();
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "text/event-stream",
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/resetSubject/${subId}`, requestOptions)
        .then(handleProgressResponse)
        .then(options => {
            return options;
        });
}
function resetSubjectClass(subId,clazz) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/resetSubjectClass/${subId}/${clazz}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function toggleReports() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/ToggleResultAccess`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function resetDepartment(dptId) {
    const sse = new EventSource(`${apiConstants.API_BASE_URL}/api/Teacher/resetDepartment/${dptId}`,{ withCredentials: false });
    function getRealtimeData(data) {
        console.log(data)
        store.dispatch(teacherActions.SSE(JSON.parse(data)))

    }
    sse.onmessage = e => getRealtimeData(e.data);
    sse.onerror = (e) => {
        // error log here
        console.log(e)
        sse.close();
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "text/event-stream",
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/resetDepartment/${dptId}`, requestOptions)
        .then(handleProgressResponse)
        .then(options => {
            return options;
        });
}


function resetDepartmentSubmission(dptId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/resetDepartmentSubmission/${dptId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function clearDepartment(dptId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/ClearDepartment/${dptId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function genReports(payload) {

    const sse = new EventSource(`${apiConstants.API_BASE_URL}/api/genReports/${payload}`,{ withCredentials: false });

    function getRealtimeData(data) {
        console.log(data)
        store.dispatch(teacherActions.SSE(JSON.parse(data)))
    }

    sse.onmessage = e => getRealtimeData(e.data);
    sse.onerror = (e) => {
        // error log here
        console.log(e)
        sse.close();
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            //"Content-Type": "application/json",
            "Content-Type": "text/event-stream",
            ...authHeader()
        },
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/genReports/${payload}`, requestOptions)
        .then(handleProgressResponse)
        .then(options => {
            return options;
        });

}
function newReportsProfile(payload) {

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(payload)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/newReportsProfile`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

const uploadToServer = (url) => {

    const requestOptions = {
        method: 'GET',
        headers: {

            ...authHeader()
        },
    };
    fetch(url, requestOptions);

}

function ClearStudent(clrId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ToggleStudentArrears/${clrId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function validateTeacherId(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/validateTeacher/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function validateId(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/validateStudent/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentComments(user) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentComments/${user}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentAccounts() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getStudentAccounts`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getSportMarks(user) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/SportMarks/${user}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClubMarks(user) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClubMarks/${user}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getDepartmentStats(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/DepartmentStat/${tic}`, requestOptions)
    //return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/DepartmentStat/all`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentArrears(stud) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getStudentArrears/${stud}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getClassArrears(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getClassArrears/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getSubjectArrears(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getSubjectArrears/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getRegisteredStudents(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/RegisteredStudents/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassRegistrationbyTic() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        }
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClassRegistrationByTic`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassRegistration(cls) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        }
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClassRegistration/${cls}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getUnregisteredStudents(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/UnRegisteredStudents/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getUpload(fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/downloadUpload/${fileId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getClassStudents(classId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getClass/${classId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getTeachers() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teachers/get`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function searchStudents(searchStudents) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/searchStudents/${searchStudents}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudents() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/get`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getOptions() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentAddOptions`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function AlterStudentArrear(arrear) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(arrear)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/alterStudentArrears`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
    function CreateArrear(arrear) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                ...authHeader()
            },
            body: JSON.stringify(arrear)
        };
        return fetch(`${apiConstants.API_BASE_URL}/api/Student/saveStudentArrears`, requestOptions)
            .then(handleResponse)
            .then(options => {
                return options;
            });
    }
function RegisterStudents(students) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(students)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Register`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassOptions(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentAddOptions/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getTeacher(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/`+tic, requestOptions)
        .then(handleResponse)
        .then(tic => {
            return tic;
        });
}

function downloadMarkTemplate(Subject ,SubjectClass){
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SubjectMarkTemplates/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(marks => {
            return marks;
        });
}
function getSubjectMarks(Subject ,SubjectClass){
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SubjectMarks/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(marks => {
            return marks;
        }); 
}


function submitClassTeacherComments(comment){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(comment)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadStudentComments`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function editNewSubject(newSubject){

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(newSubject)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/editSubject`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });

}

function SubmitNewSubject(newSubject){

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(newSubject)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/newSubject`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });

}

function SubmitNewAssessment(newAssmnt){

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(newAssmnt)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/newMarkProfile`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });

}
function submitUpload(file,description,subjectKey,classKey){

    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadDoc/`+classKey+"/"+subjectKey+"/"+description, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });

}

function submitMarks(file,Subject,SubjectClass){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadBatchMarks/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadStudentActivities(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/admin/uploadStudentActivities`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadStudentsShift(file){

    // const sse = new EventSource(`${apiConstants.API_BASE_URL}/api/Teacher/registerBatchStudents`,{ withCredentials: false });
    //
    // function getRealtimeData(data) {
    //     console.log(data)
    //     store.dispatch(teacherActions.SSE(JSON.parse(data)))
    //
    // }
    // sse.onmessage = e => getRealtimeData(e.data);
    // sse.onerror = (e) => {
    //     // error log here
    //     console.log(e)
    //     sse.close();
    // }
    const requestOptions = {
        method: 'POST',
        headers: {
           // "Content-Type": "text/event-stream",
            ...authHeader()
        },
        body:file
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/registerBatchStudents`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadMonthlyStatements(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadMonthlyStatements`, requestOptions)
        //return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadTeacherEmails`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadStudents(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadBatchStudents`, requestOptions)
    //return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadTeacherEmails`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadStudentsArrears(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadStudentsArrears`, requestOptions)

        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function uploadStudentsAccounts(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadStudentAccounts`, requestOptions)
       
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function updateStudent(student,Id){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(student)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/update/${Id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function editTeacher(teacher){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(teacher)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/editTeacher`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function saveTeacher(teacher){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(teacher)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/save`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function saveStudent(student){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(student)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/save`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitAssessmentMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/submitAssMark`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function MarkStudentRegister(payload){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...payload})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/MarkStudentRegister`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function submitStudentAccount(account){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...account})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/updateStudentAccount`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadMarks`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitSportMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadSportMark`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitClubMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadClubMark`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function handleProgressResponse(response) {
    console.log(response)
    return response.text().then(text => {
        console.log(text)
        const data = text ;

        if (!response.ok){
            if (response.status === 401) {
                logout();
            }
            return Promise.reject(data);
        }

        return data;
    })
}


function handleResponse(response) {
    
    return response.text().then(text => {
   
        const data = text && JSON.parse(text);

         if (!response.ok){
           // const error = (data && data.Message) || response.statusBody;
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();

             }
            return Promise.reject(data);
        }
        return data;
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    history.push('/');
    window.location.reload(true);
    return {
        type: userConstants.USER_LOGOUT
    };
}

//
// let uploadPercentage =0;
// let url = `${apiConstants.API_BASE_URL}/api/genReports`;
//
//
// const eventSource = new EventSource(url);
// let guidValue = null;
//
// eventSource.addEventListener("GUI_ID", (event) => {
//
//     guidValue = JSON.parse(event.data);
//     console.log(`Guid from server: ${guidValue}`);
//
//
//     eventSource.addEventListener(guidValue, (event) => {
//         console.log(event)
//         const result = JSON.parse(event.data);
//         if (uploadPercentage !== result) {
//             uploadPercentage=result;
//         }
//         if (result === "100") {
//             eventSource.close();
//         }
//     });
//     //uploadToServer(url);
// });
//
// eventSource.onerror = (event) => {
//     if (event.target.readyState === EventSource.CLOSED) {
//         console.log("SSE closed (" + event.target.readyState + ")");
//     }
//
//     eventSource.close();
// };
//
// eventSource.onopen = () => {
//     console.log("connection opened");
// };

