
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch,useSelector } from "react-redux";
import {alertActions} from "../actions";
import {teacherActions} from "../actions/teacher.actions";



function MarkProfilePopup(props) {

  const dispatch = useDispatch();
  const popup = useSelector((state) => state.popupReducer.MarkProfilePopup);
  const message = useSelector((state) => state.alert);

  const [currentFile,setCurrentFile] = useState({
    upload:null
  });

  const [uploadInfo,setUploadInfo] = useState({
    description:"",
    total:"",
    coursePercentage:100
  })
  function handleUpload (e){
    e.persist();
    e.preventDefault()
    if(e.target&&e.target.files&&e.target.files[0]&&(e.target.files[0]!=undefined||e.target.files[0]!=null)){
      if (true){
        setCurrentFile((currentFile) => ({upload:e.target.files[0]}))
      }else{
        setCurrentFile((currentFile) => ({upload:null}))
        dispatch(alertActions.error("Invalid File"));
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 5000)
      }}
  }


  function SubmitNewAssessment() {

        if (uploadInfo.description !== "") {
            const windowLoc = window.location.href.replace("%7C","|").split("/");

            let subKey = windowLoc[windowLoc.length - 1].split("|")[0];
            let classKey = windowLoc[windowLoc.length - 1].split("|")[1];

            dispatch(alertActions.success("Working, Please wait"));

            let payload = { subjectName:subKey ,classKey:classKey,description:uploadInfo.description,total:uploadInfo.total ,coursePercentage:uploadInfo.coursePercentage}

            dispatch(teacherActions.SubmitNewAssessment( payload))

          } else {

          dispatch(alertActions.error("Please enter valid description of upload"));

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, 5000)

        }

  }

  function handleChange(e){
    let { name,value } = e.target
    setUploadInfo((uploadInfo) => ({...uploadInfo, [name]:value}))
  }

  return (
      popup ? (
            <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center ">
          {popup ? (
            <>
              <div className="row align-items-center">
                <div className="col ">
                  <strong className="mt-4 text-bold"> Create new assessment marks</strong>
                </div>
              </div>
            </>
          ) : (
            "Event"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          { message ? (
              <div className={message.type + " my-1"}>
                {" "}
                <div className="popup"> {message.message}</div>
              </div>) : ("")
          }
          {popup ? (
            <>
                <div className="row">

                  <form>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6">
                          <label className={" m-1"} htmlFor="description">Description</label>
                        </div>
                        <div className="col-6">
                          <input type="text"

                                 className="text"
                                 id="description"
                                 name="description"
                                 onChange={handleChange}
                                 />
                        </div>
                      </div>

                      {/*<div className="row">*/}
                      {/*  <div className="col-6">*/}
                      {/*    <label className={"m-1"} htmlFor="total">Course Percentage</label>*/}
                      {/*  </div>*/}
                      {/*  <div className="col-6">*/}
                      {/*    <input type="number"*/}
                      {/*           className="text"*/}
                      {/*           id="coursePercentage"*/}
                      {/*           name="coursePercentage"*/}
                      {/*           onChange={handleChange}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  */}
                      {/*</div>*/}
                      <div className="row">
                        <div className="col-6">
                          <label className={"m-1"} htmlFor="total">Total Marks</label>
                        </div>
                        <div className="col-6">
                          <input type="number"
                                 className="text"
                                 id="total"
                                 name="total"
                                 onChange={handleChange}
                          />
                        </div>


                      </div>

                    </div>






                    <label className={"btn btn-success border m-1"} onClick={SubmitNewAssessment}>Submit </label>



                  </form>

                </div>



            </>
          ) : (
            <p>Nothing to show :(</p>
          )}
        </div>
      </Modal.Body>

    </Modal></>):""
  );
}
export default MarkProfilePopup;
