import {
  popupConstants
} from "../constants/popup.constants";

export function popupReducer(state = {}, action) {
  switch (action.type) {
    case popupConstants.MARK_PROFILE_POPUP:
      return {
        MarkProfilePopup: true,
        uploadPopup:false,
        settingsPopup: false,
        passwordResetPopup:false
      };
    case popupConstants.INFOPOPUP:
      return {
        MarkProfilePopup:false,
        infoPopup: true,
        settingsPopup: false,
        passwordResetPopup:false,
        info: action.info
      };
    case popupConstants.SETTINGS_POPUP:
      return {
          MarkProfilePopup:false,
          InfoPopup: false,
          settingsPopup: true,
        passwordResetPopup:false,
          settings: action.info
      };
    case popupConstants.REPORT_PROFILES_POPUP:
      return {
        reportProfilesPopup:true,
        MarkProfilePopup:false,
        InfoPopup: false,
        settingsPopup: false,
        passwordResetPopup:false,
        settings: action.info
      };
    case popupConstants.UPLOAD_POPUP:
      return {
        ...state,
        uploadPopup:true
      };
    case popupConstants.PASSWORD_RESET_POPUP:
      return {
        reportProfilesPopup: false,
        MarkProfilePopup:false,
        InfoPopup: false,
        settingsPopup: false,
        uploadPopup:false,
        passwordResetPopup:true
      };

    case popupConstants.CLEARPOPUP:
      return {
        reportProfilesPopup: false,
        MarkProfilePopup:false,
        InfoPopup: false,
        settingsPopup: false,
        uploadPopup:false,
        passwordResetPopup:false
      };
    default:
      return state
  }
}