
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Link} from "react-router-dom";
import Footer from "../Components /Footer";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {confirmAlert} from "react-confirm-alert";




function  ManageTeachers() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const dispatch = useDispatch();
    const tic = useSelector((state) => state.teacherReducer.TIC);
    const options = useSelector((state) => state.teacherReducer.options);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)
    const message = useSelector((state) => state.alert);
    const teachers = useSelector((state) => state.teacherReducer.teachers);
    const netError = useSelector((state) => state.teacherReducer.netError);

    React.useEffect(() => {
        if(!teachers&&!netError){
            dispatch(teacherActions.getTeachers())
        }
        if(!options && !netError) {
            dispatch(teacherActions.getOptions())
        }
        dispatch(pagesActions.updatepage(pagesConstants.TEACHERS));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })


    },[])

    const columns = [

        {
            title: "ID",
            field:"teacherId" ,
        },
        {
            title:"Name" ,
            field: "teacherName",
            // hidden:true
        },
        {
            title:"Email" ,
            field: "email",
            // hidden:true
        },
        {
            title: "Class",
            field:"classInCharge" ,
        },

        {
            title:"Sport" ,
            field:"Sport" ,
        },
        {
            title:"Club" ,
            field:"Club" ,
        }
        ,
        {
            title: "Actions",
            field: "actions"
        }
    ];
    const [currentCsv,setCurrentCsv] = useState({
        csv:""
    });

    const [clazz,setClazz] = useState({
        selected:"ALL"
    });
    function handleChange(e){
        const { name,value } = e.target
        setClazz((clazz) => ({...clazz, [name]:value}))
    }

    function handleCsv (e){
        e.preventDefault()
        setCurrentCsv((currentCsv) => ({ csv:e.target.files[0]}))
        SubmitCsv()
    }
    function SubmitCsv() {
        let fileData = new FormData();
        fileData.append('file', currentCsv.csv);
        dispatch(teacherActions.uploadStudents(fileData))
    }




    function handlePdf(e) {
        let selected = clazz.selected;
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getPDFClassLists([selected]))
    }

    function deleteTeacher(e) {
        let teacherId = e.target.id;
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {


                        dispatch(alertActions.success(" Working Please wait..."))
                        dispatch(teacherActions.RemoveTeacher(teacherId))
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });


    }

    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2> Manage Teachers</h2>
                        </div>

                        <br/><br/>
                    </div>
                </div>

                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }
                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Teachers</li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}



            <div className="row justify-content-between ">


                <div className="col-5" > </div>

                <div className="col-2 ">
                    <div className="container">
                        <div className="row">
                            <div className="col m-1 p-0">
                                <Link to="/home/AddTeacher">
                                <div className="btn btn-success col"><small>ADD NEW TEACHER</small></div>
                                </Link>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
                <div className="row mt-2 p-3">
                    <div className="col border p-0">
                        {teachers? <MaterialTable
                            title= {"Teachers"}
                            icons={tableIcons}
                            data={teachers.map(teacher=>{return{
                                ...teacher,
                                actions:(<>
                                    <Link to={"/home/EditTeacher/"+teacher.teacherId} >
                                        <button className={"btn btn-small btn-success mx-1"} id={teacher.teacherId} > <i id={teacher.teacherId} className=" mx-2 fas fa-edit fa-1x"/></button>
                                    </Link>

                                    <button className={"btn btn-small btn-danger"} id={teacher.teacherId} onClick={deleteTeacher}><i id={teacher.teacherId} className=" mx-2 fas fa-trash fa-1x"/></button>

                                </>),
                            }})}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}


                            detailPanel={[
                                {render: rowData => {

                                        return (
                                            <div className={'container'}
                                                 style={{

                                                     textAlign: 'center',

                                                     borderTop:'3px solid #ffc107'
                                                 }}
                                            >
                                                <h5 className="row ml-5 pl-5 py-2 pt-4">Registered Subjects</h5>

                                                    {rowData.subjects.map(sub=>{return(<>
                                                        <div className="row ml-3 py-2 p-1 mb-1 border rounded">

                                                            <div className="col-4">
                                                               <h6 className={"text-left"}>{sub.subjectName}</h6>
                                                        </div>
                                                            <div className="col-4">

                                                                   {sub.classes.map(cl => {
                                                                    return (  <div className="badge badge-success p-3 m-2 ">{cl}</div>)
                                                                })}

                                                            </div>
                                                        </div>
                                                    </>)})}

                                            </div>
                                        )
                                    }
                                }]}


                        />:<MaterialTable
                            title= "Teachers"
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />
                        }
                    </div>



                </div>
                <Footer/>

            </div>



        </>);

}

export default ManageTeachers;
