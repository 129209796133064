import {adminConstants} from "../constants/admin.constants";
import {adminService} from "../services/admin.service";
import {alertActions} from './index';


export const adminActions = {
    getUsers,
    getUser,
    toggleAccount,
    newUser,

   
};



function getUsers(branch) {
    return dispatch => {
        dispatch(request());

        adminService.getUsers(branch)
            .then(
                users => {
                    dispatch(success(users));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type: adminConstants.PORTAL_USER_RETRIEVE_REQUEST}
    }

    function success(users) {
        return {type: adminConstants.PORTAL_USER_RETRIEVE_SUCCESS,users}
    }

    function failure(error) {
        return {type: adminConstants.PORTAL_USER_RETRIEVE_FAILURE, error}
    }
}

function getUser(userId) {
    return dispatch => {
        dispatch(request());

        adminService.getUser(userId)
            .then(
                user=> {
                    dispatch(success(user));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type: adminConstants.MODAL_USER_RETRIEVE_REQUEST}
    }

    function success(user) {
        return {type: adminConstants.MODAL_USER_RETRIEVE_SUCCESS,user}
    }

    function failure(error) {
        return {type: adminConstants.MODAL_USER_RETRIEVE_FAILURE, error}
    }
}

function toggleAccount(userId,enabled) {

               
    return dispatch => {
        dispatch(request(userId))
        console.log("action"+ userId + enabled)
        adminService.toggleAccount(userId,enabled)

                   .then(  
                       user=>{
                        dispatch(success(user));
                        user.enabled?
                        
                        dispatch(alertActions.success(user.username +"'s account has been enabled")):
                        dispatch(alertActions.error(user.username +"'s account has been disabled"))


                        dispatch(adminActions.getUsers(JSON.parse(localStorage.getItem("user")).branchId))
                        setTimeout(() => {
                            getUsers();
                            dispatch(alertActions.clear());
                            dispatch(clear());

                        }, 5000)
               
                },
                       error => {

                           if(error.Message){
                               dispatch(failure(error.Messsage));
                               dispatch(alertActions.error(error.Message));
                           }else{
                               dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                               dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                           }

                           setTimeout(() => {
                               dispatch(alertActions.clear())
                           }, 5000)
                       }
            );
    };

    function request(user) {
        return {type: adminConstants.PORTAL_USER_RESET_PIN_REQUEST,user}
    }

    function success(user) {
        return {type: adminConstants.PORTAL_USER_RESET_PIN_SUCCESS,user}
    }

    function failure(error) {
        return {type: adminConstants.PORTAL_USER_RESET_PIN_FAILURE, error}
    }
    function clear() {
        return {type: adminConstants.PORTAL_USER_RESET_PIN_CLEAR}
    }

}


function newUser(user) {
    return dispatch => {

        dispatch(request());

        adminService.newUser(user)
            .then(
                user => {
                    
                    dispatch(success(user));
                    dispatch(alertActions.success("Successfully added User !"));     
                    
                    setTimeout(() => {
                    dispatch(alertActions.clear())
                }, 5000)
                },
                error => {
                    dispatch(failure("user/mail already exists"));
                    dispatch(alertActions.error("user/mail already exists"));

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
           
            );
    };

    function request() {
        return {type: adminConstants.PORTAL_USER_UPDATE_REQUEST}
    }

    function success(user) {
        return {type: adminConstants.PORTAL_USER_UPDATE_SUCCESS,user}
    }

    function failure(error) {
        return {type: adminConstants.PORTAL_USER_UPDATE_FAILURE, error}
    }
}

