
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";
import {history} from "../shared/history";
import profilepic from "../assets/pro.jpg";
import {popupActions} from "../actions/popup.actions";
import InfoPopup from "../Components /InfoPopup";
import UploadPopup from "../Components /UploadPopup";
import MarkProfilePopup from "../Components /MarkProfilePopup";




function  ELearning() {


    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

  const dispatch = useDispatch();
  const Subject = useSelector( (state)=> state.teacherReducer.subject);
  const message = useSelector((state) => state.alert);
  const uploads = useSelector((state) => state.teacherReducer.uploads);
  const netError = useSelector((state) => state.teacherReducer.netError);
  const user = useSelector((state)=>state.authenticationReducer.user.userId)
  const uploadPopup = useSelector((state) => state.popupReducer.uploadPopup);
  const markProfilePopup = useSelector((state) => state.popupReducer.MarkProfilePopup);

  const markProfiles = useSelector((state) => state.teacherReducer.MarkProfiles);
  const windowLoc = window.location.href.replace("%7C","|").replace("%20"," ").split("/");


  let CurrentSubject ="";

    React.useEffect(() => {
        CurrentSubject  = windowLoc[windowLoc.length-1];
        try {
            if(CurrentSubject=="ELearning"){
                throw "error";
            }
            dispatch(teacherActions.changeSubjectView(CurrentSubject))

        }catch (e){
            history.push('/home/');
            window.location.reload(true);
        }

        if(!uploads && !netError){
            dispatch(teacherActions.getUploads(windowLoc[windowLoc.length-1].split("|")[0],windowLoc[windowLoc.length-1].split("|")[1]))
        }

        if(!markProfiles && !netError){

            dispatch(teacherActions.getMarkProfiles(windowLoc[windowLoc.length-1].split("|")[0],windowLoc[windowLoc.length-1].split("|")[1]))
        }


        dispatch(pagesActions.updatepage(pagesConstants.HOME));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])

    const profilecolumns = [
        {
            title: "ID",
            field: "id",
            hidden:true
        },
        {
            title:"Date" ,
            field: "date"
        },

        {
            title: "Description",
            field:"description"
        },
        {
            title:"Actions" ,
            field:"actions"
         }
    ];
    const columns = [
        {
            title: "ID",
            field: "ID",
            hidden:true
        },
        {
            title:"Date" ,
            field: "timeOfUpload"
        },
        {
            title:"File" ,
            field: "uploadName"
        },
        {
            title: "Description",
            field:"description"
        },
        {
            title:"Actions" ,
            field:"actions"
        }
    ];
    function handleUploadClick() {
        dispatch(popupActions.upload());
    }

    function DeleteFile(e) {
        let fileID = e.target.getAttribute("id");
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        dispatch(alertActions.success("Working, Please wait"));
                        dispatch(teacherActions.DeleteUpload(windowLoc[windowLoc.length-1].split("|")[0],windowLoc[windowLoc.length-1].split("|")[1],fileID));

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });

    }

    function DownloadFile(e) {
        let fileID = e.target.getAttribute("id");
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        dispatch(alertActions.success("Working ,please wait"));
                        dispatch(teacherActions.getUpload(fileID))

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });



    }
    function handleNewAssClick() {
        dispatch(popupActions.newAsssmnt());
    }



    function EditMarks(e) {
        let ID = e.target.getAttribute("id");
        history.push('/home/AssessmentMarks/'+ID);
        window.location.reload(true);
    }

    function DeleteMarks(e) {
        let ID = e.target.getAttribute("id");
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        dispatch(alertActions.success("Working, Please wait"));
                        dispatch(teacherActions.DeleteAssessmentMarks(windowLoc[windowLoc.length-1].split("|")[0],windowLoc[windowLoc.length-1].split("|")[1],ID));

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });


    }

    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>
                      <div className={"row clearfix  align-items-end  "}>
                          <div className={"col-6 text-bold  "}>
                              <strong><h2>{(windowLoc[windowLoc.length-1].split("|")[0]+" ").toUpperCase()}</h2></strong>
                              <strong><h3>{(windowLoc[windowLoc.length-1].split("|")[1]).toUpperCase()}</h3></strong>
                          </div>


                      </div>
                  </div>

                    <UploadPopup
                        show={uploadPopup}
                        onHide={() =>  {
                            dispatch(popupActions.clear()
                            )}}
                    />

                    < MarkProfilePopup
                        show={markProfilePopup}
                        onHide={() =>  {
                            dispatch(popupActions.clear()
                            )}}
                    />

                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">{windowLoc[windowLoc.length-1].split("|")[0]+" "}</li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}

                    <div className="row gutters-sm">

                        <div className="col-md-4 mb-3">
                            <div className="card mb-3">
                                <div className="card-body bg-light">
                                    <div className="d-flex flex-column align-items-center text-center">

                                        <div className="col btn btn-outline-secondary  " onClick={handleNewAssClick}>  NEW ASSESSMENT MARKS</div>
                                        <div className="col btn btn-outline-secondary my-3 " onClick={handleUploadClick}>  UPLOAD FILE</div>
                                        {/*<div className="col btn btn-outline-secondary my-3">SUBJECT REGISTRATIONS</div>*/}
                                        {/*<div className="col btn btn-success ">DOWNLOAD STUDENTS LIST</div>*/}

                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className="col-md-8">

                                {uploads? <MaterialTable
                                    title= {"My uploads"}
                                    icons={tableIcons}

                                    data={uploads.map((upload)=>{return{
                                        ...upload,
                                        actions:<><button className={"btn btn-small btn-success mx-1"} id={upload.id} onClick={DownloadFile}> <i id={upload.id} className=" mx-2 fas fa-download fa-1x"/></button>
                                            <button className={"btn btn-small btn-danger"} id={upload.id} onClick={DeleteFile}><i id={upload.id} className=" mx-2 fas fa-trash fa-1x"/></button></>

                                    }
                                    })

                                    }
                                    columns={columns}
                                    options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                            //  backgroundColor:"darkgray",
                                            // color:"white",
                                            paddingTop:0,
                                            paddingBottom:0
                                        },
                                        rowStyle:{

                                            fontSize:"smaller",
                                            paddingTop:0,
                                            paddingBottom:0
                                        },
                                        pageSizeOptions: [5, 10, 20, 100]}}

                                />:<MaterialTable
                                    title= "No uploads "
                                    icons={tableIcons}
                                    data={[]}
                                    columns={columns}
                                    options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                            //  backgroundColor:"darkgray",
                                            // color:"white",
                                            paddingTop:0,
                                            paddingBottom:0
                                        },
                                        rowStyle:{

                                            fontSize:"smaller",
                                            paddingTop:0,
                                            paddingBottom:0
                                        },
                                        pageSizeOptions: [5, 10, 20, 100]}}
                                />
                                }
                            </div>

                    </div>
                    <br/><br/>
                    { message ? (
                        <div className={message.type + " my-1"}>
                            {" "}
                            <div className="popup"> {message.message}</div>
                        </div>) : ("")
                    }
                    <div className="row mt-2 p-3">

                        <div className="col border p-0">
                          {markProfiles&&markProfiles? <MaterialTable
                              title= {"Mark Profiles"}
                              icons={tableIcons}

                              data={markProfiles.map((markProfile)=>{return{
                                      ...markProfile,
                                      actions:<>
                                          <button className={"btn btn-small btn-success mx-1"} id={markProfile.id} onClick={EditMarks}> <i id={markProfile.id} className=" mx-2 fas fa-edit fa-1x"/></button>
                                          <button className={"btn btn-small btn-danger"} id={markProfile.id} onClick={DeleteMarks}><i id={markProfile.id} className=" mx-2 fas fa-trash fa-1x"/></button>
                                      </>
                              }
                              })

                              }
                              columns={profilecolumns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{
                                     
                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}

                          />:<MaterialTable
                              title= "No Mark Profiles "
                              icons={tableIcons}
                              data={[]}
                              columns={profilecolumns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                          />
                          }
                      </div>
                    </div>


                     <Footer/>
                  </div>



            </>);

}

export default ELearning;
