import React from "react";

import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import {pagesConstants} from "../constants/pages.constants";

function Sidebar() {

    let role = JSON.parse(localStorage.getItem("user")).roles;
    let adminMode = role.includes("ROLE_ADMIN")||role.includes("ROLE_SUPER_ADMIN");
    let studentMode =role.includes("ROLE_STUDENT");
    let sportTeacherMode = role.includes("ROLE_SPORTTEACHER");
    let clubTeacherMode = role.includes("ROLE_CLUBTEACHER");
    let hodMode = role.includes("ROLE_HOD");
    let classTeacherMode= role.includes("ROLE_CLASSTEACHER");
    let accMode= role.includes("ROLE_ACCOUNTS");
    let sportsAdminMode= role.includes("ROLE_SPORTSADMIN");
    let reportsMode = role.includes("ROLE_REPORTS");

    // public static final int  = 0;
    // public static final int  = 1;
    // public static final int ROLE_HOD = 2;
    // public static final int ROLE_HEAD = 3;
    // public static final int ROLE_ADMIN = 4;
    // public static final int  = 5;

    const activepage = useSelector((state) => state.pagesReducer.active_page);

    return (
        <React.Fragment>
            <div id={"left-sidebar"} className={"sidebar shadow shadow-sm"}>
                <div className={"sidebar_list"}>
                    <div className={"tab-content"} id={"main-menu"}>
                        <div className={"tab-pane active"} id={"Home-icon"}>
                            <nav className={"sidebar-nav sidebar-scroll"}>
                                <ul className={"metismenu"}>
                                    <br/>
                                    <b/>

                                    <div className="d-flex mt-5">
                                        <i
                                            style={{color: "#ffc107"}}
                                            className=" mx-2 fas fa-square-full fa-1x"
                                        ></i>

                                        <p
                                            className="p-0 m-0 text-muted"
                                            style={{fontSize: "smaller", fontWeight: "bolder"}}
                                        >
                                            ACADEMICS
                                        </p>
                                    </div>
                                    {/*PROFILE*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.PROFILE
                                                ? " bg-muted  pl-4 " + activepage
                                                : "pl-4"
                                        }
                                    >
                                        <Link to="/home">
                                            <li>
                                                {activepage === pagesConstants.PROFILE ? (
                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span>Home</span>
                                            </li>
                                        </Link>
                                    </div>

                                    {/*FULL CALENDAR*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.CALENDAR
                                                ? " bg-muted  pl-4 " + activepage
                                                : "pl-4"
                                        }
                                    >
                                        <Link to="/home/Calendar">
                                            <li>
                                                {activepage === pagesConstants.CALENDAR ? (
                                                    <i className="mx-2 fas fa-calendar-alt"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span>Calendar</span>
                                            </li>
                                        </Link>
                                    </div>

                                    {studentMode?(<>
                                    {/*PROFILE*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.ACCOUNTS
                                                ? " bg-muted  pl-4 " + activepage
                                                : "pl-4"
                                        }
                                    >
                                        <Link to="/home/StudentBursary">
                                            <li>
                                                {activepage === pagesConstants.ACCOUNTS ? (
                                                    <i className=" mx-2 fa fa-money fa-1x"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span> School Fees </span>
                                            </li>
                                        </Link>
                                    </div>
                                    </>):""}
                                    {accMode?(<>
                                        {/*MANAGE STUDENT ACCOUNTS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.ACCOUNTS
                                                    ? " bg-muted  pl-4 " + activepage
                                                    : "pl-4"
                                            }
                                        >
                                            <Link to="/home/StudentAccounts">
                                                <li>
                                                    {activepage === pagesConstants.ACCOUNTS ? (
                                                        <i className=" mx-2 fa fa-home fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Student Accounts</span>
                                                </li>
                                            </Link>
                                        </div>

                                    </>):""}

                                    {accMode?(<>
                                        {/*STUDENT ARREARS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.ARREARS
                                                    ? " bg-muted pl-4  " + activepage
                                                    : "pl-4"
                                            }
                                        >
                                            <Link to="/home/StudentArrears">
                                                <li>
                                                    {activepage === pagesConstants.ARREARS ? (
                                                        <i className=" mx-2 fas fa-coins fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Students Arrears</span>
                                                </li>
                                            </Link>
                                        </div>

                                    </>):""}
                                    {!accMode?(<>
                                        {!studentMode?(<>


                                            {/*SUBJECT MARKS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.SCHEMA
                                                        ? " bg-muted  pl-4 " + activepage
                                                        : "pl-4"
                                                }
                                            >
                                                <Link to="/home/MarkingScheme">
                                                    <li>
                                                        {activepage === pagesConstants.SCHEMA ? (
                                                            <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Marking Schemes</span>
                                                    </li>
                                                </Link>
                                            </div>
                                            {!adminMode?<>
                                            {/*STUDENT ARREARS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.ARREARS
                                                        ? " bg-muted pl-4  " + activepage
                                                        : "pl-4"
                                                }
                                            >
                                                <Link to="/home/StudentArrearsSB">
                                                    <li>
                                                        {activepage === pagesConstants.ARREARS ? (
                                                            <i className=" mx-2 fas fa-coins fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Students Arrears</span>
                                                    </li>
                                                </Link>
                                            </div>
                                        </>:""}
                                            {adminMode||reportsMode?<>
                                                {/*CLASS REGISTER*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.ATTENDANCE_STATS
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/AttendanceStats">
                                                        <li>
                                                            {activepage === pagesConstants.ATTENDANCE_STATS ? (
                                                                <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Attendance Stats</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                            </>:""}
                                            {classTeacherMode?(<>
                                                {/*CLASS REGISTRATION*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.REGISTRATION
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/Registration">
                                                        <li>
                                                            {activepage === pagesConstants.REGISTRATION ? (
                                                                <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Class Registrations</span>
                                                        </li>
                                                    </Link>
                                                </div>

                                            {/*CLASS REGISTER*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.ATTENDANCE_REGISTER
                                                        ? " bg-muted  pl-4 " + activepage
                                                        : "pl-4"
                                                }
                                            >
                                                <Link to="/home/AttendanceRegister">
                                                    <li>
                                                        {activepage === pagesConstants.ATTENDANCE_REGISTER ? (
                                                            <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Attendance Register</span>
                                                    </li>
                                                </Link>
                                            </div>
                                        </>):""}





                                            {/*SUBJECT MARKS*/}

                                            <div
                                                className={
                                                    activepage === pagesConstants.HOME
                                                        ? " bg-muted  pl-4 " + activepage
                                                        : "pl-4"
                                                }
                                            >
                                                <Link to="/home/Marks">
                                                    <li>
                                                        {activepage === pagesConstants.HOME ? (
                                                            <i className=" mx-2 fa fa-book fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>My Subjects</span>
                                                    </li>
                                                </Link>
                                            </div>
                                            {sportTeacherMode||adminMode||sportsAdminMode?(<>
                                                    {/*SPORT MARKS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.SPORTS
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/SportMarks">
                                                            <li>
                                                                {activepage === pagesConstants.SPORTS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Sports</span>
                                                            </li>
                                                        </Link>
                                                    </div>


                                                </>)
                                                :""}


                                            {clubTeacherMode||adminMode||sportsAdminMode?(<>
                                                {/*CLUB MARKS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.CLUBS
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/ClubMarks">
                                                        <li>
                                                            {activepage === pagesConstants.CLUBS ? (
                                                                <i className=" mx-2 fa fa-home fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Clubs</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                            </>):""}

                                            {classTeacherMode||adminMode||sportsAdminMode?(<>
                                                    {/*CLASS TEACHER COMMENTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.STUDENTSCOMMENTS
                                                                ? " bg-muted pl-4  " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/StudentBehaviorAndAttendance">
                                                            <li>
                                                                {activepage === pagesConstants.STUDENTSCOMMENTS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Student Behavior And Attendance </span>
                                                            </li>
                                                        </Link>
                                                    </div>
                                                </>)
                                                :""}

                                            {hodMode||adminMode?(<>
                                                {/*DEPARTMENT STATISTICS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.DEPARTMENTS
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/Departments">
                                                        <li>
                                                            {activepage === pagesConstants.DEPARTMENTS ? (
                                                                <i className=" mx-2 fa fa-home fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Departments</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                            </>):""}
                                            {adminMode?(<>
                                                {/*REGISTER STUDENTS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.REGISTER
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/registerStudents">
                                                        <li>
                                                            {activepage === pagesConstants.REGISTER ? (
                                                                <i className=" mx-2 fa fa-users fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Register Students</span>
                                                        </li>
                                                    </Link>
                                                </div>

                                                {/*REGISTER STUDENTS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.REPORTS_CENTER
                                                            ? " bg-muted   pl-4" + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/reportsCenter-re-99-33-98-mcx0OFk">
                                                        <li>
                                                            {activepage === pagesConstants.REPORTS_CENTER ? (
                                                                <i className=" mx-2 fa fa-users fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Results Center</span>
                                                        </li>
                                                    </Link>
                                                </div>

                                            </>):""}






                                            {classTeacherMode?(<>

                                                    {/*HEADING*/}
                                                    <div className="d-flex mt-3">
                                                        <i
                                                            style={{color: "#ffc107"}}
                                                            className=" mx-2 fas fa-square-full fa-1x"
                                                        ></i>

                                                        <p
                                                            className="p-0 m-0 text-muted"
                                                            style={{fontSize: "smaller", fontWeight: "bolder"}}
                                                        >
                                                            Students
                                                        </p>
                                                    </div>



                                                    {/*STUDENT CONTACTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.CONTACTS
                                                                ? " bg-muted   pl-4" + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/Contacts">
                                                            <li>
                                                                {activepage === pagesConstants.CONTACTS ? (
                                                                    <i className=" mx-2 fa fa-address-book fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Student Contacts</span>
                                                            </li>
                                                        </Link>
                                                    </div>



                                                    {/*STUDENT MEDICAL INFORMATION*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.MEDICALS
                                                                ? " bg-muted   pl-4" + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/Medicals">
                                                            <li>
                                                                {activepage === pagesConstants.MEDICALS ? (
                                                                    <i className=" mx-2 fas fa-clinic-medical fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Student Medical Information</span>
                                                            </li>
                                                        </Link>
                                                    </div>






                                                </>)
                                                :""}

                                            {adminMode||sportsAdminMode?(<>

                                                {/*HEADING*/}
                                                <div className="d-flex mt-3">
                                                    <i
                                                        style={{color: "#ffc107"}}
                                                        className=" mx-2 fas fa-square-full fa-1x"
                                                    ></i>

                                                    <p
                                                        className="p-0 m-0 text-muted"
                                                        style={{fontSize: "smaller", fontWeight: "bolder"}}
                                                    >
                                                        ADMINISTRATOR SETTINGS
                                                    </p>
                                                </div>


                                                {/*MANAGE SPORTS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.MANAGESPORTS
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/ManageSports">
                                                        <li>
                                                            {activepage === pagesConstants.MANAGESPORTS ? (
                                                                <i className=" mx-2 fa fa-home fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Manage Sports</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                                {/*MANAGE CLUBS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.MANAGECLUBS
                                                            ? " bg-muted  pl-4 " + activepage
                                                            : "pl-4"
                                                    }
                                                >
                                                    <Link to="/home/ManageClubs">
                                                        <li>
                                                            {activepage === pagesConstants.MANAGECLUBS ? (
                                                                <i className=" mx-2 fa fa-home fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Manage Clubs</span>
                                                        </li>
                                                    </Link>
                                                </div>


                                            </>):""}



                                            {adminMode ? (

                                                <>




                                                    {/*STUDENT ARREARS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.ARREARS
                                                                ? " bg-muted pl-4  " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/StudentArrears">
                                                            <li>
                                                                {activepage === pagesConstants.ARREARS ? (
                                                                    <i className=" mx-2 fas fa-coins fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Students Arrears</span>
                                                            </li>
                                                        </Link>
                                                    </div>


                                                    {/*MANAGE STUDENT ACCOUNTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.ACCOUNTS
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/StudentAccounts">
                                                            <li>
                                                                {activepage === pagesConstants.ACCOUNTS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Student Accounts</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*PORTAL USERS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.PORTAL
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/admin/PortalUsers">
                                                            <li>
                                                                {activepage === pagesConstants.PORTAL ? (
                                                                    <i className=" mx-2 fa fa-users fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Portal Users</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*MANAGE NOTIFICATIONS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.MANAGENOTIFICATIONS
                                                                ? " bg-muted pl-4  " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageNotifications">
                                                            <li>
                                                                {activepage === pagesConstants.MANAGENOTIFICATIONS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Notifications</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*MANAGE CALENDAR*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.MANAGECALENDAR
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageCalendar">
                                                            <li>
                                                                {activepage === pagesConstants.MANAGECALENDAR ? (
                                                                    <i className="mx-2 fas fa-calendar-alt"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Calendar</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*MANAGE TEACHERS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.TEACHERS
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageTeachers">
                                                            <li>
                                                                {activepage === pagesConstants.TEACHERS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Teachers</span>
                                                            </li>
                                                        </Link>
                                                    </div>


                                                    {/*MANAGE STUDENTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.SUPPORT
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageStudents">
                                                            <li>
                                                                {activepage === pagesConstants.SUPPORT ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Students</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*MANAGE SUBJECTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.SUBJECTS
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageSubjects">
                                                            <li>
                                                                {activepage === pagesConstants.SUBJECTS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Subjects</span>
                                                            </li>
                                                        </Link>
                                                    </div>

                                                    {/*MANAGE SUBJECTS*/}
                                                    <div
                                                        className={
                                                            activepage === pagesConstants.MANAGEDEPARTMENTS
                                                                ? " bg-muted  pl-4 " + activepage
                                                                : "pl-4"
                                                        }
                                                    >
                                                        <Link to="/home/ManageDepartments">
                                                            <li>
                                                                {activepage === pagesConstants.MANAGEDEPARTMENTS ? (
                                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <span>Manage Departments</span>
                                                            </li>
                                                        </Link>
                                                    </div>




                                                    {/*ADD PORTAL USER
                                            <div
                                                className={
                                                    activepage === pagesConstants.ADDPORTALUSER
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/admin/addUser">
                                                    <li>
                                                        {activepage === pagesConstants.ADDPORTALUSER ? (
                                                            <i className=" mx-2 fa fa-user fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Add Portal User</span>
                                                    </li>
                                                </Link>
                                            </div>*/}
                                                </>
                                            ) : (
                                                ""
                                            )}

                                        </>):""}
                                    </>):""}



                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Sidebar;
