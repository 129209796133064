
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch,useSelector } from "react-redux";
import {alertActions} from "../actions";
import {teacherActions} from "../actions/teacher.actions";
import {confirmAlert} from "react-confirm-alert";
import {popupActions} from "../actions/popup.actions";



function ReportProfilePopup(props) {

  const dispatch = useDispatch();
  const popup = useSelector((state) => state.popupReducer.reportProfilesPopup);
  const message = useSelector((state) => state.alert);


  const [profileInfo,setProfileInfo] = useState({
    description:"",
    year:"",
    term:"",

  })


  function SubmitNewProfile() {



        if (profileInfo.description !== ""&&profileInfo.description !== ""&&profileInfo.description !== "") {


                  dispatch(alertActions.success("Working Please Wait...This might take a while"))
                  dispatch(teacherActions.newReportsProfile(profileInfo));
                  dispatch(popupActions.clear())

                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, 10000)

        } else {


          dispatch(alertActions.error("Please enter valid description and time period for reports"));

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, 5000)

        }

  }

  function handleChange(e){
    let { name,value } = e.target
    setProfileInfo((profile) => ({...profile, [name]:value}))
  }

  return (
      popup ? (
            <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center ">
          {popup ? (
            <>
              <div className="row align-items-center">
                <div className="col ">
                  <strong className="mt-4 text-bold"> Generate new reports</strong>
                </div>
              </div>
            </>
          ) : (
            "Event"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          { message ? (
              <div className={message.type + " my-1"}>
                {" "}
                <div className="popup"> {message.message}</div>
              </div>) : ("")
          }
          {popup ? (
            <>
                <div className="row">

                  <form>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6">
                          <label className={" m-1"} htmlFor="description">Description</label>
                        </div>
                        <div className="col-6">
                          <input type="text"

                                 className="text"
                                 id="description"
                                 name="description"
                                 onChange={handleChange}
                                 />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <label className={"m-1"} htmlFor="total">YEAR</label>
                        </div>
                        <div className="col-6">
                          <input type="number"
                                 className="text"
                                 id="year"
                                 name="year"
                                 onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <label className={"m-1"} htmlFor="total">TERM</label>
                        </div>
                        <div className="col-6">
                          <input type="number"
                                 className="text"
                                 id="term"
                                 name="term"
                                 onChange={handleChange}
                          />
                        </div>
                      </div>

                    </div>






                    <label className={"btn btn-success border m-1"} onClick={SubmitNewProfile}>Submit </label>



                  </form>

                </div>



            </>
          ) : (
            <p>Nothing to show :(</p>
          )}
        </div>
      </Modal.Body>

    </Modal></>):""
  );
}
export default ReportProfilePopup;
