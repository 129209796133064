import React, { useState } from "react";
import { pagesConstants } from "../constants/pages.constants";
import { pagesActions } from "../actions/pages.actions";
import { useDispatch, useSelector } from "react-redux";

import { adminActions } from "../actions/admin.actions";
import Footer from "../Components /Footer";
import {alertActions} from "../actions";
import {history} from "../shared/history";

function AddPortalUser() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(pagesActions.updatepage(pagesConstants.ADDPORTALUSER));
  });
  let message = useSelector((state) => state.alert);

  let [user, setUser] = useState({
    username: "",
    email: "",
    enabled: "",

    Role:"",
  });

  const [updateButton, setUpdateButton] = useState({
    status: true,
  });

  function handleChange(e) {
    e.preventDefault();
    setUpdateButton((UpdateButton) => ({ status: false }));

    const { name, value } = e.target;

    let x = value
      .replace(/(^\s*)|(\s*$)/gi, "")
      .replace(/[ ]{2,}/gi, " ")
      .replace(/\n +/, "\n");

    setUser((user) => ({ ...user, [name]: x }));
  }

  function handleUpdate(e) {
    let error =false;
    e.preventDefault();
    if(user.Role==0||user.Role==1){

      if(user.Role==0){
        history.push('/home/addStudent/');
        window.location.reload(true);
      }else{
        history.push('/home/addTeacher/');
        window.location.reload(true);
      }
    }else{

      let newPassword=document.getElementsByName('newPassword')[0].value;
      let repeatPassword=document.getElementsByName('repeatPassword')[0].value;


      if(newPassword!=repeatPassword){
        error =true;
        dispatch(alertActions.error("passwords do not match"))
        setTimeout(()=>{
          dispatch(alertActions.clear())
        },5000)
        return 0 ;

      }
      if(newPassword.length<8){
         error =true;
        dispatch(alertActions.error("passwords should be at least 8 characters"))
        setTimeout(()=>{
          dispatch(alertActions.clear())
        },5000)
        return 0 ;

      }
      if (user.username.trim().length > 2) {
        if(user.Role>1){
          if(!error){
          dispatch(adminActions.newUser({...user,password:newPassword}));
          }
        }else {
          dispatch(alertActions.error("Please enter a valid role"));
          setTimeout(()=>{
            dispatch(alertActions.clear())
          },5000);
        }

      } else {
        dispatch(alertActions.error("username should have 3 or more characters"));
        setTimeout(()=>{
          dispatch(alertActions.clear())
        },5000);
      }
    }

  }

  return (
    <React.Fragment>
      <form id="pUser" onSubmit={handleUpdate}>
        <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
          <div className="block-header">
            <div className="row clearfix">

            </div>
          </div>

          <div className="container-fluid">
            <div className="row clearfix justify-content-center ">
              <div className="col-xl-9 col-lg-8 col-md-7">
                <h5>
                  <strong>Add Portal User</strong>
                </h5>
                <div className="card">
                  <div className="header container ">
                    <br />
                    <h2>Basic Information</h2>
                  </div>
                  <div className="body">
                    <br />

                    <div className=" ">
                      <div className="row">
                        <div className="col">
                          {" "}
                          {message ? (
                            <div className={message.type + " my-1"}>
                              {" "}
                              <div className="popup"> {message.message}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row  justify-content-center">
                        <div className="col-4">
                          <strong className="text-bold">Role</strong>
                        </div>
                        <select
                            className="form-group col-5 pl-3 mb-2 form-control"
                            name="Role"
                            onChange={handleChange}
                            required
                        >
                          <option value="">-- Select Role --</option>
                          <option value="0">Student</option>
                          <option value="1">Teacher</option>
                          <option value="4">Administrator</option>
                          <option value="6">Super Administrator</option>
                          <option value="9">Accounts</option>
                          <option value="10">Sports Admin</option>

                        </select>
                      </div>





                      {!(user.Role==0||user.Role==1)?(<>
                        <div className="row justify-content-center ">
                          <div className="col-4 ">
                            <strong className="text-bold">UserName</strong>
                          </div>
                          <input
                              placeholder="Username"
                              className="col-5 pl-3 mb-2 "
                              type="text"
                              name="username"
                              onChange={handleChange}
                              required
                              minLength={3}
                              trim
                          />
                        </div>
                        <div className="row  justify-content-center">
                          <div className="col-4">
                            <strong className="text-bold">Email</strong>
                          </div>
                          <input
                              placeholder="Email"
                              className="col-5 pl-3 mb-2"
                              type="email"
                              name="email"
                              onChange={handleChange}
                              required
                          />
                        </div>

                        <br/> <br/>
                        <div className="row justify-content-center ">
                          <div className="col-4 ">
                            <strong className="text-bold">New Password</strong>
                          </div>
                          <input
                              placeholder="New Password"
                              className="col-5 pl-3 mb-2 "
                              type="password"
                              name="newPassword"
                              onChange={handleChange}
                              defaultValue={""}
                              required
                              minLength={3}
                              trim
                          />
                        </div>

                        <div className="row justify-content-center ">
                          <div className="col-4 ">
                            <strong className="text-bold">Repeat Password</strong>
                          </div>
                          <input
                              placeholder="New Password"
                              className="col-5 pl-3 mb-2 "
                              type="password"
                              name="repeatPassword"
                              onChange={handleChange}
                              defaultValue={""}
                              required
                              minLength={3}
                              trim
                          />
                        </div>
                      </>):""}




                      <div className="row  justify-content-center">
                        <div className="col-4"></div>
                        <div className="col-5 p-0  mb-2 d-flex justify-content-end ">
                          {" "}
                          <button
                            type="submit"
                            className={
                              updateButton.status
                                ? "btn btn-success disabled btn-sm "
                                : " btn-sm btn btn-success"
                            }
                            disabled={updateButton.status}
                          >
                            {(user.Role==0||user.Role==1)?("Continue"):"AddUser"}

                          </button>


                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>
            </div>{" "}
            <br />
            <br />
            <br />
          </div>
          <Footer/>
        </div>
      </form>
    </React.Fragment>
  );
}
export default AddPortalUser;
