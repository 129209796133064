
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";




function  StudentBehaviorAndAttendance() {


    const tableIcons = {

        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert);
  const marks = useSelector((state) => state.teacherReducer.StudentComments);
  const netError = useSelector((state) => state.teacherReducer.netError);
  const user = useSelector((state)=>state.authenticationReducer.user.userId)


    React.useEffect(() => {
        if(!marks && !netError){
            dispatch(teacherActions.getStudentComments(user))
        }
        dispatch(pagesActions.updatepage(pagesConstants.STUDENTSCOMMENTS));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])


    const columns = [

        {
            title: "Student Id",
            field: "id",
        },
        {
            title:"Full Name" ,
            field: "name",
            // hidden:true
        },
        {
            title:"Responsibilities" ,
            field: "responsibilities",
            // hidden:true
        },
        {
            title: "Discipline",
            field:"discipline" ,
        },
        {
            title:"Attendance" ,
            field:"attendance" ,
        }
        ,
        {
            title: "Marked?",
            field: "status",
        },
        {
            title: "Actions",
            field: "actions",
        }

    ];




    function submitMark(e) {
        let mark = JSON.parse(e.target.getAttribute("data-mark"));

        let responsibilities = document.getElementById(mark.id + 'RESP').value
        let discipline = document.getElementById(mark.id + 'DISC').value
        let attendance = document.getElementById(mark.id + 'ATT').value
        let newMark = {...mark, discipline, responsibilities,attendance}

        if (discipline !== "" && responsibilities !== ""&& attendance!== "") {
            dispatch(alertActions.success("Working ,please wait"));
            dispatch(teacherActions.submitClassTeacherComments(newMark,user))
        } else {
            dispatch(alertActions.error("Please Select Both Effort and Achievement for " + mark.fname));
            setTimeout(() => {
                dispatch(alertActions.clear())
            }, 5000)
        }
    }

        return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>
                      <div className={"row clearfix  align-items-end  "}>
                          <div className={"col-6 text-bold  "}>
                              <strong><h2>Student Behavior And Attendance</h2></strong>
                          </div>


                      </div>
                  </div>
                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Class Teacher Comments</li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}



                    <div className="row mt-4 p-3">
                        <div className="col border p-0">
                          {marks? <MaterialTable
                              title= {"Class Teacher Comments"}
                              icons={tableIcons}

                              data={marks.map(mark=>{
                                  return(
                                      {
                                          ...mark,



                                          responsibilities:<>
                                      <select name={mark.id+"RESP"} className="col mb-2" id={mark.id+"RESP"} >
                                          <option value="">--Responsibilities--</option>
                                          <option value="A" selected={mark.responsibilities==="A"}>A - Very Responsible and dedicated to given duties.</option>
                                          <option value="B" selected={mark.responsibilities==="B"}>B - Performs duties without any supervision.</option>
                                          <option value="C" selected={mark.responsibilities==="C"}>C - Needs monitoring.</option>


                                      </select>
                                  </>,
                                          attendance:<>
                                      <select name={mark.id+"ATT"} className="col mb-2" id={mark.id+"ATT"} >
                                          <option value="">--ATTENDANCE--</option>
                                          <option value="A" selected={mark.attendance==="A"}>A - Good attendance and very puntual.</option>
                                          <option value="B" selected={mark.attendance==="B"}>B - Satisfactory attendance record.</option>
                                          <option value="C" selected={mark.attendance==="C"}>C - Poor attendance record.</option>

                                      </select>
                                  </>,discipline:<>

                                              <select name={mark.id+"DISC"} className="col mb-2" id={mark.id+"DISC"} >
                                                  <option value="">--DISCIPLINE--</option>
                                                  <option value="A" selected={mark.discipline==="A"}>A - Very Responsible and dedicated to given duties.</option>
                                                  <option value="B" selected={mark.discipline==="B"}>B - Performs duties without any supervision.</option>
                                                  <option value="C" selected={mark.discipline==="C"}>C - Needs monitoring.</option>

                                              </select>
                                               </>,
                                          status:mark.status?(<div className="col"> <i className="fas fa-check-circle text-success fa-2x"/></div>):(<div className="col-6">
                                              <i className="fas fa-times-circle text-danger fa-2x"/></div>),
                                  actions:!mark.status?<><button className={"btn btn-small btn-primary"} data-mark={JSON.stringify(mark)} onClick={submitMark}>submit</button></>:
                                              <><button className={"btn btn-small btn-warning"} data-mark={JSON.stringify(mark)} onClick={submitMark}>alter</button></>}
                                  )
                              })
                              }
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{
                                     
                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}

                          />:<MaterialTable
                              title= "No Mark Schedule "
                              icons={tableIcons}
                              data={[]}
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                          />
                          }
                      </div>



                  </div>
                     <Footer/>

                  </div>



            </>);

}

export default StudentBehaviorAndAttendance;
