
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Link} from "react-router-dom";
import Footer from "../Components /Footer";
import ProgressBar from "react-bootstrap/ProgressBar";
import {confirmAlert} from "react-confirm-alert";




function  ManageStudents() {

    const tableIcons = {

        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)

    };

    const dispatch = useDispatch();
    const LoadingStatus = useSelector((state) => state.teacherReducer.LoadingStatus);
    const sse = useSelector((state) => state.teacherReducer.sse);
    const ic = useSelector((state) => state.teacherReducer.TIC);
    const options = useSelector((state) => state.teacherReducer.options);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)
    const message = useSelector((state) => state.alert);
    const students = useSelector((state) => state.teacherReducer.students);
    const netError = useSelector((state) => state.teacherReducer.netError);

    React.useEffect(() => {

        if(!students&&!netError){
            dispatch(teacherActions.getStudents())
        }
        if(!options && !netError) {
            dispatch(teacherActions.getOptions())
        }
        dispatch(pagesActions.updatepage(pagesConstants.SUPPORT));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })


    },[])

    const columns = [
        {
            title: "ID",
            field:"id" ,
        },
        {
            title:"Surname" ,
            field: "lname",
            // hidden:true
        },
        {
            title: "Name",
            field:"fname" ,
        },

        {
            title:"Class" ,
            field:"clazz" ,
        },
        {
            title:"D.O.B" ,
            field:"dob" ,
        },{
            title: "Gender",
            field: "sex",
        },
        {
            title: "Nat.ID",
            field: "idNumber",
        },
        {
            title: "Religion",
            field: "religion",
        },
        {
          title: "Actions",
          field: "actions"
        }
    ];

    const [currentCsv,setCurrentCsv] = useState({
        csv:""
    });

    const [currentCsvShift,setCurrentCsvShift] = useState({
        csv:""
    });

    const [clazz,setClazz] = useState({
        selected:"ALL"
    });

    function handleSportsTemplate(e) {

        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getECTemplate())
    }
    function handleSportsCsv (e){
        e.preventDefault()
        setCurrentCsv((currentCsv) => ({ csv:e.target.files[0]}))
        SubmitSportsCsv(e.target.files[0])
    }
    function SubmitSportsCsv(csv) {
        dispatch(alertActions.success("Working ,please wait"));
        let fileData = new FormData();
        fileData.append('file', csv);
        dispatch(teacherActions.uploadStudentActivities(fileData))
    }

    function handleStudentsCsv (e){
        e.preventDefault()
        setCurrentCsv((currentCsv) => ({ csv:e.target.files[0]}))
        SubmitStudentsCsv(e.target.files[0])
    }
    function SubmitStudentsCsv(file) {
        let fileData = new FormData();
        fileData.append('file',file);
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.uploadStudents(fileData))
    }



    function handleCsvShift (e){
        e.preventDefault()
        setCurrentCsvShift((currentCsv) => ({ csv:e.target.files[0]}))
        SubmitCsvShift(e.target.files[0])
    }
    function SubmitCsvShift(file) {
        let fileData = new FormData();
        fileData.append('file', file);
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.uploadStudentsShift(fileData))
    }


    function handleChange(e){
        const { name,value } = e.target
        setClazz((clazz) => ({...clazz, [name]:value}))
    }
    function handlePdf(e) {
        let selected = clazz.selected;
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getPDFClassLists([selected]))
    }



    function handleStudentsShiftTemplate(e) {

        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getStudentShiftTemplate())
    }


    function handleStudentPdf() {

        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getStudentTemplate())
    }

    function deleteStudent(e){

        let studentId = e.target.id;
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {


                        dispatch(alertActions.success(" Working Please wait..."))
                        dispatch(teacherActions.RemoveStudent(studentId))
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });


    }

    function editSingleStudent() {

    }



    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2> Manage Students</h2>
                        </div>

                        <br/><br/>
                    </div>
                </div>

                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }

                {/*ProgressBar*/}
                {LoadingStatus&&sse?
                    (<>
                        <div className="container-fluid">
                            <ProgressBar className={"row"} animated now={LoadingStatus.progress} label={`${LoadingStatus.progress}%`} max={100}/>
                            <div className="row text-muted justify-content-center mb-2"><small><strong>{LoadingStatus.message}</strong></small></div>
                        </div>
                    </>)
                    :""}
                {/*ProgressBar*/}

                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Students</li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}


                <div className="row justify-content-between align-content-center">


                <div className="col card m-2" >
                    <div className="card-body">

                        <div className="row"><div className="col text-dark"><strong>SHIFT STUDENT CLASSES</strong></div></div>
                        <div className="row"><div className="col text-dark">Download the Guide and shift classes accordingly by altering classes. Upload the file as is to effect changes. NB do not remove students without updates</div></div>

                        <div className="row pl-3 mt-2 card-footer">
                            <div className="col btn btn-dark mr-2" onClick={handleStudentsShiftTemplate}><small>Get Template</small></div>
                            <div className="col p-0">
                                <input type="file"

                                       className="custom-file-input "
                                       id="regfile"
                                       name="regfile"
                                       onChange={handleCsvShift}
                                       hidden/>

                                <label className={"btn btn-primary border  col m-0"} htmlFor="regfile"><small>UPLOAD</small></label>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="col card m-2" >
                    <div className="card-body">

                        <div className="row"><div className="col text-dark"><strong>STUDENTS</strong></div></div>
                        <div className="row"><div className="col text-dark">Download the Guide and fill in the respective student details and upload to effect changes.The respective formats for entering are found in the guide<br/></div></div>

                        <div className="row pl-3 mt-2 card-footer">
                            <div className="col btn btn-dark mr-2" onClick={handleStudentPdf}><small>Get Template</small></div>
                            <div className="col  p-0">
                                <input type="studentsFile"
                                       className="custom-file-input "
                                       id="studentsFile"
                                       name="studentsFile"
                                       onChange={handleStudentsCsv}
                                       hidden/>
                                <label className={"btn btn-primary border  col m-0"} htmlFor="studentsFile"><small>UPLOAD</small></label>
                            </div>
                        </div>

                    </div>



                </div>

                <div className="col card m-2" >
                    <div className="card-body">

                        <div className="row"><div className="col text-dark"><strong>SPORTS AND CLUBS</strong></div></div>
                        <div className="row"><div className="col text-dark">Download the Guide and fill in the respective sport and club codes.A student appearing twice indicates multiple sporting and club activities</div></div>

                        <div className="row pl-3 mt-2 card-footer">
                            <div className="col btn btn-dark mr-2" onClick={handleSportsTemplate}><small>Get Template</small></div>
                            <div className="col  p-0">
                                <input type="file"
                                       className="custom-file-input "
                                       id="sportsFile"
                                       name="sportsFile"
                                       onChange={handleSportsCsv}
                                       hidden/>
                                <label className={"btn btn-primary border  col m-0"} htmlFor="sportsFile"><small>UPLOAD</small></label>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

                        <div className="row m-1 mt-2 p-3 row bg-light rounded border">

                            {options&&options.subjects?(<>
                                <div className="col " >


                                <div className="row ">
                                        <div className="col-3">
                                            Download classlists here
                                            <select className="col p-0 m-0" name="selected" onChange={handleChange}>

                                                <option value={"ALL"}>ALL CLASSES</option>
                                                {options.subjects.map(subject=>{
                                                    return(
                                                        <>
                                                            <option value={subject.clazz.clName}>{subject.clazz.clName}</option>
                                                        </>
                                                    )})}

                                            </select >
                                        </div>
                                        <div className="col-3 btn btn-success mx-3" onClick={handlePdf}><small>Download PDF</small></div>
                                </div>
                            </div>
                            </>):""}

                            <div className="col-2 ">
                                <Link to="/home/AddStudent">
                                    <div className="btn btn-primary col"><small>Add New Student</small></div>
                                </Link>
                            </div>

                        </div>






                <div className="row mt-2 p-3">
                    <div className="col border p-0">
                        {students? <MaterialTable
                            title= {"Students"}
                            icons={tableIcons}
                            data={students.map(student=>{return{
                                ...student,
                                actions:(<>
                                    <Link to={"/home/EditStudent/"+student.id} >
                                        <button className={"btn btn-small btn-success mx-1"} id={student.id} onClick={editSingleStudent}> <i id={student.id} className=" mx-2 fas fa-edit fa-1x"/></button>
                                    </Link>

                                    <button className={"btn btn-small btn-danger"} id={student.id} onClick={deleteStudent}><i id={student.id} className=" mx-2 fas fa-trash fa-1x"/></button>

                                </>),
                            }})}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}


                        />:<MaterialTable
                            title= "Students"
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />
                        }
                    </div>



                </div>
                <Footer/>

            </div>



        </>);

}

export default ManageStudents;
