
import "../css/dash.css";

import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";
import {ContextMenu, MenuItem, ContextMenuTrigger, SubMenu} from "react-contextmenu";

import {alertActions} from '../actions';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Link} from "react-router-dom";
import { Collapse, Badge } from 'reactstrap';
import Footer from "../Components /Footer";


function  RegisterStudents() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const [isSubjectOpen, setIsSubjectOpen] = useState(false);
    const toggleSubject = () => setIsSubjectOpen(!isSubjectOpen);
    const [isClubOpen, setIsClubOpen] = useState(false);
    const toggleClub = () => setIsClubOpen(!isClubOpen);
    const [isSportOpen, setIsSportOpen] = useState(false);
    const toggleSport = () => setIsSportOpen(!isSportOpen);

    const dispatch = useDispatch();
    const tic = useSelector((state) => state.teacherReducer.TIC);
    const options = useSelector((state) => state.teacherReducer.options);
    const uploadedStudents = useSelector((state) => state.teacherReducer.fetchedStudents);
    const netError = useSelector((state) => state.teacherReducer.netError);
    const user = useSelector((state)=>state.authenticationReducer.user)
    const studentOptions= useSelector((state)=>state.teacherReducer.options)
    const message = useSelector((state) => state.alert);
    const students = useSelector((state) => state.teacherReducer.UnregisteredStudents);

    React.useEffect(() => {

        if(!options && !netError) {
            dispatch(teacherActions.getOptions())
        }

        dispatch(pagesActions.updatepage(pagesConstants.REGISTER));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])

    const columns = [
        {
            title: "Student Id",
            field:"id" ,
        },
        {
            title:"Last Name" ,
            field: "lname",
            // hidden:true
        },
        {
            title: "First Name",
            field:"fname" ,
        },
        {
            title:"Date Of Birth" ,
            field:"dob" ,
        },{
            title: "Gender",
            field: "sex",
        }
    ];




    const [currentClass,setCurrentClass] = useState({
        cls:""
    });

    const [currentCsv,setCurrentCsv] = useState({
        csv:""
    });

    const [attributeGroups,setAttributeGroups] = useState({
        SubjectGroup:[],
        ClubGroup:[],
        SportGroup:[]
    });

    const [SelectedStudents,setSelectedStudents] = useState({
        students:[],
    });

    function submitClassRegistrationRequest(){

            if(currentClass.cls===""){
                dispatch(alertActions.error("Invalid Class option ,Please try again."))
                setTimeout(()=>{dispatch(alertActions.clear())},3000)
            }else{
                dispatch(alertActions.success(" Working Please wait..."))
                dispatch(teacherActions.getClassRegistration(currentClass.cls))
                setTimeout(()=>{dispatch(alertActions.clear())},3000)
            }

    }

    function handleClassChange(e){
        let { name,value } = e.target
        setCurrentClass((CurrentClass) => ({...CurrentClass, [name]:value}))

    }

    function alterStudents (e){

        let attrType = e.target.getAttribute("id")
        let alteredStudents="";
        try{
            switch (attrType){
                case "Add_Subject_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Subjects!==undefined){
                            subs = student.Subjects;
                        }
                        if(attributeGroups.SubjectGroup!==undefined){
                            subGroup = attributeGroups.SubjectGroup;
                        }
                        let newSubs =new Set([...subs,...subGroup])
                        return({...student,Subjects:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Add_Subject_Selected":
                    let selected = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{
                        if (selected.has(student.id)){
                            let subs =[];
                            let subGroup =[];
                            if(student.Subjects!==undefined){
                                subs = student.Subjects;
                            }
                            if(attributeGroups.SubjectGroup!==undefined){
                                subGroup = attributeGroups.SubjectGroup;
                            }
                            let newSubs =new Set([...subs,...subGroup])
                            return({...student,Subjects:[...newSubs]})

                        }else{
                            return student;
                        }
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Subject_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Subjects!==undefined){
                            subs = student.Subjects;
                        }
                        if(attributeGroups.SubjectGroup!==undefined){
                            subGroup = attributeGroups.SubjectGroup;
                        }
                        let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                        return({...student,Subjects:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Subject_Selected":
                    let selectedstuds = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{

                        if (selectedstuds.has(student.id)){

                            let subs =[];
                            let subGroup =[];
                            if(student.Subjects!==undefined){
                                subs = student.Subjects;
                            }
                            if(attributeGroups.SubjectGroup!==undefined){
                                subGroup = attributeGroups.SubjectGroup;
                            }
                            let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                            return({...student,Subjects:[...newSubs]})

                        }else{
                            return student;
                        }

                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Add_Club_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Clubs!==undefined){
                            subs = student.Clubs;
                        }
                        if(attributeGroups.ClubGroup!==undefined){
                            subGroup = attributeGroups.ClubGroup;
                        }
                        let newSubs =new Set([...subs,...subGroup])
                        return({...student,Clubs:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Add_Club_Selected":
                    let selectedstud = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{
                        if (selectedstud.has(student.id)){
                            let subs =[];
                            let subGroup =[];
                            if(student.Clubs!==undefined){
                                subs = student.Clubs;
                            }
                            if(attributeGroups.ClubGroup!==undefined){
                                subGroup = attributeGroups.ClubGroup;
                            }
                            let newSubs =new Set([...subs,...subGroup])
                            return({...student,Clubs:[...newSubs]})

                        }else{
                            return student;
                        }
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Club_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Clubs!==undefined){
                                subs = student.Clubs;
                        }
                        if(attributeGroups.ClubGroup!==undefined){
                            subGroup = attributeGroups.ClubGroup;
                        }
                        let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                        return({...student,Clubs:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Club_Selected":
                    let selectedstudsss = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{
                        if (selectedstudsss.has(student.id)){

                            let subs =[];
                            let subGroup =[];
                            if(student.Clubs!==undefined){
                                subs = student.Clubs;
                            }
                            if(attributeGroups.ClubGroup!==undefined){
                                subGroup = attributeGroups.ClubGroup;
                            }
                            let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                            return({...student,Clubs:[...newSubs]})

                        }else{
                            return student;
                        }

                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Add_Sport_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Sports!==undefined){
                            subs = student.Sports;
                        }
                        if(attributeGroups.SportGroup!==undefined){
                            subGroup = attributeGroups.SportGroup;
                        }
                        let newSubs =new Set([...subs,...subGroup])
                        return({...student,Sports:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Add_Sport_Selected":
                    let selectedstudd = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{
                        if (selectedstudd.has(student.id)){
                            let subs =[];
                            let subGroup =[];
                            if(student.Sports!==undefined){
                                subs = student.Sports;
                            }
                            if(attributeGroups.SportGroup!==undefined){
                                subGroup = attributeGroups.SportGroup;
                            }
                            let newSubs =new Set([...subs,...subGroup])
                            return({...student,Sports:[...newSubs]})

                        }else{
                            return student;
                        }
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Sport_All":
                    alteredStudents = students.map(student=>{
                        let subs =[];
                        let subGroup =[];
                        if(student.Sports!==undefined){
                            subs = student.Sports;
                        }
                        if(attributeGroups.SportGroup!==undefined){
                            subGroup = attributeGroups.SportGroup;
                        }
                        let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                        return({...student,Sports:[...newSubs]})
                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

                case "Remove_Sport_Selected":
                    let selectedstudss = new Set(SelectedStudents.students.map(stud=>stud.id))
                    alteredStudents = students.map(student=>{
                        if (selectedstudss.has(student.id)){

                            let subs =[];
                            let subGroup =[];
                            if(student.Sports!==undefined){
                                subs = student.Sports;
                            }
                            if(attributeGroups.SportGroup!==undefined){
                                subGroup = attributeGroups.SportGroup;
                            }
                            let newSubs= new Set(subs.filter(n=>!subGroup.includes(n)))
                            return({...student,Sports:[...newSubs]})

                        }else{
                            return student;
                        }

                    })
                    dispatch(teacherActions.alterStudents(alteredStudents))
                    break;

            }
        }catch(e){
               //APA MUSHANDISI WE SYSTEM ANOBVA AFUNGA KUTI COMPUTER YAKE YASTICKER OBVA AEDZA FUTI ZVOITA... BUG SOLVED
        }

    }

    function handleSubjectGroup (e){
        let y=new Set(attributeGroups.SubjectGroup);
        if(e.target.checked){
            y.add(e.target.value);
        }else{
            y.delete(e.target.value)
        }
        setAttributeGroups((prevState) => ({...prevState, SubjectGroup: Array.from(y)}))
    }

    function handleClubGroup (e){
        let y=new Set(attributeGroups.ClubGroup);
        if(e.target.checked){
            y.add(e.target.value);
        }else{
            y.delete(e.target.value)
        }
        setAttributeGroups((prevState) => ({...prevState, ClubGroup: Array.from(y)}))
    }

    function handleSportGroup (e){
        let y=new Set(attributeGroups.SportGroup);
        if(e.target.checked){
            y.add(e.target.value);
        }else{
            y.delete(e.target.value)
        }
        setAttributeGroups((prevState) => ({...prevState, SportGroup: Array.from(y)}))
    }

    function handleIndividualStudent(e) {
        try{
            let subs = JSON.parse(e.target.getAttribute('data-student'))
            let alteredStudents = students.map(stud=>{
                if(stud.id===subs.id){
                    return {...stud,Subjects:subs.Subjects,Clubs:subs.Clubs,Sports:subs.Sports};
                }else{
                    return stud;
                }
            })
            dispatch(teacherActions.alterStudents(alteredStudents))
        }catch (e) {
            try {

                let subs = JSON.parse(e.target.firstChild.getAttribute('data-student'))
                let alteredStudents = students.map(stud=>{
                    if(stud.id===subs.id){
                        return {...stud,Subjects:subs.Subjects,Clubs:subs.Clubs,Sports:subs.Sports};
                    }else{
                        return stud;
                    }
                })
                dispatch(teacherActions.alterStudents(alteredStudents))

            }catch (e) {

            }

        }


    }

    function handleIndividualStudentHouse(e) {
        let subs  = JSON.parse(e.target.getAttribute('data-student'))
        let house = e.target.value;
        let stud  = {houseId:house}
        let alteredStudents = students.map(student=>{
            if(student.id === subs.id){
                return {...student,...stud,Subjects:subs.Subjects,Clubs:subs.Clubs,Sports:subs.Sports}
            }else{
                return student
            }
        })
        dispatch(teacherActions.alterStudents(alteredStudents))
    }

    function RegisterSingleStudent(e) {

        let student = JSON.parse(e.target.getAttribute('data-student'))

        dispatch(alertActions.success("Registering "+student.fname+" "+student.lname+". Please Wait"))
        dispatch(teacherActions.RegisterStudents([{...student}],currentClass.cls))
        setAttributeGroups((prevState) => ({...prevState,SubjectGroup:[],
            ClubGroup:[],
            SportGroup:[]}))

    }

    function RegisterAllStudents(){
        if(students){
            dispatch(alertActions.success("Registering students .Please Wait"))
            dispatch(teacherActions.RegisterStudents(students,currentClass.cls))
            setAttributeGroups((prevState) => ({...prevState,SubjectGroup:[],
                ClubGroup:[],
                SportGroup:[]}))

        }else{
            dispatch(alertActions.error("No Students Selected. Please try again"))
            setTimeout(()=>{dispatch(alertActions.clear())},3000)
        }

    }

    function RegisterBulkStudents(){
        if(students){
            let bulkStudents = [];
            let selected = new Set(SelectedStudents.students.map(stud=>stud.id))
            students.map(student=>{
                if (selected.has(student.id)){
                    bulkStudents = [...bulkStudents,student]
                }
            })
            if(bulkStudents.length<1){
                dispatch(alertActions.error("No Students Selected. Please try again"))
                setTimeout(()=>{dispatch(alertActions.clear())},3000)
            }else{
                dispatch(alertActions.success("Registering students .Please Wait"))
                dispatch(teacherActions.RegisterStudents(bulkStudents,currentClass.cls))
                setAttributeGroups((prevState) => ({...prevState,SubjectGroup:[],
                    ClubGroup:[],
                    SportGroup:[]}))
            }


        }else{
            dispatch(alertActions.error("No Students Selected. Please try again"))
            setTimeout(()=>{dispatch(alertActions.clear())},3000)
        }

    }

    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className={"block-header "}>
                    <div className={"row clearfix  align-items-end  "}>
                        <div className={"col-4 text-bold  "}>
                            <strong><h2>Register Students</h2></strong>
                        </div>
                    </div>
                </div>
                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }
                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Register Students</li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}

                <div className="row mt-5 mb-4 ">
                    {options&&options.subjects?(<>
                        <div className="col-6" >
                            <div className="container-fluid border bg-white p-3 rounded">
                                <div className="row">
                                    <div className="col">
                                        <select className="col p-0 m-0" name="cls" onChange={handleClassChange}>
                                            <option value={"ALL"}>ALL CLASSES</option>

                                            {options.subjects.map(subject=>{
                                                return(
                                                    <>
                                                        <option value={subject.clazz.clName}>{subject.clazz.clName}</option>
                                                    </>
                                                )})}

                                        </select >
                                    </div>
                                    <div className="col-4"> <div className="btn btn-dark col" onClick={submitClassRegistrationRequest}><small>Retrieve Class</small></div></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 ">
                            <div className="container">
                                <div className="row align-content-center">
                                    <div className="col m-1 p-0" onClick={RegisterBulkStudents}>
                                        <div className="btn btn-primary col"><small>Register Selected</small></div>
                                    </div>
                                    <div className="col m-1 p-0">
                                        <div className="btn btn-primary col" onClick={RegisterAllStudents}><small>Register All</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>):""}
                </div>

                <div className="row justify-content-between">
                    <div className="col">

                        <div className={" card shadow "}>
                            <div className={" card-header d-flex bg-light  "}>

                                <div className="col">CREATE SUBJECT GROUP</div>


                                <div onClick={toggleSubject} className="zimra-menu col-1">
                                    <i className={"fa fa-bars fa-md  m-0 p-0"}></i>
                                </div>

                            </div>
                            <Collapse isOpen={isSubjectOpen}>

                            <div className="card-body">

                                { studentOptions&&studentOptions.subjects?studentOptions.subjects.map(candidate=> {
                                        if(candidate.clazz.clName === currentClass.cls) {
                                            return(candidate.subjects.map(sub => {
                                                return (
                                                    <>
                                                        <div className="col ">
                                                            <input className="labelPicker" onChange={handleSubjectGroup}
                                                                   type="checkbox" id={sub.subjectkey}
                                                                   name={sub.subjectkey} value={sub.subjectname}/>
                                                            <label className="labelPicker"
                                                                   htmlFor={sub.subjectkey}>{sub.subjectname}</label>

                                                        </div>

                                                    </>
                                                )
                                            }))
                                        }

                                    })
                                    : ""}

                            </div>

                            </Collapse>
                            <div className="card-footer bg-dark m-0 p-x py-2  ">
                                <div className="row mb-3">
                                    <div className="col">
                                        <small><small>  {attributeGroups.SubjectGroup.length} subject(s) selected  {!isSubjectOpen?<strong>expand panel to edit</strong>:""}</small></small>
                                    </div>
                                </div>
                                {students?
                                <div className="row justify-content-around">

                                <Badge id={"Add_Subject_All"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0 "}/>ALL</Badge>
                                <Badge id={"Add_Subject_Selected"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>
                                <Badge id={"Remove_Subject_All"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0"}/>ALL</Badge>
                                <Badge  id={"Remove_Subject_Selected"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>
                            </div>:""}

                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className={" card shadow"}>
                            <div className={" card-header d-flex bg-light  "}>

                                <div className="col">CREATE CLUB GROUP</div>


                                <div onClick={toggleClub} className="zimra-menu col-1">
                                    <i className={"fa fa-bars fa-md  m-0 p-0"}></i>
                                </div>

                            </div>
                            <Collapse isOpen={isClubOpen}>
                                <div className="card-body">
                                    { studentOptions&&studentOptions.subjects?studentOptions.clubs.map( sub=>{
                                            return(
                                                <>

                                                    <div className="col ">
                                                        <input className="labelPicker" onChange={handleClubGroup} type="checkbox" id ={sub.clubkey} name={sub.clubkey} value={sub.name} />
                                                        <label className="labelPicker"  htmlFor={sub.clubkey}>{sub.name}</label>

                                                    </div>

                                                </>
                                            )
                                        })
                                        : ""}
                                </div>
                            </Collapse>
                            <div className="card-footer bg-dark m-0 p-x py-2  ">
                                <div className="row mb-3">
                                    <div className="col">
                                        <small><small>{attributeGroups.ClubGroup.length} club(s) selected  {!isClubOpen?<strong>expand panel to edit </strong>:""}</small></small>
                                    </div>
                                </div>
                                {students?
                                <div className="row justify-content-around">

                                    <Badge id={"Add_Club_All"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0 "}/>ALL</Badge>
                                    <Badge id={"Add_Club_Selected"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>
                                    <Badge id={"Remove_Club_All"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0 "}/>ALL</Badge>
                                    <Badge  id={"Remove_Club_Selected"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>

                                </div>:""}

                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className={" card shadow "}>
                            <div className={" card-header d-flex bg-light  "}>

                                <div className="col">CREATE SPORTS GROUP</div>


                                <div onClick={toggleSport} className="zimra-menu col-1">
                                    <i className={"fa fa-bars fa-md  m-0 p-0"}></i>
                                </div>

                            </div>
                            <Collapse isOpen={isSportOpen}>
                            <div className="card-body">
                                { studentOptions&&studentOptions.subjects?studentOptions.sports.map( sub=>{
                                        return(
                                            <>

                                                <div className="col ">
                                                    <input className="labelPicker" onChange={handleSportGroup} type="checkbox" id ={sub.sportkey} name={sub.sportkey} value={sub.name} />
                                                    <label className="labelPicker"  htmlFor={sub.sportkey}>{sub.name}</label>

                                                </div>

                                            </>
                                        )
                                    })
                                    : ""}

                            </div>
                            </Collapse>
                            <div className="card-footer bg-dark m-0 p-x py-2  ">
                                <div className="row mb-3">
                                    <div className="col">
                                            <small><small>  {attributeGroups.SportGroup.length} Sport(s) selected  {!isSportOpen?<strong>expand panel to edit </strong>:""}</small></small>
                                    </div>
                                </div>
                                {students?
                                <div className="row justify-content-around">


                                    <Badge id={"Add_Sport_All"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0"}/>ALL</Badge>
                                    <Badge id={"Add_Sport_Selected"} onClick={alterStudents} className={"badgeButton"} color="success"><i className={"fa fa-plus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>
                                    <Badge id={"Remove_Sport_All"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0 "}/>ALL</Badge>
                                    <Badge  id={"Remove_Sport_Selected"} onClick={alterStudents} className={"badgeButton"} color="dark"><i className={"fa fa-minus-circle fa-md  m-0 p-0 "}/>SELECTED</Badge>

                                </div>:""}

                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mt-2 p-3">
                    <div className="col border p-0">
                        {students? <MaterialTable

                            title= {currentClass.cls+" Student Registration"}
                            icons={tableIcons}
                            data={students}

                            columns={columns}
                            options={{ selection: true,search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]

                            }}


                           detailPanel={[
                               {render: rowData => {

                                    return (
                                        <div className={'container'}
                                            style={{

                                                textAlign: 'center',

                                                borderTop:'3px solid #ffc107'
                                            }}
                                        >
                                            <h5 className="row ml-5 pl-5 py-2 pt-4">{rowData.lname+" "+rowData.fname}</h5>
                                            <div className="row ml-3 py-2">
                                                <div className="col-4">
                                                    <strong className="text-bold mr-3">HOUSE</strong>

                                                <select name="houseId" value={rowData.houseId} onChange={handleIndividualStudentHouse} data-student={JSON.stringify({...rowData,})}  >

                                                    {options.houses.map(house=>{

                                                        return(
                                                            <>
                                                                <option value={house.houseId} >{house.house}</option>
                                                            </>
                                                        )})}

                                                </select >
                                                </div>
                                            </div>
                                            <div className="row mx-5  py-2" >
                                                <div className="col">
                                                <ContextMenuTrigger id= {'subjectchoices'} >
                                                <div className="col  bg-light border pl-5 rounded mx-2 "  style={{cursor:'pointer'}}>

                                                    <div className="row mini-box"><strong>SUBJECTS </strong></div>
                                                    <ContextMenu  id= {'subjectchoices'}  style={{cursor:'pointer'}} title={"Add Subject"}>
                                                        <MenuItem className="row mini-box m-0 p-0" >
                                                            <div className="col m-0 p-0 hie " style={{textTransform:"uppercase",fontWeight:"bold"}}>  ADD SUBJECTS</div>
                                                        </MenuItem>
                                                        { studentOptions&&studentOptions.subjects?studentOptions.subjects[0].subjects.map( subject=>{

                                                            let set = new Set(rowData.Subjects)
                                                            let studid = rowData.id;
                                                            let subs =[];
                                                            if(!set.has(subject.subjectname)){

                                                                if(rowData.Subjects!==undefined){
                                                                    subs = rowData.Subjects;
                                                                }
                                                                  return(
                                                                      <>
                                                                          <MenuItem className="row p-0 m-0" onClick={handleIndividualStudent}>
                                                                              <div className={"col p-0 m-0"} data-student={JSON.stringify({...rowData,Subjects:[...subs,subject.subjectname] })}  key={studid}>

                                                                              {subject.subjectname}
                                                                              </div>
                                                                            </MenuItem>

                                                                      </>
                                                                  )
                                                              }else return ""

                                                            })
                                                            : ""}

                                                    </ContextMenu>

                                                    {rowData.Subjects!==undefined?(

                                                        rowData.Subjects.map( subject=>{

                                                            return(
                                                                <>

                                                                    <ContextMenu id= {subject}  style={{cursor:'pointer'}}>

                                                                        <MenuItem className="row mini-box" >
                                                                            <div onClick={handleIndividualStudent} data-student={JSON.stringify({...rowData,Subjects:[...rowData.Subjects.filter(x=>x!==subject)] })}  key={subject}>
                                                                                Remove  {subject}</div>
                                                                        </MenuItem>


                                                                    </ContextMenu>
                                                                <div className="row mini-box"><div  className=" m-0 p-0">

                                                                    <ContextMenuTrigger id= {subject}>
                                                                        <div className= {subject}  style={{cursor:'pointer'}} > {subject}</div>
                                                                    </ContextMenuTrigger>

                                                                </div>
                                                                </div>
                                                                </>
                                                            )})):""
                                                    }


                                        </div></ContextMenuTrigger></div>
                                                <div className="col">
                                                <ContextMenuTrigger id= {'sportchoices'} >

                                                <div className="col  bg-light border pl-5 rounded  mx-2"  style={{cursor:'pointer'}} >

                                                    <div className="row mini-box"><strong>SPORTS </strong></div>
                                                       <ContextMenu  id= {'sportchoices'}  style={{cursor:'pointer'}} title={"Add Sport"}>
                                                        <MenuItem className="row mini-box p-0 m-0" >
                                                            <div className="hie col p-0 m-0" style={{textTransform:"uppercase",fontWeight:"bold"}}>  ADD SPORTS</div>
                                                        </MenuItem>
                                                        { studentOptions&&studentOptions.sports?studentOptions.sports.map( subject=>{

                                                                let set = new Set(rowData.Sports)
                                                                let studid = rowData.id;
                                                                let subs =[];
                                                                if(!set.has(subject.name)){

                                                                    if(rowData.Sports!==undefined){
                                                                        subs = rowData.Sports;
                                                                    }
                                                                    return(
                                                                        <>

                                                                            <MenuItem className="row mini-box m-0 p-0" onClick={handleIndividualStudent}>
                                                                                <div  className={"col m-0 p-0"} data-student={JSON.stringify({...rowData,Sports:[...subs,subject.name] })}  key={studid}>
                                                                                    {subject.name}
                                                                                </div>
                                                                            </MenuItem>

                                                                        </>
                                                                    )
                                                                }else return ""

                                                            })
                                                            : ""}

                                                    </ContextMenu>


                                                    {rowData.Sports!==undefined?(
                                                        rowData.Sports.map( subject=>{

                                                            return(
                                                                <>



                                                                <ContextMenu id= {subject}  style={{cursor:'pointer'}}>

                                                                    <MenuItem className="row mini-box" >
                                                                        <div onClick={handleIndividualStudent} data-student={JSON.stringify({...rowData,Sports:[...rowData.Sports.filter(x=>x!==subject)] })}  key={subject}>
                                                                            Remove  {subject}</div>
                                                                    </MenuItem>


                                                                </ContextMenu>
                                                                <div className="row mini-box "><div  className=" m-0 p-0">

                                                                    <ContextMenuTrigger id= {subject}>
                                                                        <div className= {subject}  style={{cursor:'pointer'}}> {subject}</div>
                                                                    </ContextMenuTrigger>
                                                                </div>
                                                                </div>
                                                                </>
                                                            )})):""
                                                    }
                                                </div>
                                                </ContextMenuTrigger></div>
                                                <div className="col">
                                                <ContextMenuTrigger id= {'clubchoices' } >
                                                <div className="col  bg-light border pl-5 rounded  mx-2"  style={{cursor:'pointer'}} >
                                                        <div className="row mini-box"><strong>CLUBS </strong></div>
                                                        <ContextMenu  id= {'clubchoices'}  style={{cursor:'pointer'}} title={"Add Club"}>
                                                            <MenuItem className="row mini-box m-0 p-0" >
                                                                <div className="hie col m-0 p-0" style={{textTransform:"uppercase",fontWeight:"bold"}}>  ADD CLUBS</div>
                                                            </MenuItem>
                                                            { studentOptions&&studentOptions.clubs?studentOptions.clubs.map( subject=>{

                                                                    let set = new Set(rowData.Clubs)
                                                                    let studid = rowData.id;
                                                                    let subs =[];
                                                                    if(!set.has(subject.name)){

                                                                        if(rowData.Clubs!==undefined){
                                                                            subs = rowData.Clubs;
                                                                        }
                                                                        return(
                                                                            <>
                                                                                <MenuItem className="row mini-box" onClick={handleIndividualStudent}>
                                                                                    <div  data-student={JSON.stringify({...rowData,Clubs:[...subs,subject.name] })}  key={studid}>

                                                                                        {subject.name}</div>
                                                                                </MenuItem>

                                                                            </>
                                                                        )
                                                                    }else return ""

                                                                })
                                                                : ""}

                                                        </ContextMenu>


                                                        {rowData.Clubs!==undefined?(
                                                            rowData.Clubs.map( subject=>{

                                                                return(
                                                                    <>



                                                                        <ContextMenu id= {subject}  style={{cursor:'pointer'}}>

                                                                            <MenuItem className="row mini-box" >
                                                                                <div onClick={handleIndividualStudent} data-student={JSON.stringify({...rowData,Clubs:[...rowData.Clubs.filter(x=>x!==subject)] })}  key={subject}>
                                                                                    Remove  {subject}</div>
                                                                            </MenuItem>


                                                                        </ContextMenu>
                                                                        <div className="row mini-box "><div  className=" m-0 p-0">

                                                                            <ContextMenuTrigger id= {subject}>
                                                                                <div className= {subject}  style={{cursor:'pointer'}}> {subject}</div>
                                                                            </ContextMenuTrigger>
                                                                        </div>
                                                                        </div>
                                                                    </>
                                                                )})):""
                                                        }
                                                </div>
                                                </ContextMenuTrigger>
                                            </div></div>
                                            <br/><br/>
                                            <div className="row mx-5  py-2 justify-content-end" >
                                                <div className="btn btn-rounded btn-success" onClick={RegisterSingleStudent} data-student={JSON.stringify(rowData)}>
                                                    Register
                                                </div>
                                            </div>
                                            <br/><br/><br/>



                                        </div>
                                      )
                                }
                            }]}
                                onSelectionChange={(rows)=>setSelectedStudents((prevState) => ({...prevState, students:rows}))}

                        />:<MaterialTable
                            title= "Unregistered Students"
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ selection: true,search: true, paging: true, filtering: false, exportButton: true,grouping: false, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />
                        }
                    </div>



                </div>
            <br/>

            <Footer/>

            </div>
        </>);

}

export default RegisterStudents;


