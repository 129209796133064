
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch,useSelector } from "react-redux";
import {alertActions} from "../actions";
import {teacherActions} from "../actions/teacher.actions";



function UploadPopup(props) {

  const dispatch = useDispatch();
  const popup = useSelector((state) => state.popupReducer.uploadPopup);
  const message = useSelector((state) => state.alert);

  const [currentFile,setCurrentFile] = useState({
    upload:null
  });

  const [uploadInfo,setUploadInfo] = useState({
    description:""
  })
  function handleUpload (e){
    e.persist();
    e.preventDefault()
    if(e.target&&e.target.files&&e.target.files[0]&&(e.target.files[0]!=undefined||e.target.files[0]!=null)){
      if (true){
        setCurrentFile((currentFile) => ({upload:e.target.files[0]}))
      }else{
        setCurrentFile((currentFile) => ({upload:null}))
        dispatch(alertActions.error("Invalid File"));
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 5000)
      }}
  }
  function SubmitUpload() {

    if (currentFile.upload!=undefined||currentFile.upload!=null) {

      if (currentFile.upload.size/1024/1024<=3){
        if (uploadInfo.description !== "") {
          if (true) {
            let file = new FormData();

            file.append("file", currentFile.upload);

            const windowLoc = window.location.href.replace("%7C","|").split("/");

            let subKey = windowLoc[windowLoc.length - 1].split("|")[0];
            let classKey = windowLoc[windowLoc.length - 1].split("|")[1];

            dispatch(alertActions.success("Working, Please wait"));
            dispatch(teacherActions.submitUpload(file, uploadInfo.description, subKey, classKey))

          } else {
            setCurrentFile((currentFile) => ({...currentFile, upload: undefined}))
            dispatch(alertActions.error("Invalid File"));

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, 5000)
          }
        } else {
          dispatch(alertActions.error("Please enter valid description of upload"));
          setTimeout(() => {
            dispatch(alertActions.clear())
          }, 5000)
        }
      }else{
        dispatch(alertActions.error("File should be 3 MBs or less"))
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 3000)

      }


    }

  }

  function handleChange(e){
    let { name,value } = e.target
    setUploadInfo((uploadInfo) => ({...uploadInfo, [name]:value}))
  }

  return (
      popup ? (
            <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center ">
          {popup ? (
            <>
              <div className="row align-items-center">
                <div className="col ">
                  <strong className="mt-4 text-bold"> Upload New File</strong>
                </div>
              </div>
            </>
          ) : (
            "Event"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          { message ? (
              <div className={message.type + " my-1"}>
                {" "}
                <div className="popup"> {message.message}</div>
              </div>) : ("")
          }
          {popup ? (
            <>
                <div className="row">

                  <form>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-4">
                          <label className={" m-1"} htmlFor="description">Description</label>
                        </div>
                        <div className="col-8">
                          <input type="text"

                                 className="text"
                                 id="description"
                                 name="description"
                                 onChange={handleChange}
                                 />
                        </div>


                      </div>
                        <div className="row">
                          <div className="col-4">
                            <label className={" m-1"} htmlFor="file">Pick File</label>
                          </div>

                          <div className="col-8">
                            <input type="file"

                                //   className="custom-file-input "
                                   id="file"
                                   name="file"
                                   onChange={handleUpload}
                                  />
                          </div>

                        </div>

                    </div>




                    {
                      (currentFile.upload!==undefined||currentFile.upload!=null)?(
                          <label className={"btn btn-success border m-1"} onClick={SubmitUpload}>Submit </label>
                      ):""

                    }


                  </form>

                </div>



            </>
          ) : (
            <p>Nothing to show :(</p>
          )}
        </div>
      </Modal.Body>

    </Modal></>):""
  );
}
export default UploadPopup;
