import {
   teacherConstants
  } from "../constants/teacher.constants";
import {useSelector} from "react-redux";

  const initialState = {
      Marks:[],
      CurrentClass:" ",
      subject:"",
      sse:false,
      //attendanceRegister: {}
  };


  export function teacherReducer(state =initialState, action) {
    switch (action.type) {
        case teacherConstants.REPORT_RESET_REQUEST:
        return{...state,
            ReportCards:""
        }
        case teacherConstants.MARK_REGISTER_REQUEST:
            return {
                ...state,
                loading:true,
                Updating: true,
                Updated:false

            };
        case teacherConstants.MARK_REGISTER_FAILURE:
            return{
                ...state,
                loading:false,
                Updating:false,
                Updated:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MARK_REGISTER_SUCCESS:
            return {
                ...state,
                loading:false,
                Updating: false,
                netError:false,
                Updated:true,
                //attendanceRegister:{...state.attendanceRegister, attendance:[...state.attendanceRegister.attendance,action.payload]}
            };
        case teacherConstants.DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                Updating: true,
                Updated:false

            };
        case teacherConstants.DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                Updating:false,
                Updated:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                Updating: false,
                netError:false,
                Updated:true,
            };
        case teacherConstants.CHANGE_SSE_STATUS:
            return {
                ...state,
                LoadingStatus: action.payload,
                sse:true
            };
        case teacherConstants.CUT_SSE:
            return {
                ...state,
                LoadingStatus:{progress:0,message:"Please wait"},
                sse:false
            };

        case teacherConstants.CHANGE_SUBJECT_VIEW:
            return {
                ...state,
                subject: action.payload
            };

        case teacherConstants.RESET_NET_ERROR:
            return {
                ...state,
                netError: false,
                students:false,
                MarksCLass: "",
                Marks :[]
            };
        case teacherConstants.RESET_CLEARANCES:
            return {
                ...state,
                netError: false,
                studentsArrears:false
            };
        case teacherConstants.STUDENT_DELETE_REQUEST:
            return {
                ...state,
                deletingStudent: true,
                deletedStudent:false,
                loading:true

            };
        case teacherConstants.STUDENT_DELETE_FAILURE:
            return{
                ...state,
                deletingStudent:false,
                deletedStudent:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.STUDENT_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                deletingStudent: false,
                netError:false,
                deletedStudent:true,


            };
        case teacherConstants.FILE_DELETE_REQUEST:
            return {
                ...state,
                deletingFile: true,
                deletedFile:false,
                loading:true

            };
        case teacherConstants.FILE_DELETE_FAILURE:
            return{
                ...state,
                deletingFile:false,
                deletedFile:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.FILE_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                deletingFile: false,
                netError:false,
                deletedFile:true,


            };
        case teacherConstants.SUBJECT_DELETE_REQUEST:
            return {
                ...state,
                deletingSubject: true,
                deletedSubject:false,
                loading:true

            };
        case teacherConstants.SUBJECT_DELETE_FAILURE:
            return{
                ...state,
                deletingSubject:false,
                deletedSubject:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.SUBJECT_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                deletingSubject: false,
                netError:false,
                deletedSubject:true,


            };

        case teacherConstants.SUBJECT_CLASS_DELETE_REQUEST:
            return {
                ...state,
                deletingSubjectClass: true,
                deletedSubjectClass:false,
                loading:true

            };
        case teacherConstants.SUBJECT_CLASS_DELETE_FAILURE:
            return{
                ...state,
                deletingSubjectClass:false,
                deletedSubjectClass:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.SUBJECT_CLASS_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                deletingSubjectClass: false,
                netError:false,
                deletedSubjectClass:true,


            };

        case teacherConstants.SUBJECT_CLASS_ADD_REQUEST:
            return {
                ...state,
                addingSubjectClass: true,
                addedSubjectClass:false,
                loading:true

            };
        case teacherConstants.SUBJECT_CLASS_ADD_FAILURE:
            return{
                ...state,
                addingSubjectClass:false,
                addedSubjectClass:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.SUBJECT_CLASS_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingSubjectClass: false,
                netError:false,
                addedSubjectClass:true,


            };
        case teacherConstants.ASSESSMENT_MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                submittingAssessmentMark: true,
                submittedAssessmentMark:false,
                loading:true

            };
        case teacherConstants.ASSESSMENT_MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                submittingAssessmentMark:false,
                submittedAssessmentMarks:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ASSESSMENT_MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingAssessmentMark: false,
                netError:false,
            };

        case teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingAttendanceRegister: true,
                fetchedAttendanceRegister:false,
                loading:true

            };
        case teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingAttendanceRegister:false,
                fetchedAttendanceRegister:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAttendanceRegister: false,
                netError:false,
                attendanceRegister:action.payload,


            };
        case teacherConstants.ATTENDANCE_STATS_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingAttendanceStats: true,
                fetchedAttendanceStats:false,
                loading:true

            };
        case teacherConstants.ATTENDANCE_STATS_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingAttendanceStats:false,
                fetchedAttendanceStats:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ATTENDANCE_STATS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAttendanceStats: false,
                netError:false,
                attendanceStats:action.payload,


            };
        case teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingSingleSubjectInformation: true,
                fetchedSingleSubjectInformation:false,
                loading:true

            };
        case teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingSingleSubjectInformation:false,
                fetchedSingleSubjectInformation:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingSingleSubjectInformation: false,
                netError:false,
                SingleSubjectInformation:action.payload,


            };

        case teacherConstants.SUBJECT_INFORMATION_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingSubjectInformation: true,
                fetchedSubjectInformation:false,
                loading:true

            };
        case teacherConstants.SUBJECT_INFORMATION_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingSubjectInformation:false,
                fetchedSubjectInformation:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.SUBJECT_INFORMATION_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingSubjectInformation: false,
                netError:false,
                SubjectInformation:action.payload,


            };

        case teacherConstants.STUDENT_STATEMENT_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingStudentStatement: true,
                fetchedStudentStatement:false,
                loading:true

            };
        case teacherConstants.STUDENT_STATEMENT_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingStudentStatement:false,
                fetchedStudentStatement:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.STUDENT_STATEMENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudentStatement: false,
                netError:false,
                StudentStatement:action.payload,


            };

        case teacherConstants.ASSESSMENT_MARKS_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingAssessmentMarks: true,
                fetchedAssessmentMarks:false,
                loading:true

            };
        case teacherConstants.ASSESSMENT_MARKS_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingAssessmentMarks:false,
                fetchedAssessmentMarks:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ASSESSMENT_MARKS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAssessmentMarks: false,
                fetchedAssessmentMarks:true,
                netError:false,
                assessmentMarks:action.payload,


            };

        case teacherConstants.ASSESSMENT_MARKS_DELETE_REQUEST:
            return {
                ...state,
                deletingMarks: true,
                deletedMarks:false,
                loading:true

            };
        case teacherConstants.ASSESSMENT_MARKS_DELETE_FAILURE:
            return{
                ...state,
                deletingMarks:false,
                deletedMarks:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ASSESSMENT_MARKS_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                deletingMarks: false,
                netError:false,
                deletedMarks:true,


            };
        case teacherConstants.ACCOUNTS_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingAccounts: true,
                fetchedAccounts:false,
                loading:true

            };
        case teacherConstants.ACCOUNTS_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingAccounts:false,
                fetchedAccounts:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.ACCOUNTS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAccounts: false,
                netError:false,
                fetchedAccounts:true,
                StudentAccounts : action.payload


            };
        case teacherConstants.PDF_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingPDF: true,
                fetchedPDF:false,
                loading:true

            };
        case teacherConstants.PDF_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingPDF:false,
                fetchedPDF:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.PDF_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingPDF: false,
                netError:false,
                fetchedPDF:true,


            };
        case teacherConstants.STUDENT_ID_RESET_REQUEST:
            return {
                ...state,
                loading:false,
                validStudent:"",
                validatedStudent:false,
                CurrentStudent: ""
            };

        case teacherConstants.TEACHER_ID_RESET_REQUEST:
            return {
                ...state,
                loading:false,
                validTeacher:"",
                validatedTeacher:false,
                CurrentTeacher: ""
            };

        case teacherConstants.MINI_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingMiniProfile: true,
                fetchedMiniProfile:false

            };
        case teacherConstants.MINI_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingMiniProfile:false,
                fetchedMiniProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MINI_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingMiniProfile: false,
                netError:false,
                fetchedMiniProfile:true,
                CurrentStudent:action.payload,

            };

        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingTeacherMiniProfile: true,
                fetchedTeacherMiniProfile:false

            };
        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTeacherMiniProfile:false,
                fetchedTeacherMiniProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingTeacherMiniProfile: false,
                netError:false,
                fetchedTeacherMiniProfile:true,
                CurrentTeacher:action.payload,

            };
        case teacherConstants.MARK_PROFILES_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingMarkProfiles: true,
                fetchedMarkProfiles:false

            };
        case teacherConstants.MARK_PROFILES_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingMarkProfiles:false,
                fetchedMarkProfiles:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MARK_PROFILES_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingMarkProfiles: false,
                netError:false,
                fetchedMarkProfiles:true,
                MarkProfiles:action.payload,

            };


        case teacherConstants.PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingProfile: true,
                fetchedProfile:false

            };
        case teacherConstants.PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingProfile:false,
                fetchedProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingProfile: false,
                netError:false,
                fetchedProfile:true,
              //  Profile:action.payload.isReportGenerated?{...action.payload,ReportCard:JSON.parse(action.payload.ReportCard.replace("\\",""))}:action.payload,
                Profile:action.payload,
                CurrentClass:action.payload.CurrentClass?action.payload.CurrentClass:""
            };
  
      case teacherConstants.TEACHER_RETRIEVE_REQUEST:
        return {
            ...state,
            loading:true,
          fetchingTic: true,
          fetchedTIC:false
        
        };
        case teacherConstants.TEACHER_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTic:false,
                fetchedTIC:false,
                netError:true,
                error: action.error
            }

      case teacherConstants.TEACHER_RETRIEVE_SUCCESS:
        return {
            ...state,
            loading:false,
          fetchingTic: false,
            netError:false,
          fetchedTIC:true,
          TIC :action.TIC,
        };


        case teacherConstants.MARKS_RETRIEVE_REQUEST:
          return {
              ...state,
              loading:true,
            fetchingMarks: true,
            fetchedMarks:false
          
          };
          case teacherConstants.MARKS_RETRIEVE_FAILURE:
              return{
                  ...state,
                  loading:false,
                  fetchingMarks:false,
                  Marks: [],

                  netError:true,
                  fetchedMarks:false,
                  error: action.error
              }

        case teacherConstants.MARKS_RETRIEVE_SUCCESS:
          return {
              ...state,
              loading:false,
              fetchingMarks: false,
              fetchedMarks:true,
              netError:false,
              MarksCLass:action.marks.classCode+"",
              Marks :{...action.marks,marks:action.marks.marks.sort((prev,next)=>(prev.id>next.id)?1:-1)}
          };


        case teacherConstants.MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,

                submittingMarks: true,
                submittedMarks:false

            };
        case teacherConstants.MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingMarks:false,
                submittedMarks:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingMarks: false,
                netError:false,
                submittedMarks:true,

            };
        case teacherConstants.OPTIONS_REQUEST:
            return {
                ...state,
                loading:true,
                RequestingOptions: true,
                RequestedOptions:false

            };
        case teacherConstants.OPTIONS_FAILURE:
            return{
                ...state,
                loading:false,
                RequestingOptions:false,
                RequestedOptions:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.OPTIONS_SUCCESS:
            return {
                ...state,
                loading:false,
               RequestingOptions: false,
               RequestedOptions:true,
                netError:false,
                options: action.payload


            };
        case teacherConstants.TEACHER_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingTeacher: true,
                addedTeacher:false

            };
        case teacherConstants.TEACHER_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingTeacher:false,
                addedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingTeacher: false,
                netError:false,
                addedTeacher:true,

            };
        case teacherConstants.TEACHER_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                editingTeacher: true,
                editedTeacher:false

            };
        case teacherConstants.TEACHER_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                editingTeacher:false,
                editedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                editingTeacher: false,
                netError:false,
                editedTeacher:true,

            };
        case teacherConstants.TEACHER_REMOVE_REQUEST:
            return {
                ...state,
                loading:true,
                removingTeacher: true,
                removedTeacher:false

            };
        case teacherConstants.TEACHER_REMOVE_FAILURE:
            return{
                ...state,
                loading:false,
                removingTeacher:false,
                removedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_REMOVE_SUCCESS:
            return {
                ...state,
                loading:false,
                removingTeacher: false,
                netError:false,
                removedTeacher:true,

            };
        case teacherConstants.STUDENT_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingStudent: true,
                addedStudent:false

            };
        case teacherConstants.STUDENT_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingStudent:false,
                addedStudent:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.STUDENT_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingStudent: false,
                netError:false,
                addedStudent:true,

            };
        case teacherConstants.STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
               fetchingStudents:false,
                fetchedStudents:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                students:action.payload

            };
        case teacherConstants.TEACHERS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingTeachers: true,
                fetchedTeachers:false

            };
        case teacherConstants.TEACHERS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTeachers:false,
                fetchedTeachers:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHERS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingTeachers: false,
                fetchedTeachers:true,
                netError:false,
                teachers:action.payload

            };

        case teacherConstants.ALTER_STUDENTS:
            return {
                ...state,

                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                UnregisteredStudents:action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)

            };
        case teacherConstants.ALTER_TEACHER_SUBJECTS:
            return {
                ...state,

                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                CurrentTeacher:{...state.CurrentTeacher,subjects:action.payload}

            };

        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudents:false,
                netError:true,
                fetchedStudents:false,
                error: action.error
            }

        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents: true,
                netError:false,
                registeredStudents: action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)
            };

        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudents:false,
                fetchedStudents:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                UnregisteredStudents:action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)

            };
        case teacherConstants.FILE_UPLOAD_REQUEST:
            return {
                ...state,
                loading:true,
                uploadingFile: true,

                uploadedFile:false

            };
        case teacherConstants.FILE_UPLOAD_FAILURE:
            return{
                ...state,
                loading:false,
                uploadingFile:false,
                uploadedFile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading:false,
                uploadingFile: false,
                netError:false,
                uploadedFile:true,

            };
        case teacherConstants.STUDENT_UPLOAD_REQUEST:
            return {
                ...state,
                loading:true,
                uploadingStudents: true,

                uploadedStudents:false

            };
        case teacherConstants.STUDENT_UPLOAD_FAILURE:
            return{
                ...state,
                loading:false,
                uploadingStudents:false,
                uploadedStudents:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_UPLOAD_SUCCESS:
            return {
                ...state,
                loading:false,
                uploadingStudents: false,
                netError:false,
                uploadedStudents:true,

            };
        case teacherConstants.REGISTER_STUDENT_REQUEST:
            return {
                ...state,
                loading:true,
                registeringStudents: true,

                registeredStudents:false

            };
        case teacherConstants.REGISTER_STUDENT_FAILURE:
            return{
                ...state,
                loading:false,
                registeringStudents:false,
                registeredStudents:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.REGISTER_STUDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                registeringStudent: false,
                netError:false,
                registeredStudent:true,

            };

        case teacherConstants.STUDENT_ACTIVITIES_UPLOAD_REQUEST:
            return {
                ...state,
                loading:true,
                uploadingStudentActivities: true,

                uploadedStudentActivities:false

            };
        case teacherConstants.STUDENT_ACTIVITIES_UPLOAD_FAILURE:
            return{
                ...state,
                loading:false,
                uploadingStudentActivities:false,
                uploadedStudentActivities:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ACTIVITIES_UPLOAD_SUCCESS:
            return {
                ...state,
                loading:false,
                uploadingStudentActivities: false,
                netError:false,
                uploadedStudentActivities:true,

            };

        case teacherConstants.STUDENT_VALIDATE_REQUEST:
            return {
                ...state,
                loading:true,
                validatingStudent: true,

                validatedStudent:false

            };
        case teacherConstants.STUDENT_VALIDATE_FAILURE:
            return{
                ...state,
                loading:false,
                validatingStudent:false,
                validatedStudent:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_VALIDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                validatingStudent: false,
                netError:false,
                validatedStudent:true,
                validStudent:action.payload

            };


        case teacherConstants.TEACHER_VALIDATE_REQUEST:
            return {
                ...state,
                loading:true,
                validatingTeacher: true,

                validatedTeacher:false

            };
        case teacherConstants.TEACHER_VALIDATE_FAILURE:
            return{
                ...state,
                loading:false,
                validatingTeacher:false,
                validatedTeacher:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.TEACHER_VALIDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                validatingTeacher: false,
                netError:false,
                validatedTeacher:true,
                validTeacher:action.payload

            };

        case teacherConstants.ALL_ARREARS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingArrears: true,
                fetchedArrears:false

            };
        case teacherConstants.ALL_ARREARS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingArrears:false,
                fetchedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ALL_ARREARS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingArrears: false,
                netError:false,
                fetchedArrears:true,
                studentsArrears:action.payload

            };
        case teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingAccountStatementProfiles: true,
                fetchedAccountStatementProfiles:false

            };
        case teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingAccountStatementProfiles:false,
                fetchedAccountStatementProfiles:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAccountStatementProfiles: false,
                netError:false,
                fetchedAccountStatementProfiles:true,
                accountStatementsProfiles:action.payload

            };
        case teacherConstants.ACCOUNT_STATEMENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingAccountStatement: true,
                fetchedAccountStatement:false

            };
        case teacherConstants.ACCOUNT_STATEMENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingAccountStatement:false,
                fetchedAccountStatement:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ACCOUNT_STATEMENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingAccountStatement: false,
                netError:false,
                fetchedAccountStatement:true,
                studentsAccountStatement:action.payload

            };

        case teacherConstants.REPORT_STATISTICS_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingReportStats: true,
                fetchedReportStats:false

            };
        case teacherConstants.REPORT_STATISTICS_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingReportStats:false,
                fetchedReportStats:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.REPORT_STATISTICS_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingReportStats: false,
                netError:false,
                fetchedReportStats:true,
                ReportStatistics:action.payload

            };

        case teacherConstants.ARREARS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingArrears: true,
                fetchedArrears:false

            };
        case teacherConstants.ARREARS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingArrears:false,
                fetchedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ARREARS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingArrears: false,
                netError:false,
                fetchedArrears:true,
                studentArrears:action.payload

            };

        case teacherConstants.STUDENT_ARREAR_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingArrears: true,
                addedArrears:false

            };
        case teacherConstants.STUDENT_ARREAR_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingArrears:false,
                addedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ARREAR_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingArrears: false,
                netError:false,
                addedArrears:true,
                validatedStudent:false,
                validStudent:""

            };

        case teacherConstants.STUDENT_ARREAR_CLEAR_REQUEST:
            return {
                ...state,
                loading:true,
                clearingArrears: true,
                clearedArrears:false

            };
        case teacherConstants.STUDENT_ARREAR_CLEAR_FAILURE:
            return{
                ...state,
                loading:false,
                clearingArrears:false,
                clearedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ARREAR_CLEAR_SUCCESS:
            return {
                ...state,
                loading:false,
                clearingArrears: false,
                netError:false,
                clearedArrears:true,

            };

        case teacherConstants.SPORT_MARKS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingSportMarks: true,

                fetchedSportMarks:false

            };
        case teacherConstants.SPORT_MARKS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingSportMarks:false,
                fetchedSportMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_MARKS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingSportMarks: false,
                netError:false,
                fetchedSportMarks:true,
                SportMarks:action.payload

            };

        case teacherConstants.SPORT_MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingSportMarks: true,

                submittedSportMarks:false

            };
        case teacherConstants.SPORT_MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingSportMarks:false,
                submittedSportMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingSportMarks: false,
                netError:false,
                submittedSportMarks:true,


            };
        case teacherConstants.STUDENT_ACCOUNT_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingStudentAccount: true,

                submittedStudentAccount:false

            };
        case teacherConstants.STUDENT_ACCOUNT_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingStudentAccount:false,
                submittedStudentAccount:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ACCOUNT_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingStudentAccount: false,
                netError:false,
                submittedStudentAccount:true
            };
        case teacherConstants.UPLOAD_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingUpload: true,

                fetchedUpload:false

            };
        case teacherConstants.UPLOAD_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingUpload:false,
                fetchedUpload:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.UPLOAD_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingUpload: false,
                netError:false,
                fetchedUpload:true,
                uploads: action.payload

            };
        case teacherConstants.UPLOAD_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingUpload: true,

                submittedUpload:false

            };
        case teacherConstants.UPLOAD_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingUpload:false,
                submittedUpload:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.UPLOAD_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingUpload: false,
                netError:false,
                submittedUpload:true,

            };
        case teacherConstants.CLUB_MARKS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingClubMarks: true,

                fetchedClubMarks:false

            };
        case teacherConstants.CLUB_MARKS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingClubMarks:false,
                fetchedClubMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_MARKS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingClubMarks: false,
                netError:false,
                fetchedClubMarks:true,
                ClubMarks:action.payload

            };

        case teacherConstants.NEW_SUBJECT_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingSubject: true,

                submittedSubject:false

            };
        case teacherConstants.NEW_SUBJECT_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingSubject:false,
                submittedSubject:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NEW_SUBJECT_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingSubject: false,
                netError:false,
                submittedSubject:true,

            };

        case teacherConstants.NEW_SUBJECT_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                editingSubject: true,

                editedSubject:false

            };
        case teacherConstants.NEW_SUBJECT_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                editingSubject:false,
                editedSubject:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NEW_SUBJECT_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                editingSubject: false,
                netError:false,
                editedSubject:true,

            };

        case teacherConstants.NEW_ASSMNT_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingProfile: true,

                submittedProfile:false

            };
        case teacherConstants.NEW_ASSMNT_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingProfile:false,
                submittedProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NEW_ASSMNT_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingProfile: false,
                netError:false,
                submittedProfile:true,

            };
        case teacherConstants.CLUB_MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingClubMarks: true,

                submittedClubMarks:false

            };
        case teacherConstants.CLUB_MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingClubMarks:false,
                submittedClubMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingClubMarks: false,
                netError:false,
                submittedClubMarks:true,

            };

        case teacherConstants.DEPARTMENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingDepartments: true,

                fetchedDepartments:false

            };
        case teacherConstants.DEPARTMENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingDepartments:false,
                fetchedDepartments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingDepartments: false,
                netError:false,
                fetchedDepartments:true,
                Departments:action.payload

            };


        case teacherConstants.DEPARTMENT_CLEAR_REQUEST:
            return {
                ...state,
                loading:true,
                clearingDepartments: true,

                clearedDepartments:false

            };
        case teacherConstants.DEPARTMENT_CLEAR_FAILURE:
            return{
                ...state,
                loading:false,
                clearingDepartments:false,
                clearedDepartments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_CLEAR_SUCCESS:
            return {
                ...state,
                loading:false,
                clearingDepartments: false,
                netError:false,
                clearedDepartments:true,

            }

        case teacherConstants.DEPARTMENT_SUBMISSION_RESET_REQUEST:
            return {
                ...state,
                loading:true,
                resetingDepartmentSubmission: true,

                resetDepartmentSubmission:false

            };
        case teacherConstants.DEPARTMENT_SUBMISSION_RESET_FAILURE:
            return{
                ...state,
                loading:false,
                resetingDepartmentSubmission:false,
                resetDepartmentSubmission:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_SUBMISSION_RESET_SUCCESS:
            return {
                ...state,
                loading:false,
                resetingDepartmentSubmission: false,
                netError:false,
                resetDepartmentSubmission:true,

            }

        case teacherConstants.DEPARTMENT_RESET_REQUEST:
            return {
                ...state,
                loading:true,
                resetingDepartment: true,
                resetDepartment:false

            };
        case teacherConstants.DEPARTMENT_RESET_FAILURE:
            return{
                ...state,
                loading:false,
                resetingDepartment:false,
                resetDepartment:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_RESET_SUCCESS:
            return {
                ...state,
                loading:false,
                resetingDepartment: false,
                netError:false,
                resetDepartment:true,

            }

        case teacherConstants.SUBJECT_RESET_REQUEST:
            return {
                ...state,
                loading:true,
                resetingSubject: true,
                resetSubject:false

            };
        case teacherConstants.SUBJECT_RESET_FAILURE:
            return{
                ...state,
                loading:false,
                resetingSubject:false,
                resetSubject:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SUBJECT_RESET_SUCCESS:
            return {
                ...state,
                loading:false,
                resetingSubject: false,
                netError:false,
                resetSubject:true,

            }

        case teacherConstants.CLASS_RESET_REQUEST:
            return {
                ...state,
                loading:true,
                resetingClass: true,
                resetClass:false

            };
        case teacherConstants.CLASS_RESET_FAILURE:
            return{
                ...state,
                loading:false,
                resetingClass:false,
                resetClass:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLASS_RESET_SUCCESS:
            return {
                ...state,
                loading:false,
                resetingClass: false,
                netError:false,
                resetClass:true,

            }

        case teacherConstants.CONTACT_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                updatingContact: true,

                updatedContact:false

            };
        case teacherConstants.CONTACT_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                updatingContact:false,
                updatedContact:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                updatingContact: false,
                netError:false,
                updatedContact:true,
                validatedStudent:false,
                validStudent:""

            }

        case teacherConstants.CONTACTS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingContacts: true,

                fetchedContacts:false

            };
        case teacherConstants.CONTACTS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingContacts:false,
                fetchedContacts:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CONTACTS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingContacts: false,
                netError:false,
                fetchedContacts:true,
                Contacts:action.payload

            };

        case teacherConstants.MEDICALS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingMedicals: true,

                fetchedMedicals:false

            };
        case teacherConstants.MEDICALS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingMedicals:false,
                fetchedMedicals:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MEDICALS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingMedicals: false,
                netError:false,
                fetchedMedicals:true,
                Medicals:action.payload

            };

        case teacherConstants.MEDICALS_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                updatingMedicals: true,

                updatedMedicals:false

            };
        case teacherConstants.MEDICALS_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                updatingMedicals:false,
                updatedMedicals:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MEDICALS_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                updatingMedicals: false,
                netError:false,
                updatedMedicals:true,
                validatedStudent:false,
                validStudent:""
            };

        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudComments: true,

                fetchedStudComments:false

            };
        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudComments:false,
                fetchedStudComments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudComments: false,
                netError:false,
                fetchedStudComments:true,
                StudentComments:action.payload

            };

        case teacherConstants.STUDENT_COMMENT_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingStudComments: true,
                submittedStudComments:false

            };
        case teacherConstants.STUDENT_COMMENT_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingStudComments:false,
                submittedStudComments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_COMMENT_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingStudComments: false,
                netError:false,
                submittedStudComments:true,
            };

        case teacherConstants.NOTIFICATIONS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingNotifications: true,

                fetchedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingNotifications:false,
                fetchedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingNotifications: false,
                netError:false,
                fetchedNotifications:true,
                Notifications:action.payload

            };

        case teacherConstants.NOTIFICATIONS_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,
            };

        case teacherConstants.NOTIFICATIONS_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,
            };
        case teacherConstants.NOTIFICATIONS_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false
            };
        case teacherConstants.NOTIFICATIONS_SAVE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,

            };

        case teacherConstants.CALENDAR_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingCalendar: true,

                fetchedCalendar:false

            };
        case teacherConstants.CALENDAR_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingCalender:false,
                fetchingCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingCalendar: false,
                netError:false,
                fetchedCalendar:true,
                Calendar:action.payload

            };

        case teacherConstants.CALENDAR_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,
            };

        case teacherConstants.DEPARTMENT_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingDepartment: true,
                UpdatedDepartment:false

            };
        case teacherConstants.DEPARTMENT_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingDepartment:false,
                UpdatedDepartment:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingDepartment: false,
                netError:false,
                UpdatedDepartment:true,
            };

        case teacherConstants.DEPARTMENT_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingDepartment: true,
                UpdatedDepartment:false

            };
        case teacherConstants.DEPARTMENT_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingDepartment:false,
                UpdatedDepartment:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingDepartment: false,
                netError:false,
                UpdatedDepartment:true,
            };

        case teacherConstants.DEPARTMENT_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                SavingDepartment: true,
                SavedDepartment:false

            };
        case teacherConstants.DEPARTMENT_SAVE_FAILURE:
            return{
                ...state,
                loading:false,
                SavingDepartment:false,
                SavedDepartment:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                SavingDepartment: false,
                netError:false,
                SavedDepartment:true,
            };

        case teacherConstants.DEPARTMENT_REQUEST_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingDepartment: true,
                fetchedDepartment:false

            };
        case teacherConstants.DEPARTMENT_REQUEST_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingDepartment:false,
                fetchedDepartment:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingDepartment: false,
                netError:false,
                fetchedDepartment:true,
                fullDepartmentInformation : action.payload
            };
        case teacherConstants.CLUB_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingClubs: true,
                UpdatedClubs:false

            };
        case teacherConstants.CLUB_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingClubs:false,
                UpdatedClubs:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingClubs: false,
                netError:false,
                UpdatedClubs:true,
            };

        case teacherConstants.CLUB_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingClubs: true,
                UpdatedClubs:false

            };
        case teacherConstants.CLUB_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingClubs:false,
                UpdatedClubs:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingClubs: false,
                netError:false,
                UpdatedClubs:true,
            };

        case teacherConstants.CLUB_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                SavingClubs: true,
                SavedClubs:false

            };
        case teacherConstants.CLUB_SAVE_FAILURE:
            return{
                ...state,
                loading:false,
                SavingClubs:false,
                SavedClubs:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                SavingClubs: false,
                netError:false,
                SavedClubs:true,
            };

        case teacherConstants.CLUB_REQUEST_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingClubs: true,
                fetchedClubs:false

            };
        case teacherConstants.CLUB_REQUEST_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingClubs:false,
                fetchedClubs:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingClubs: false,
                netError:false,
                fetchedClubs:true,
                fullClubInformation : action.payload
            };
        case teacherConstants.SPORT_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingSports: true,
                UpdatedSports:false

            };
        case teacherConstants.SPORT_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingSports:false,
                UpdatedSports:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingSports: false,
                netError:false,
                UpdatedSports:true,
            };

        case teacherConstants.SPORT_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingSports: true,
                UpdatedSports:false

            };
        case teacherConstants.SPORT_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingSports:false,
                UpdatedSports:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingSports: false,
                netError:false,
                UpdatedSports:true,
            };

        case teacherConstants.SPORT_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                SavingSports: true,
                SavedSports:false

            };
        case teacherConstants.SPORT_SAVE_FAILURE:
            return{
                ...state,
                loading:false,
                SavingSports:false,
                SavedSports:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                SavingSports: false,
                netError:false,
                SavedSports:true,
            };

        case teacherConstants.SPORT_REQUEST_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingSports: true,
                fetchedSports:false

            };
        case teacherConstants.SPORT_REQUEST_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingSports:false,
                fetchedSports:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingSports: false,
                netError:false,
                fetchedSports:true,
                fullSportInformation : action.payload
            };
        case teacherConstants.CALENDAR_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,
            };
        case teacherConstants.CALENDAR_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_SAVE_FAILURE:
            return{
                ...state,
                loading:false,

                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,

            };
        case teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                EditingStudentReg: true,
                EditedStudentReg:false

            };
        case teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE:
            return{
                ...state,
                loading:false,

                EditingStudentReg:false,
                EditedStudentReg:false,

                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                EditingStudentReg: false,
                netError:false,
                EditedStudentReg:true,

            };

        case teacherConstants.RESET_MARKS:
            return{
                ...state,
                ...state,
                loading:false,
                fetchingMarks: false,
                fetchedMarks:false,
                netError:false,
                MarksCLass:"",
                Marks :[]
        };

        case teacherConstants.REPORT_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingReports: true,
                fetchedReports:false,
                loading:true

            };
        case teacherConstants.REPORT_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingReports:false,
                fetchedReports:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.REPORT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingReports: false,
                netError:false,
                ReportCards:action.payload,


            };

        case teacherConstants.STUDENT_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingStudentProfile: true,
                fetchedStudentProfile:false,
                loading:true

            };
        case teacherConstants.STUDENT_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingStudentProfile:false,
                fetchedStudentProfile:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.STUDENT_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudentProfile: false,
                netError:false,
                StudentProfile:action.payload,


            };

        default:
        return state
    }
  }