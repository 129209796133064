import {
    apiConstants
} from "../constants/api.constants";
import {
    userConstants
} from "../constants/user.constants";
import {
    history
} from "../shared/history";
import {
    authHeader
} from "../shared/auth-header";

export const userService = {
    login,
    logout,
    register,
    SetEmail,
    updateUserDetails,
    updateUserCredentials,
    accountRecovery,
    updateUserCredentialsMandatory

};

function login(user) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/authenticate`, requestOptions)
        .then(handleResponse).then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    history.push('/EJohns');
    window.location.reload(true);
    return {
        type: userConstants.USER_LOGOUT
    };

}

function SetEmail(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/api/User/setEmail`, requestOptions)
        .then(handleResponse)
        .then(user => {
           
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //  localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}


function accountRecovery(email) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(email)
    };

    return fetch(`${apiConstants.API_BASE_URL}/recoverPortal`, requestOptions)
        .then(handleResponse)
        .then(user => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //  localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}



function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/register/user`, requestOptions).then(handleResponse);
}



function updateUserDetails(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/updateUserDetails`, requestOptions).then(handleResponse);
}
function updateUserCredentialsMandatory(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/updateUserCredentialsMandatory`, requestOptions).then(handleResponse);
}


function updateUserCredentials(user) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(user)
    };

    return fetch(`${apiConstants.API_BASE_URL}/updateUserCredentials`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    
    return response.text().then(text => {
   
        const data = text && JSON.parse(text);
         if (!response.ok){ 

            return Promise.reject(data);
        }
        return data;
    })
}

