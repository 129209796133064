
import "../css/dash.css";


import React, {forwardRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";
import {BarChart,Bar, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";

import {Tooltip} from "@material-ui/core";

import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import UploadPopup from "../Components /UploadPopup";
import {popupActions} from "../actions/popup.actions";
import ReportProfilePopup from "../Components /ReportProfilePopup"; // Import css



function  ReportsCenter() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const dispatch = useDispatch();
    const message = useSelector((state) => state.alert);
    const netError = useSelector((state) => state.teacherReducer.netError);
    const reportStatistics = useSelector((state) => state.teacherReducer.ReportStatistics);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)
    const reportPopup = useSelector((state) => state.popupReducer.reportProfilesPopup);
    React.useEffect(() => {
        if(!reportStatistics && !netError) {
            dispatch(teacherActions.getReportStatistics())
        }
        dispatch(pagesActions.updatepage(pagesConstants.REPORTS_CENTER));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])




    function toggleReports() {
        confirmAlert({
            title: 'Confirm submission',
            message: 'Are you sure you want to proceed.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(alertActions.success("Working Please Wait...This might take a while"))
                        dispatch(teacherActions.toggleReports());
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)

                    }
                },
                {
                    label: 'No',
                    onClick: () => {return 0}
                }
            ]
        });

    }

    function HandleGenReports() {
        dispatch(popupActions.newReportProfiles())
    }

    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                      <div className={"block-header "}>
                          <div className={"row clearfix  align-items-end  "}>

                              <div className={"col-6 text-bold  "}>
                                  <strong><h2>Student Reports Center</h2></strong>
                              </div>

                          </div>
                      </div>

                        <ReportProfilePopup
                            show={reportPopup}
                            onHide={() =>  {
                                dispatch(popupActions.clear()
                                )}}
                        />

                      { message ? (
                          <div className={message.type + " my-1"}>
                              {" "}
                              <div className="popup"> {message.message}</div>
                          </div>) : ("")
                      }
                      {/*Breadcrumb*/}
                      <nav aria-label="breadcrumb" className="main-breadcrumb">
                          <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to="/home">
                                  Home
                              </Link></li>
                              <li className="breadcrumb-item active" aria-current="page">Results Center</li>
                          </ol>
                      </nav>
                      {/*Breadcrumb*/}


                    <div className="row mt-2 p-3">
                        <div className="col rounded p-4  bg-light">

                            <div id="real">
                                <div className="row">

                                    <div className="col-lg-4">

                                        {reportStatistics?(

                                            <div className="card py-3 m-1">

                                                {reportStatistics.ResultsStatus?(<>

                                                    {reportStatistics.Cleared?(
                                                        <>
                                                            <strong className="mx-2">Reports Have Been Generated</strong>
                                                            <button className="btn btn-info mx-2" onClick={HandleGenReports}>Regenerate</button>
                                                        </>):
                                                        <strong className="mx-2">Departments need to be cleared first before  Report generation</strong>
                                                    }

                                                </>):
                                                    (<>
                                                        {reportStatistics.Cleared?(<>
                                                            <strong className="mx-2">Generate Reports</strong>
                                                                <button className="btn btn-info mx-2" onClick={HandleGenReports}>Regenerate</button>
                                                        </>):
                                                        <strong className="mx-2">Departments need to be cleared first before  Report generation</strong>
                                                        }
                                                    </>)

                                                }

                                            </div>

                                          ):""}
                                        {reportStatistics?(<>
                                        {reportStatistics.ResultsStatus?(<>

                                            {reportStatistics.ResultsAccess?(<>
                                                <div className="card py-3 m-1">
                                                    <strong className="mx-2">Restrict Students from Accessing Results</strong>
                                                    <button  className="btn btn-info mx-2" onClick={toggleReports}> Restrict access</button>
                                                </div>

                                            </>):(<>
                                                <div className="card py-3 m-1">
                                                    <strong className="mx-2">Open Results for Student Access</strong>
                                                    <button  className="btn btn-info mx-2" onClick={toggleReports}>Allow access</button>
                                                </div>

                                            </>)}

                                        </>):""}
                                        </>):""}

                                        {reportStatistics?(<>
                                            <Link to="/home/StudentReports">


                                        <div className="card gradient-card gradient-card-button p-3">

                                                <h1 className="color-white wyte" >{reportStatistics.ReportsFrequency}</h1>
                                                  
                                                <div className="card-bottom">
                                                    <p>Reports Generated</p>
                                                </div>

                                        </div> </Link>
                                        </>):""}


                                    </div>
                                    <div className="col-lg-8">

                                            <div className="card pt-2">
                                                {reportStatistics?(<>
                                                    <div id="chart" style={{"width":"100%", "height":"270px"}}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart
                                                                title={"Average mark Distributions"}
                                                                width={500}
                                                                height={300}
                                                                data={
                                                                    reportStatistics.perfomanceRatings.formRatings
                                                                }
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis />
                                                                {/*<Tooltip />*/}
                                                                <Legend />
                                                                <Bar dataKey="H" fill="#8884d8" />
                                                                <Bar dataKey="K" fill="#82ca9d" />
                                                                <Bar dataKey="R" fill="#829d" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </>):(<>
                                                    <div id="chart" style={{"width":"100%", "height":"270px"}}>
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <BarChart
                                                                width={500}
                                                                height={300}
                                                                data={
                                                                    []
                                                                }
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="name" />
                                                                <YAxis />
                                                                {/*<Tooltip />*/}
                                                                <Legend />
                                                                <Bar dataKey="H" fill="#8884d8" />
                                                                <Bar dataKey="K" fill="#82ca9d" />
                                                                <Bar dataKey="R" fill="#829d" />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div></>)
                                                }

                                            </div>

                                    </div>

                                    {/*<div className="col-lg-3">*/}
                                    {/*    <div className="analytics">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <div className="icon"><i className="fa fa-video"></i></div>*/}
                                    {/*            <div className="text">*/}
                                    {/*                <h1>984</h1>*/}
                                    {/*                <p>Total Movies</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-lg-3">*/}
                                    {/*    <div className="analytics">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <div className="icon"><i className="fab fa-vimeo-v"></i></div>*/}
                                    {/*            <div className="text">*/}
                                    {/*                <h1>1455</h1>*/}
                                    {/*                <p>Total Tv-Series</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-lg-3">*/}
                                    {/*    <div className="analytics">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <div className="icon"><i className="fa fa-users"></i></div>*/}
                                    {/*            <div className="text">*/}
                                    {/*                <h1>32</h1>*/}
                                    {/*                <p>Total users</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-lg-3">*/}
                                    {/*    <div className="analytics">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <div className="icon"><i className="fa fa-envelope"></i></div>*/}
                                    {/*            <div className="text">*/}
                                    {/*                <h1>43</h1>*/}
                                    {/*                <p>Total emails</p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>

                        </div>

                    </div>
                    <Footer/>

                </div>



            </>);

}

export default ReportsCenter;
