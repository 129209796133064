import {teacherConstants} from "../constants/teacher.constants";
import {teacherService} from "../services/teacher.service";
import {alertActions} from './index';
import {useSelector} from "react-redux";
import {history} from "../shared/history";


export const teacherActions = {

    deleteSport,
    editSport,
    createSport,
    getSportInformation,
    deleteClub,
    editClub,
    createClub,
    getClubInformation,
    deleteDepartment,
    editDepartment,
    createDepartment,
    getDepartmentInformation,
    RemoveClassSubject,
    AddClassSubject,
    RemoveSubject,
    getSingleSubjectInformation,
    getSubjectInformation,
    getAssessmentMarks,
    editNewSubject,
    SubmitNewSubject,
    SubmitNewAssessment,
    toggleReports,
    getUpload,
    getMarkProfiles,
    getMarkProfilesForStudent,
    getUploads,
    getUploadsForStudent,
    testProgress,
    genReports,
    getReportStatistics,
    getTeacher,
    getSubjectMarks,
    removeStudentSubjectByAdmin,
    submitMarks,
    submitUpload,
    uploadStudents,
    uploadStudentsShift,
    uploadStudentActivities,
    getSportMarks,
    getClubMarks,
    submitAssessmentMark,
    submitMark,
    submitClassTeacherComments,
    submitSportMark,
    submitClubMark,
    getOptions,
    getClassOptions,
    saveStudent,
    saveTeacher,
    editTeacher,
    updateStudent,
    getTeachers,
    getClassStudents,
    getUnregisteredStudents,
    getRegisteredStudents,
    getStudentArrears,
    getClassArrears,
    validateId,
    validateTeacherId,
    alterStudents,
    RegisterStudents,
    CreateArrear,
    ClearStudent,
    getDepartmentStats,
    clearDepartment,
    resetDepartmentSubmission,
    resetDepartment,
    resetSubject,
    resetSubjectClass,
    getStudentContacts,
    CreateContact,
    ClearContact,
    getStudentMedicals,
    CreateMedicalCondition,
    getStudentComments,
    getCalendar,
    RemoveCalendarEvent,
    UpdateCalendar,
    getNotifications,
    UpdateNotifications,
    RemoveNotification,
    getProfile,
    DeleteAssessmentMarks,
    DeleteUpload,
    resetId,
    resetTeacherId,
    getMiniProfile,
    retrievePdfResults,
    downloadClearances,
    getECTemplate,
    getPDFClassLists,
    getPDFCalendar,
    removeStudentSubject,
    removeStudentSport,
    removeStudentClub,
    AddSubject,
    AddSport,
    AddClub,
    editTeacherSubjects,
    getClassRegistration,
    getClassRegistrationbyTic,
    AddSubjectByAdmin,
    resetNetError,
    resetClearances,
    downloadMarkTemplate,
    changeSubjectView,
    resetMarks,
    SSE,
    CutSSE,
    getStudentResults,
    getStudentProfile,
    clearReportCards,
    getMarkProfilesForStudentTwo,
    newReportsProfile,
    getStudentTemplate,
    getAttendanceRegister,
    getStudentShiftTemplate,
    RemoveStudent,
    RemoveTeacher,
    getStudentAccounts,
    uploadStudentsAccounts,
    getStudentAccountsTemplate,

    submitStudentAccount,
    uploadMonthlyStatements,
    getAccountStatementsProfiles,
    deleteAccountStatements,
    getStudentStatements,
    retrievePdfStatements,
    getBroadsheet,
    getSubjectArrears,
    CreateArrearSB,
    ClearStudentSB,
    MarkStudentRegister,
    uploadStudentsArrears,
    getStudents,
    searchStudents,
    AlterStudentArrear,
    getAttendanceStats,
    compileAttendanceStats,
    downloadRegisterStats

};


function getStudentAccounts() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentAccounts()
            .then(
                response=> {
                    dispatch(success(response));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                },

                error => {

                    console.log(error.Message)

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ACCOUNTS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ACCOUNTS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ACCOUNTS_RETRIEVE_FAILURE, error}
    }
}

function changeSubjectView(info) {
    return { type:teacherConstants.CHANGE_SUBJECT_VIEW , payload:info };
}
function CutSSE() {
    return { type:teacherConstants.CUT_SSE };
}
function SSE(info) {
    return { type:teacherConstants.CHANGE_SSE_STATUS , payload:info };
}

function getStudentProfile(ID) {
    return dispatch => {

        dispatch(request());
        teacherService.getStudentProfile(ID)
            .then(

                response=> {
                    dispatch(success(response));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                },

                error => {

                    console.log(error.Message)

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }

            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_PROFILE_RETRIEVE_FAILURE, error}
    }
}


function getStudentStatements(ID,TIME) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentStatements(ID,TIME)
            .then(
                response=> {
                    dispatch(success(response));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_STATEMENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_STATEMENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_STATEMENT_RETRIEVE_FAILURE, error}
    }
}

function getBroadsheet(TIME) {
    return dispatch => {
        dispatch(request());

        teacherService.getBroadsheet(TIME)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }

}

function getStudentResults(ID,TIME) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentResults(ID,TIME)
            .then(
                response=> {
                    dispatch(success(response));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REPORT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_RETRIEVE_FAILURE, error}
    }
}
function getReportStatistics() {
    return dispatch => {
        dispatch(request());

        teacherService.getReportStatistics()
            .then(
                response=> {
                    dispatch(success(response));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_STATISTICS_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REPORT_STATISTICS_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_STATISTICS_FAILURE, error}
    }
}

function toggleReports() {
    return dispatch => {
        dispatch(request());

        teacherService.toggleReports()
            .then(
                response=> {

                    dispatch(success());
                    dispatch(getReportStatistics())

                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},1000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_GEN_REQUEST}
    }

    function success() {
        return {type: teacherConstants.REPORT_GEN_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_GEN_FAILURE, error}
    }
}
function testProgress() {
    return dispatch => {
        dispatch(request());

        teacherService.testProgress()
            .then(
                response=> {
                    dispatch(teacherActions.CutSSE())
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},10)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_GEN_REQUEST}
    }

    function success() {
        return {type: teacherConstants.REPORT_GEN_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_GEN_FAILURE, error}
    }
}

function resetNetError() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.RESET_NET_ERROR}
    }
}

function resetClearances() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.RESET_CLEARANCES}
    }
}
function resetMarks() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.RESET_MARKS}
    }
}
function AddClub(studentId,club) {
    return dispatch => {
        dispatch(request());

        teacherService.AddClub(studentId,club)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function AddSport(studentId,sport) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSport(studentId,sport)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function AddSubjectByAdmin(studentId,subject,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getSubjectMarks(subject,classKey));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function AddSubject(studentId,subject) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function removeStudentClub(studentId,club) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentClub(studentId,club)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function removeStudentSport(studentId,sport) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSport(studentId,sport)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}

function  deleteAccountStatements(Id) {
    return dispatch => {
        dispatch(request());

        teacherService.deleteAccountStatements(Id)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getAccountStatementsProfiles())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.DELETE_FAILURE, error}
    }
}

function  RemoveTeacher(teacherId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveTeacher(teacherId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getTeachers())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_REMOVE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.TEACHER_REMOVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_REMOVE_FAILURE, error}
    }
}


function  RemoveStudent(studentId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveStudent(studentId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getStudents())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_DELETE_FAILURE, error}
    }
}
function  removeStudentSubject(studentId,subject) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function removeStudentSubjectByAdmin(studentId,subject,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getSubjectMarks(subject,classKey));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}


function compileAttendanceStats(user) {
    return dispatch => {
        dispatch(request());

        teacherService.compileAttendanceStats(user)
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ATTENDANCE_STATS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ATTENDANCE_STATS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ATTENDANCE_STATS_RETRIEVE_FAILURE, error}
    }
}


function getAttendanceStats(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getAttendanceStats(user)
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ATTENDANCE_STATS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ATTENDANCE_STATS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ATTENDANCE_STATS_RETRIEVE_FAILURE, error}
    }
}
function getAttendanceRegister(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getAttendanceRegister(user)
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ATTENDANCE_REGISTER_RETRIEVE_FAILURE, error}
    }
}

function getSingleSubjectInformation(subject) {
    return dispatch => {
        dispatch(request());

        teacherService.getSingleSubjectInformation(subject)
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SINGLE_SUBJECT_INFORMATION_RETRIEVE_FAILURE, error}
    }
}

function getSubjectInformation() {
    return dispatch => {
        dispatch(request());

        teacherService.getSubjectInformation()
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SUBJECT_INFORMATION_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SUBJECT_INFORMATION_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SUBJECT_INFORMATION_RETRIEVE_FAILURE, error}
    }
}

function getAssessmentMarks(Id) {
    return dispatch => {
        dispatch(request());

        teacherService.getAssessmentMarks(Id)
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ASSESSMENT_MARKS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ASSESSMENT_MARKS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ASSESSMENT_MARKS_RETRIEVE_FAILURE, error}
    }
}

function getUpload(fileId) {
    return dispatch => {
        dispatch(request());

        teacherService.getUpload(fileId)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function getPDFCalendar() {
    return dispatch => {
        dispatch(request());

        teacherService.getPDFCalendar()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function getPDFClassLists(classes) {
    return dispatch => {
        dispatch(request());

        teacherService.getPDFClassLists(classes)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function downloadMarkTemplate(Subject ,SubjectClass) {
    return dispatch => {
        dispatch(request());

        teacherService.downloadMarkTemplate(Subject ,SubjectClass)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function getStudentTemplate() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentTemplate()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function getStudentShiftTemplate() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentShiftTemplate()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function getStudentAccountsTemplate() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentAccountsTemplate()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function getECTemplate() {
    return dispatch => {
        dispatch(request());

        teacherService.getECTemplate()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function downloadClearances() {
    return dispatch => {
        dispatch(request());

        teacherService.downloadClearances()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function downloadRegisterStats(user) {
    return dispatch => {
        dispatch(request());

        teacherService.downloadRegisterStats(user)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function retrievePdfStatements(ID,time) {
    return dispatch => {
        dispatch(request());

        teacherService.retrievePdfStatements(ID,time)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function retrievePdfResults(ID,time) {
    return dispatch => {
        dispatch(request());

        teacherService.retrievePdfResults(ID,time)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}
function resetId() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.STUDENT_ID_RESET_REQUEST}
    }
}

function resetTeacherId() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.TEACHER_ID_RESET_REQUEST}
    }
}

function getTeacherMiniProfile(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.getTeacherMiniProfile(ID)
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_FAILURE, error}
    }
}


function getMiniProfile(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.getMiniProfile(ID)
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MINI_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MINI_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MINI_PROFILE_RETRIEVE_FAILURE, error}
    }
}

function getProfile() {
    return dispatch => {
        dispatch(request());

        teacherService.getProfile()
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PROFILE_RETRIEVE_FAILURE, error}
    }
}

function RemoveNotification(notificationId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveNotification(notificationId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getNotifications());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NOTIFICATIONS_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_DELETE_FAILURE, error}
    }
}

function UpdateNotifications(notification) {
    return dispatch => {
        dispatch(request());

        teacherService.UpdateNotifications(notification)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getNotifications());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_UPDATE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NOTIFICATIONS_UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_UPDATE_FAILURE, error}
    }
}

function getNotifications() {
    return dispatch => {
        dispatch(request());

        teacherService.getNotifications()
            .then(
                notifications=> {
                    dispatch(success(notifications));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.NOTIFICATIONS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_RETRIEVE_FAILURE, error}
    }
}


function UpdateCalendar(calEvent) {
    return dispatch => {
        dispatch(request());

        teacherService.UpdateCalendar(calEvent)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getCalendar());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_UPDATE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CALENDAR_UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_UPDATE_FAILURE, error}
    }
}

function deleteDepartment(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.deleteDepartment(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getDepartmentInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.DEPARTMENT_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_DELETE_FAILURE, error}
    }
}
function getDepartmentInformation() {
    return dispatch => {
        dispatch(request());

        teacherService.getDepartmentInformation()
            .then(
                response=> {
                    dispatch(success(response));


                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
            return {type:teacherConstants.DEPARTMENT_REQUEST_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_REQUEST_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_REQUEST_FAILURE, error}
    }
}
function editDepartment(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.editDepartment(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getDepartmentInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.DEPARTMENT_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_EDIT_FAILURE, error}
    }
}
function createDepartment(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.createDepartment(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getDepartmentInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_SAVE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.DEPARTMENT_SAVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_SAVE_FAILURE, error}
    }
}
function deleteClub(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.deleteClub(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getClubInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CLUB_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_DELETE_FAILURE, error}
    }
}
function getClubInformation() {
    return dispatch => {
        dispatch(request());

        teacherService.getClubInformation()
            .then(
                response=> {
                    dispatch(success(response));


                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_REQUEST_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CLUB_REQUEST_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_REQUEST_FAILURE, error}
    }
}
function editClub(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.editClub(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getClubInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CLUB_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_EDIT_FAILURE, error}
    }
}
function createClub(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.createClub(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getClubInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_SAVE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CLUB_SAVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_SAVE_FAILURE, error}
    }
}

function deleteSport(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.deleteSport(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSportInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SPORT_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_DELETE_FAILURE, error}
    }
}
function getSportInformation() {
    return dispatch => {
        dispatch(request());

        teacherService.getSportInformation()
            .then(
                response=> {
                    dispatch(success(response));


                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_REQUEST_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SPORT_REQUEST_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_REQUEST_FAILURE, error}
    }
}
function editSport(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.editSport(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSportInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SPORT_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_EDIT_FAILURE, error}
    }
}
function createSport(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.createSport(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSportInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_SAVE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SPORT_SAVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_SAVE_FAILURE, error}
    }
}


function RemoveClassSubject(payload,sub) {
        return dispatch => {
            dispatch(request());

            teacherService.RemoveClassSubject(payload)
                .then(
                    response=> {
                        dispatch(success());
                        dispatch(alertActions.success(response.Message));
                        dispatch(getSingleSubjectInformation(sub))
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                    },
                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.SUBJECT_CLASS_DELETE_REQUEST}
        }

        function success() {
            return {type: teacherConstants.SUBJECT_CLASS_DELETE_SUCCESS}
        }

        function failure(error) {
            return {type: teacherConstants.SUBJECT_CLASS_DELETE_FAILURE, error}
        }
    }

function AddClassSubject(payload,sub) {
    return dispatch => {
        dispatch(request());

        teacherService.AddClassSubject(payload)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSingleSubjectInformation(sub))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SUBJECT_CLASS_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SUBJECT_CLASS_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SUBJECT_CLASS_ADD_FAILURE, error}
    }
}

function RemoveSubject(subjectId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveSubject(subjectId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSubjectInformation())
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SUBJECT_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SUBJECT_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SUBJECT_DELETE_FAILURE, error}
    }
}

function RemoveCalendarEvent(eventId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveCalendarEvent(eventId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getCalendar());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CALENDAR_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_DELETE_FAILURE, error}
    }
}
function getCalendar() {
    return dispatch => {
        dispatch(request());

        teacherService.getCalendar()
            .then(
                events=> {
                    dispatch(success(events));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CALENDAR_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_RETRIEVE_FAILURE, error}
    }
}

function getStudentComments(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentComments(user)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENTS_COMMENTS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENTS_COMMENTS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENTS_COMMENTS_RETRIEVE_FAILURE, error}
    }
}


function ClearContact(contact) {
    return dispatch => {
        dispatch(request());

        teacherService.ClearContact(contact)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentContacts());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACT_DELETE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACT_DELETE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACT_DELETE_FAILURE, error}
    }
}


function CreateMedicalCondition(condition) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateMedicalCondition(condition)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentMedicals());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };  function request() {
        return {type:teacherConstants.MEDICALS_UPDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MEDICALS_UPDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MEDICALS_UPDATE_FAILURE, error}
    }
}

function CreateContact(contact) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateContact(contact)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentContacts());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACT_UPDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACT_UPDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACT_UPDATE_FAILURE, error}
    }
}


function getStudentMedicals() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentMedicals()
            .then(
                contacts=> {
                    dispatch(success(contacts));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MEDICALS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MEDICALS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MEDICALS_RETRIEVE_FAILURE, error}
    }
}

function getAccountStatementsProfiles() {
    return dispatch => {
        dispatch(request());

        teacherService.getAccountStatementsProfiles()
            .then(
                payload=> {
                    dispatch(success(payload));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ACCOUNT_STATEMENT_PROFILE_RETRIEVE_FAILURE, error}
    }
}

function getStudentContacts() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentContacts()
            .then(
                contacts=> {
                    dispatch(success(contacts));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACTS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACTS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACTS_RETRIEVE_FAILURE, error}
    }
}

function newReportsProfile(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.newReportsProfile(payload)
            .then(
                response=> {
                    dispatch(genReports(response.Message))

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.REPORT_GEN_REQUEST}
    }

    function success() {
        return {type: teacherConstants.REPORT_GEN_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_GEN_FAILURE,error}
    }
}



function genReports(payload) {
    return dispatch => {
        dispatch(request());

        teacherService.genReports(payload)
            .then(
                response=> {
                    dispatch(teacherActions.CutSSE())
                    dispatch(success());


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_GEN_REQUEST}
    }

    function success() {
        return {type: teacherConstants.REPORT_GEN_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_GEN_FAILURE,error}
    }
}


function  resetDepartmentSubmission(dptId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.resetDepartmentSubmission(dptId)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("Department successfully reset!"));
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_SUBMISSION_RESET_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_SUBMISSION_RESET_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_SUBMISSION_RESET_FAILURE,error}
    }
}

function  resetSubject(subId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.resetSubject(subId)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(teacherActions.CutSSE())
                    dispatch(alertActions.success("Department successfully reset!"));
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SUBJECT_RESET_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SUBJECT_RESET_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SUBJECT_RESET_FAILURE,error}
    }
}
function  resetSubjectClass(subId,clazz,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.resetSubjectClass(subId,clazz)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("Department successfully reset!"));
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLASS_RESET_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CLASS_RESET_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CLASS_RESET_FAILURE,error}
    }
}

function  DeleteAssessmentMarks(SubjectKey,ClassKey,id) {
    return dispatch => {
        dispatch(request());

        teacherService.DeleteAssessmentMarks(id)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success("Marks successfully deleted!"));
                    dispatch(getMarkProfiles(SubjectKey,ClassKey))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ASSESSMENT_MARKS_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.ASSESSMENT_MARKS_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.ASSESSMENT_MARKS_DELETE_FAILURE,error}
    }
}
function  DeleteUpload(SubjectKey,ClassKey,fileId) {
    return dispatch => {
        dispatch(request());

        teacherService.DeleteUpload(fileId)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("File successfully deleted!"));
                    dispatch(getUploads(SubjectKey,ClassKey))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.FILE_DELETE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.FILE_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.FILE_DELETE_FAILURE,error}
    }
}
function  resetDepartment(dptId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.resetDepartment(dptId)
            .then(
                response=> {
                    dispatch(success(response));

                    dispatch(alertActions.success("Department successfully reset!"));
                    dispatch(teacherActions.CutSSE())
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_RESET_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_RESET_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_RESET_FAILURE,error}
    }
}
function  clearDepartment(dptId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.clearDepartment(dptId)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("Department successfully Submitted!"));
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_CLEAR_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_CLEAR_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_CLEAR_FAILURE,error}
    }
}
function clearReportCards(){
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.REPORT_RESET_REQUEST}
    }
}
function  ClearStudent(clrId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.ClearStudent(clrId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(teacherActions.getClassArrears(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ARREAR_CLEAR_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ARREAR_CLEAR_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ARREAR_CLEAR_FAILURE,error}
    }
}
function MarkStudentRegister(payload,postPayload) {
    return dispatch => {
        dispatch(request());

        teacherService.MarkStudentRegister(payload)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("Record Updated!"));
                    dispatch(teacherActions.getAttendanceRegister(postPayload))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARK_REGISTER_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MARK_REGISTER_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MARK_REGISTER_FAILURE,error}
    }
}
function  ClearStudentSB(clrId,tic) {
        return dispatch => {
            dispatch(request());

            teacherService.ClearStudent(clrId)
                .then(
                    response=> {
                        dispatch(success());
                        dispatch(alertActions.success(response.Message));
                        dispatch(teacherActions.getSubjectArrears(tic))
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                    },
                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.STUDENT_ARREAR_CLEAR_REQUEST}
        }

        function success() {
            return {type: teacherConstants.STUDENT_ARREAR_CLEAR_SUCCESS}
        }

        function failure(error) {
            return {type: teacherConstants.STUDENT_ARREAR_CLEAR_FAILURE,error}
        }
    }
function CreateArrear(arrear,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateArrear(arrear)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(teacherActions.getClassArrears(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ARREAR_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_FAILURE,error}
    }
}
function CreateArrearSB(arrear,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateArrear(arrear)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(teacherActions.getSubjectArrears(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ARREAR_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_FAILURE,error}
    }
}


function AlterStudentArrear(arrear,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.AlterStudentArrear(arrear)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));

                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ARREAR_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_FAILURE,error}
    }
}

function editTeacherSubjects(alteredSubjects) {
    return dispatch=> {
        dispatch(alterStudents(alteredSubjects));
    }
    function alterStudents(payload) {
        return {type: teacherConstants.ALTER_TEACHER_SUBJECTS,payload}
    }

}
function alterStudents(alteredStudents) {
    return dispatch=> {
        dispatch(alterStudents(alteredStudents));
    }
    function alterStudents(payload) {
        return {type: teacherConstants.ALTER_STUDENTS,payload}
    }

}

function validateId(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.validateId(ID)
            .then(
                student=> {
                    dispatch(success(student));
                    dispatch(alertActions.success("Success!"));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                    dispatch(getMiniProfile(student.id))

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_VALIDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_VALIDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_VALIDATE_FAILURE, error}
    }
}
function validateTeacherId(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.validateTeacherId(ID)
            .then(
                teacher=> {
                    dispatch(success(teacher));
                    dispatch(alertActions.success("Success!"));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                    dispatch(getTeacherMiniProfile(teacher.id))


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_VALIDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHER_VALIDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_VALIDATE_FAILURE, error}
    }
}

function getClubMarks(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getClubMarks(user)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_MARKS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CLUB_MARKS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_MARKS_RETRIEVE_FAILURE, error}
    }
}
function getSportMarks(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getSportMarks(user)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_MARKS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SPORT_MARKS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_MARKS_RETRIEVE_FAILURE, error}
    }
}

function getDepartmentStats(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getDepartmentStats(tic)
            .then(
                departments=> {
                    dispatch(success(departments));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_RETRIEVE_FAILURE, error}
    }
}


function getClassArrears(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassArrears(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ALL_ARREARS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ALL_ARREARS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ALL_ARREARS_RETRIEVE_FAILURE, error}
    }
}
function getSubjectArrears(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getSubjectArrears(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ALL_ARREARS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ALL_ARREARS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ALL_ARREARS_RETRIEVE_FAILURE, error}
    }
}



function getStudentArrears(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentArrears(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));

                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ARREARS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ARREARS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ARREARS_RETRIEVE_FAILURE, error}
    }
}


function RegisterStudents(students,cls) {

    return dispatch => {

        dispatch(request());
        teacherService.RegisterStudents(students)
            .then(
                students=> {

                    dispatch(getClassRegistration(cls));
                    dispatch(success(students.Message));
                    dispatch(alertActions.success(students.Message));

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REGISTER_STUDENT_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REGISTER_STUDENT_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REGISTER_STUDENT_FAILURE, error}
    }
}
function getRegisteredStudents(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getRegisteredStudents(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getClassRegistrationbyTic() {
    return dispatch => {
        dispatch(request());

        teacherService.getClassRegistrationbyTic()
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}
function getClassRegistration(cls) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassRegistration(cls)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}
function getUnregisteredStudents(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getUnregisteredStudents(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getClassStudents(classId) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassStudents(classId)
            .then(
                students=> {
                    dispatch(success(students));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getTeachers() {
    return dispatch => {
        dispatch(request());

        teacherService.getTeachers()
            .then(
                teachers=> {
                    dispatch(success(teachers));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHERS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHERS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHERS_RETRIEVE_FAILURE, error}
    }
}


function searchStudents(searchString) {
    return dispatch => {
        dispatch(request());

        teacherService.searchStudents(searchString)
            .then(
                students=> {
                    dispatch(success(students));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_RETRIEVE_FAILURE, error}
    }
}
function getStudents() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudents()
            .then(
                students=> {
                    dispatch(success(students));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_RETRIEVE_FAILURE, error}
    }
}


function getClassOptions(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassOptions(tic)
            .then(
                options=> {
                    dispatch(success(options));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.OPTIONS_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.OPTIONS_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.OPTIONS_FAILURE, error}
    }
}

function getOptions() {
    return dispatch => {
        dispatch(request());

        teacherService.getOptions()
            .then(
               options=> {
                    dispatch(success(options));
                   
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));           
                        dispatch(alertActions.error(error.Message));
                    }else{
                         dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.OPTIONS_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.OPTIONS_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.OPTIONS_FAILURE, error}
    }
}

function getTeacher(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getTeacher(tic)
            .then(
                tic=> {
                    dispatch(success(tic));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_RETRIEVE_REQUEST}
    }

    function success(TIC) {
        return {type: teacherConstants.TEACHER_RETRIEVE_SUCCESS,TIC}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_RETRIEVE_FAILURE, error}
    }
}

function getMarkProfilesForStudentTwo( id){
    return dispatch => {
        dispatch(request());

        teacherService.getMarkProfilesForStudentTwo( id)
            .then(
                profiles=> {
                    dispatch(success(profiles));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARK_PROFILES_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_FAILURE, error}
    }
}

function getMarkProfilesForStudent( Subject,SubjectClass){
    return dispatch => {
        dispatch(request());

        teacherService.getMarkProfilesForStudent( Subject,SubjectClass)
            .then(
                profiles=> {
                    dispatch(success(profiles));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARK_PROFILES_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_FAILURE, error}
    }
}
function getMarkProfiles( Subject,SubjectClass){
    return dispatch => {
        dispatch(request());

        teacherService.getMarkProfiles( SubjectClass,Subject)
            .then(
               profiles=> {
                    dispatch(success(profiles));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARK_PROFILES_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MARK_PROFILES_RETRIEVE_FAILURE, error}
    }
}

function getUploadsForStudent( SubjectClass,Subject){
    return dispatch => {
        dispatch(request());

        teacherService.getUploadsForStudent( SubjectClass,Subject)
            .then(
                uploads=> {
                    dispatch(success(uploads));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UPLOAD_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UPLOAD_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UPLOAD_RETRIEVE_FAILURE, error}
    }
}
function getUploads( SubjectClass,Subject){
    return dispatch => {
        dispatch(request());

        teacherService.getUploads( SubjectClass,Subject)
            .then(
                uploads=> {
                    dispatch(success(uploads));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UPLOAD_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UPLOAD_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UPLOAD_RETRIEVE_FAILURE, error}
    }
}
function getSubjectMarks(Subject ,SubjectClass){
        return dispatch => {
        dispatch(request());

        teacherService.getSubjectMarks(Subject ,SubjectClass)
            .then(
               marks=> {
                    dispatch(success(marks));
                   
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));           
                        dispatch(alertActions.error(error.Message));
                    }else{
                         dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_RETRIEVE_REQUEST}
    }

    function success(marks) {
        return {type: teacherConstants.MARKS_RETRIEVE_SUCCESS,marks}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_RETRIEVE_FAILURE, error}
    }
}

function uploadStudentActivities(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudentActivities(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.STUDENT_ACTIVITIES_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ACTIVITIES_UPLOAD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ACTIVITIES_UPLOAD_FAILURE,error}
    }
}

function uploadStudentsShift(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudentsShift(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.CutSSE())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.STUDENT_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_UPLOAD_FAILURE,error}
    }
}
function uploadStudentsArrears(user,file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudentsArrears(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getSubjectArrears(user))
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.FILE_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.FILE_UPLOAD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.FILE_UPLOAD_FAILURE,error}
    }
}

function uploadMonthlyStatements(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadMonthlyStatements(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getStudentAccounts())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.FILE_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.FILE_UPLOAD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.FILE_UPLOAD_FAILURE,error}
    }
}
function uploadStudentsAccounts(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudentsAccounts(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getStudentAccounts())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.STUDENT_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_UPLOAD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_UPLOAD_FAILURE,error}
    }
}
function uploadStudents(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudents(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.STUDENT_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_UPLOAD_FAILURE,error}
    }
}

function editNewSubject(newSubject) {
    return dispatch => {
        dispatch(request());

        teacherService.editNewSubject(newSubject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                        window.location.reload(true);
                    }, 3000)
                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NEW_SUBJECT_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NEW_SUBJECT_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NEW_SUBJECT_EDIT_FAILURE,error}
    }
}

function SubmitNewSubject(newSubject) {
        return dispatch => {
            dispatch(request());

            teacherService.SubmitNewSubject(newSubject)
                .then(
                    response=> {
                        dispatch(success());
                        dispatch(alertActions.success(response.Message));
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                            window.location.reload(true);
                        }, 3000)
                    },
                    error => {

                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.NEW_SUBJECT_SUBMIT_REQUEST}
        }

        function success() {
            return {type: teacherConstants.NEW_SUBJECT_SUBMIT_SUCCESS}
        }

        function failure(error) {
            return {type: teacherConstants.NEW_SUBJECT_SUBMIT_FAILURE,error}
        }
    }
function SubmitNewAssessment(newAssesmnt) {
    return dispatch => {
        dispatch(request());

        teacherService.SubmitNewAssessment(newAssesmnt)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                        window.location.reload(true);
                    }, 3000)
                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NEW_ASSMNT_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NEW_ASSMNT_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NEW_ASSMNT_SUBMIT_FAILURE,error}
    }
}
function submitUpload(file,description,subjectKey,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.submitUpload(file,description,subjectKey,classKey)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                        window.location.reload(true);
                    }, 3000)
                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UPLOAD_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.UPLOAD_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.UPLOAD_SUBMIT_FAILURE,error}
    }
}
function submitMarks(file,subjectKey,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.submitMarks(file,subjectKey,classKey)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSubjectMarks(subjectKey,classKey))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_SUBMIT_FAILURE,error}
    }
}

function updateStudent(student,Id) {
    return dispatch => {
        dispatch(request());

        teacherService.updateStudent(student,Id)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ADD_FAILURE,error}
    }
}
function saveStudent(student) {
    return dispatch => {
        dispatch(request());

        teacherService.saveStudent(student)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(teacherActions.getStudents())
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ADD_FAILURE,error}
    }
}

function editTeacher(teacher) {
    return dispatch => {
        dispatch(request());

        teacherService.editTeacher(teacher)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getTeachers())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.TEACHER_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_ADD_FAILURE,error}
    }
}

function saveTeacher(teacher) {
    return dispatch => {
        dispatch(request());

        teacherService.saveTeacher(teacher)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getTeachers())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.TEACHER_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_ADD_FAILURE,error}
    }
}

function submitAssessmentMark(mark,assMarks) {
        return dispatch => {
            dispatch(request());

            teacherService.submitAssessmentMark(mark)
                .then(
                    response=> {
                        dispatch(success());
                        dispatch(alertActions.success(response.Message));
                        dispatch(getAssessmentMarks(assMarks))
                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    },
                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.ASSESSMENT_MARKS_SUBMIT_REQUEST}
        }

        function success() {
            return {type: teacherConstants.ASSESSMENT_MARKS_SUBMIT_SUCCESS}
        }

        function failure(error) {
            return {type: teacherConstants.ASSESSMENT_MARKS_SUBMIT_FAILURE,error}
        }
    }

function submitStudentAccount(account) {
    return dispatch => {
        dispatch(request());

        teacherService.submitStudentAccount(account)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentAccounts())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ACCOUNT_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ACCOUNT_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ACCOUNT_SUBMIT_FAILURE,error}
    }
}

function submitMark(mark,subjectKey,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.submitMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSubjectMarks(subjectKey,classKey))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_SUBMIT_FAILURE,error}
    }
}

function submitClubMark(mark,user) {
    return dispatch => {
        dispatch(request());

        teacherService.submitClubMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getClubMarks(user))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CLUB_MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_MARKS_SUBMIT_FAILURE,error}
    }
}


function submitClassTeacherComments(mark,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.submitClassTeacherComments(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentComments(tic))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_COMMENT_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_COMMENT_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_COMMENT_SUBMIT_FAILURE,error}
    }
}

function submitSportMark(mark,user) {
    return dispatch => {
        dispatch(request());

        teacherService.submitSportMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSportMarks(user))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SPORT_MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_MARKS_SUBMIT_FAILURE,error}
    }
}


function  downloadEncodedFile(file , fileName){

    let base64Data =file

    let  contentType ='';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(new Blob(byteArrays, ));
    link.download=fileName;
    link.click();

}

